<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">经销商名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入经销商名称查询"
                  size="medium"
                  v-model="formInline.dealerName"
                >
                </el-input>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
              >查询</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="650"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="dealerName" label="经销商名称" min-width="180">
          </el-table-column>
          <el-table-column prop="dealerRole" label="经销商等级" min-width="150">
          </el-table-column>
          <el-table-column prop="dealerPhone" label="经销商电话" min-width="180">
          </el-table-column>
          <el-table-column prop="thisMonthUnsettledBonus" label="本月未结算奖金（元）" min-width="180">
            <template slot-scope="scope">
              {{scope.row.thisMonthUnsettledBonus || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="beforeThisMonthUnsettledBonus" label="本月之前未结算奖金（元）" min-width="180">
            <template slot-scope="scope">
              {{scope.row.beforeThisMonthUnsettledBonus || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="totalBonus" label="历史总奖金（元）" min-width="180">
            <template slot-scope="scope">
              {{scope.row.totalBonus || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="latestSettlementTime" label="最近结算时间" min-width="160">
          </el-table-column>
          <el-table-column prop="saleName" label="业务员" min-width="120">
          </el-table-column>
          <el-table-column label="操作" min-width="130" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" @click="settlement(scope.row.id)" v-has="'settlement'">结算</el-button>
                <div class="line" v-has="'settlement'"></div>
                <el-button class="textBtn" type="text" @click="settlementRecord(scope.row.id)">结算记录</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
          class="dialog"
          title="结算记录"
          :visible.sync="isShow"
          @close="closeDialog"
          :close-on-click-modal="false">
        <div class="input-group input-time">
          <div class="input-title">结算时间：</div>
          <div class="input-content">
            <el-date-picker
              v-model="recordForm.settlementTime"
              :picker-options="pickerBeginDateBefore"
              type="daterange"
              size="medium"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getSettlementTime"
            >
            </el-date-picker>
          </div>
        </div>
        <el-table v-loading="loadingRecord" :data="records" :height="300">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column property="money" label="结算金额（元）" min-width="130"></el-table-column>
          <el-table-column property="createTime" label="结算时间" min-width="150"></el-table-column>
          <el-table-column property="handlerName" label="操作人员" min-width="150"></el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChangeRecord"
            @current-change="pageChageRecord"
            :total="totalRecord"
            :page-size="pageSizeRecord"
            :current-page="nowPageRecord"
          >
          </el-pagination>
        </div>
      </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      settlementTime: [],
      tableData: [],
      loading: true,
      total: 0,
      nowPage: 1,
      pageSize: 10,
      options1: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "primaryDealer",
          label: "一级经销商",
        },
        {
          value: "secondaryDealer",
          label: "二级经销商",
        },
      ],
      formInline: {
        dealerName: "",
      },
      submitData: {
        dealerName: "",
      },
      isShow: false,
      records: [],
      loadingRecord: true,
      totalRecord: 1,
      nowPageRecord: 1,
      pageSizeRecord: 10,
      settleId: '',//查看结算记录id
      recordForm: {
        settlementTime: ""
      },
      submitDataRecord: {
        settlementTime: ""
      }
    };
  },
  mounted() {
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 关闭对话框
    closeDialog(){
      this.recordForm.settlementTime = []
      this.settleId = ''
      this.pageSizeRecord = 10
      this.nowPageRecord = 0
      this.totalRecord = 0
      this.records = []
    },
    // 结算时间
    getSettlementTime(e){
      this.recordForm.settlementTime = e
      this.submitDataRecord = Object.create(this.recordForm);
      this.nowPageRecord = 1;
      this.searchRecord(1);
    },
    // 奖金结算
    settlement(id){
      this.$api.post('/v1/settlement',{userId: id},r=>{
        if(r.errorCode == '0'){
          this.$message.success('结算成功')
          this.search()
        }else{
          this.$message.error(r.msg)
        }
      })
    },
    searchRecord(e){
      var settlementRecordListRes = {}
      if (
        this.submitDataRecord.settlementTime !== null &&
        this.submitDataRecord.settlementTime !== "" &&
        this.submitDataRecord.settlementTime !== undefined &&
        this.submitDataRecord.settlementTime.length !== 1
      ) {
        var start = this.submitDataRecord.settlementTime[0];
        var end = this.submitDataRecord.settlementTime[1];
        settlementRecordListRes.start = start;
        settlementRecordListRes.end = end;
      }
      settlementRecordListRes.userId = this.settleId
      settlementRecordListRes.size = this.pageSizeRecord;
      settlementRecordListRes.page = parseInt(e) - 1;
      this.$api.post('/v1/settlementRecord/list',settlementRecordListRes,r=>{
        if(r.errorCode =='0'){
          r.data.content.map(v=>{
            if (v.createTime) {
              v.createTime = this.$format.format(v.createTime, 'yyyy-MM-dd hh:mm:ss')
            }
            return v
          })
          this.records = r.data.content
          this.totalRecord = r.data.totalElements
          this.loadingRecord = false
        }else{
          this.loadingRecord = false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        this.loadingRecord = false
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    // 查看结算记录
    settlementRecord(id){
      this.submitDataRecord = Object.create(this.recordForm);
      this.nowPageRecord = 1;
      this.loadingRecord = true
      this.settleId = id
      this.searchRecord(1)
      this.isShow = true
    },
    handleSizeChangeRecord(e){
      this.pageSizeRecord = e
      this.nowPageRecord = 0
      this.searchRecord(1)
    },
    pageChageRecord(e){
      this.searchRecord(e);
      this.nowPageRecord = parseInt(e);
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var bonusSettlementRes = {};
      if (this.submitData.dealerName) {
        bonusSettlementRes.dealerName = this.submitData.dealerName;
      }
      bonusSettlementRes.size = this.pageSize;
      bonusSettlementRes.page = parseInt(e) - 1;
      this.$api.post('/v1/bonusSettlement/list',bonusSettlementRes,r=>{
        if(r.errorCode == '0'){
          r.data.content.map(v=>{
            if (v.latestSettlementTime) {
              v.latestSettlementTime = this.$format.format(v.latestSettlementTime, 'yyyy-MM-dd hh:mm:ss')
            }else{
              v.latestSettlementTime = '--'
            }
            v.saleName = v.saleName || '--'
            if(v.dealerRole){
              switch (v.dealerRole) {
                case 'root':
                  v.dealerRole = '超级管理员'
                  break;
                case 'manger':
                  v.dealerRole = '业务经理'
                  break;
                case 'salesman':
                  v.dealerRole = '业务员'
                  break;
                case 'primaryDealer':
                  v.dealerRole = '一级经销商'
                  break;
                case 'secondaryDealer':
                  v.dealerRole = '二级经销商'
                  break;
                default:
                  break;
              }
            }
            return v
          })
          this.tableData = r.data.content
          this.total = r.data.totalElements
          this.loading=false
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        this.loading=false
        if (f === 403) {
          this.logout()
        }
      },'json')

    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  /* width: 425px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.tableStyle >>>tr{
  height: 64px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px;
  height: fit-content;
}
.block{
  text-align: right;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
.dialog >>> .el-dialog__title{
  color: #FE9F36;
  font-weight: bold;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.dialog >>> .el-dialog__body{
  padding-top: 10px;
}
.dialog >>> .el-dialog__body .input-group{
  margin-bottom: 25px;
}
.tableStyle >>> .el-table__header .cell{
   padding: 0!important;
   text-overflow: unset!important;
   white-space:nowrap!important
}
</style>