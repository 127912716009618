<template>
  <el-aside width="200px">
    <el-menu
    :default-active="this.$store.state.defaultActive"
    background-color="#3b3c40"
    text-color="#fff"
    active-text-color="#ee6600">
        <div v-for="item in this.$store.state.menu" :key="item.id">
          <!-- 没有子菜单 -->
          <el-menu-item v-if="!item.children" :index="item.jumpWay" @click="link(item.jumpWay)">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.menuName}}</span>
            </template>
          </el-menu-item>

          <!-- 有子菜单 -->
          <el-submenu v-else :index="item.jumpWay">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.menuName}}</span>
            </template>
            <el-menu-item v-for="child in item.children" :key="child.id" :index="child.jumpWay" @click="link(child.jumpWay)">{{child.menuName}}</el-menu-item>
          </el-submenu>
        </div>
    </el-menu>
</el-aside>
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    link (path, query) {
      this.$router.push({path: path, query: query})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-aside{
  background: rgb(59, 60, 64);
  height: 100%;
  border-top: 1px solid #1b1b1b;
 }
 .el-submenu .el-menu-item{
    padding-left: 54px !important;
 }
 .el-menu-item.is-active {
   background-color: #303133 !important;
}
</style>
