<template>
  <div class="page">
    <div class="topMenu">
      <div class="logo">
        <img class="logoStyle" src="../../assets/images/logo-s.png" alt="">
        <span class="menuText">经销商管理平台</span>
      </div>
    </div>
    <el-main>
      <div class="main-content">
        <el-page-header @back="goBack" content="修改密码">
          <template slot="title"></template>
        </el-page-header>
        <div class="form-edit">
          <el-form
            ref="editForm"
            :rules="rules"
            label-width="0px"
            :model="editForm">
            <el-form-item prop="phoneNumber">
              <el-input
                  class="inputStyle"
                  v-model="editForm.phoneNumber"
                  maxlength="11"
                  minlength="11"
                  placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="newPass">
              <el-input
                  class="inputStyle"
                  type="password"
                  v-model="editForm.newPass"
                  placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item prop="confirmPass">
              <el-input
                  class="inputStyle"
                  type="password"
                  v-model="editForm.confirmPass"
                  placeholder="请再次输入密码"></el-input>
            </el-form-item>
            <el-form-item  class="verifyCodeItemCss">
              <el-input class="inputStyle" placeholder="请输入验证码" v-model="editForm.verifyCode" ></el-input>
              <!--关键 ↓-->
              <div :class="['v_container', { 'disabled': !show}]" @click="getCode">
                <span v-if="show">验证码</span>
                <span v-if="!show">{{times}}s</span>
              </div>
            </el-form-item>
            <el-button
              class="search-button"
              :loading="loginLoading"
              type="primary"
              @click="submitForm('editForm')"
              >提交</el-button
            >
          </el-form>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
// import { GVerify } from '@/assets/js/verifyCode.js'

const validPhone = (rule, value, callback) => {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确手机号码'))
  } else {
    callback()
  }
}
  export default {
    data(){
      return {
        times: 59,//倒计时
        show: true, //显示获取验证码
        loginLoading: false,
        editForm: {
          phoneNumber: '',
          newPass: '',
          confirmPass: '',
          verifyCode: ''
        },
        verifyCode: null,
        rules: {
          phoneNumber: [
            {required: true, trigger: 'blur', validator: validPhone}
          ],
          newPass: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
            ],
          confirmPass: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
            ],
        },
      }
    },
    mounted(){
    },
    methods: {
       getTimes() {
        this.show = false
        this.timer = setInterval(()=>{
          this.times--
          if(this.times===0){
            this.show = true
            clearInterval(this.timer)
            this.times = 59
          }
        },1000)
      },
      // 点击发送手机验证码
      getCode(){
        if(!this.show) return false
        if(!this.editForm.phoneNumber){
          this.$message({
            message: "请先填写手机号",
            type: "error",
          });
          return false
        }
        var params = {}
        params.phoneNumber = this.editForm.phoneNumber
        this.$api.post('/v1/user/send/code',params,r=>{
          if(r.errorCode == '0'){
            this.$message.success('验证码已发送，请注意接收！')
            this.show = false
            this.getTimes()
          }else{
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0])
            } else {
              this.$message.error(r.msg)
            }
          }
        })

      },
      goBack() {
        this.$router.go(-1);
      },
      submitForm(formName){
        console.log(this.$refs[formName].model);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.phoneNumber = this.$refs[formName].model.phoneNumber
            if( this.$refs[formName].model.newPass !== this.$refs[formName].model.confirmPass){
              this.$message({
                message: "两次密码输入需一致",
                type: "error",
              });
              return false
            }
            if(!this.editForm.verifyCode){
              this.$message({
                message: "请获取验证码后进行填写",
                type: "error",
              });
            }
            this.submit();
          } else {
            console.log("error submit!!");
            return false;
          }
        });

      },
      // 提交修改密码
      submit(){
        console.log(this.editForm);
        let params  = {}
        params.code = this.editForm.verifyCode
        params.phone  =this.editForm.phoneNumber
        params.passWord = this.editForm.newPass
        this.$api.post('/v1/user/check/code',params,res=>{
          if(res.errorCode == '0'){
            this.$message({
              message: "修改成功，请重新登录",
              type: "success",
            });
            this.link('/login')
          }else{
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        })
      },
      link(path, query) {
        this.$router.push({ path: path, query: query });
      },
    }
  }
</script>

<style scoped>
#app{
  background: #ffffff !important;
}

.v_container {
 width: 35%;
 background: #FE9F36;
 color: #ffffff;
 text-align: center;
 height: auto;
 font-size: 16px;
 /* top: 1rem; */
 margin-left: 20px;
 border-radius: 15px;
 cursor: pointer;
}
.v_container.disabled{
  background: #eeb97c;
}
.el-main{
  padding: 20px 0;
}
.topMenu{
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 11px 26px 3px rgba(191, 188, 188, 0.09);
}
.logo{
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 55px;
}
.logoStyle{
  width: 28px;
}
.menuText{
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #DB6F2C;
  margin-left: 14px;
}
.main-content>>>.el-page-header__content{
  color: #FE9F36;
  font-size: 16px;
}
.main-content >>> .el-icon-back{
  font-size: 16px;
}
.main-content >>> .el-page-header__title{
  font-size: 16px;
}
.main-content >>> .el-page-header{
  border-bottom: 2px solid #F2F2F2;
  padding: 0 20px 30px;
}
.main-content{
  padding: 20px 0;
  position: relative;
}
.form-edit{
  margin: auto;
  width: 20%;
  margin-top: 90px;
}
.form-edit >>> .el-form-item{
  margin-bottom: 25px;
}
.verifyCodeItemCss >>> .el-form-item__content{
  display: flex;
  align-items: center;
}
.inputStyle>>>.el-input__inner{
  background: transparent;
  border-color: #AAAAAA;
  border-radius: 12px;
  border: 1px solid #AAAAAA;
  height: 40px;
  padding: 0 22px;
  line-height: 65px;
  font-size: 16px;
  color: #AAAAAA;
}
.search-button {
  border-radius: 12px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  color: #ffffff;
}
</style>