<template>
  <div>
    <el-menu
    background-color="#303133"
    active-text-color="#999999"
    text-color="#999999"
    mode="horizontal">
        <el-submenu index="1">
            <template slot="title">
                <i class="el-icon-user"></i>
                <span>{{username}}</span>
            </template>
            <el-menu-item index="1-2" @click="logout">退出</el-menu-item>
        </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  data () {
    return {
      username: '',
      balance: 0
    }
  },
  props: [],
  mounted () {
    this.username = sessionStorage.getItem('username') || '管理员'
  },
  methods: {
    link (path, query) {
      this.$router.push({path: path, query: query})
    },
    logout () {
      // var params = {}
      // params.u = '/logout'
      // sessionStorage.setItem('username', null)
      // this.$api.get('', params, r => {})
      this.link('/login')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cost{
  padding: 10px;
  color: rgb(153, 153, 153);
  font-size: 14px;
}
.cost-title,.cost-amount{
  margin: 5px;
}
.cost-title{
  margin-top: 0;
}
.cost-amount{
  padding-bottom: 15px;
  padding-top: 5px;
  border-bottom: 1px solid rgba(153, 153, 153,0.5);
}
.cost-amount span{
  color: #EE6600;
  font-size: 20px;
  margin-right: 5px;
}
.cost-button{
  padding: 10px;
  padding-bottom: 0;
}
.cost-button button{
  padding: 8px 12px;
  font-size: 14px !important;
  color: rgb(153, 153, 153);
  background: #414245;
  border: none;
}
.cost-button button:hover{
  background: #666666;
}
.login{
  padding: 10px 0;
}
.login-button{
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 7px 10px;
}
</style>
