<template>
  <el-main>
    <!-- <MainTitle name="硬件数据"></MainTitle> -->
    <div class="main-content">
      <el-card class="box-card">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :disabled="loading" label="待发货订单" name="paid"></el-tab-pane>
          <el-tab-pane :disabled="loading" label="已发货订单" name="delivered"></el-tab-pane>
          <el-tab-pane :disabled="loading" label="退款订单" name="returned"></el-tab-pane>
        </el-tabs>
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">商品名称：</div>
              <div class="input-content">
                <el-input placeholder="请输入商品名称查询" size="medium" v-model="formInline.productName">
                </el-input>
              </div>
            </div>
            <div class="input-group">
              <div class="input-title">收件人姓名：</div>
              <div class="input-content">
                <el-input placeholder="请输入用户姓名查询" size="medium" v-model="formInline.buyName">
                </el-input>
              </div>
            </div>
            <div class="input-group">
              <div class="input-title">收件人手机：</div>
              <div class="input-content">
                <el-input placeholder="请输入用户手机号查询" size="medium" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11"  v-model="formInline.phone">
                </el-input>
              </div>
            </div>
            <div class="input-group input-time">
              <div class="input-title">下单时间：</div>
              <div class="input-content">
                <el-date-picker
                  v-model="formInline.buyTime"
                  size="medium"
                  :picker-options="pickerBeginDateBefore"
                  type="daterange"
                  style="width: 240px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="input-group input-time" v-if="activeName == 'delivered'">
              <div class="input-title">发货时间：</div>
              <div class="input-content">
                <el-date-picker
                  v-model="formInline.sendTime"
                  size="medium"
                  :picker-options="pickerBeginDateBefore"
                  type="daterange"
                  style="width: 240px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="input-group input-time" v-if="activeName == 'returned'">
              <div class="input-title">退款时间：</div>
              <div class="input-content">
                <el-date-picker
                  v-model="formInline.returnedTime"
                  size="medium"
                  :picker-options="pickerBeginDateBefore"
                  type="daterange"
                  style="width: 240px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="flex-right">
            <el-button size="medium" class="search-button" type="primary" @click="submit(1)"
              >查询</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="530"
          class="tableStyle"
          :data="activeName == 'paid' ? tableData1 : (activeName == 'delivered' ? tableData2 : tableData3)"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="id" label="订单号" min-width="105"> </el-table-column>
          <el-table-column prop="productname" label="商品名称" min-width="120"> </el-table-column>
          <el-table-column prop="productSize" label="商品数量" min-width="80"> </el-table-column>
          <el-table-column prop="productPrice" label="商品价格（元）" min-width="100"> </el-table-column>
          <el-table-column prop="totalFee" label="成交价格（元）" min-width="100"> </el-table-column>
          <el-table-column prop="buyName" label='收件人姓名' min-width="100">
            <template slot-scope="scope">
              {{scope.row.buyName || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="buyPhone" label='收件人手机号' min-width="120">
            <template slot-scope="scope">
              {{scope.row.buyPhone || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="buyAddress" label='收货地址' min-width="130">
            <template slot-scope="scope">
              {{scope.row.buyAddress || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="orderTime" label="下单时间" :min-width="activeName == 'delivered' ? '90' : (activeName == 'paid' ? '110' : '85')" > </el-table-column>
          <el-table-column prop="state" label="订单状态" min-width="80" >
            <template slot-scope="scope">
              {{scope.row.state == 'paid' ? '待发货' : (scope.row.state == 'delivered' ? '已发货' : (scope.row.state == 'returned' ? '已退款' : '--'))}}
            </template>
          </el-table-column>
          <el-table-column prop="expressNumber" label="物流编码" min-width="110" v-if="activeName !== 'paid'">
            <template slot-scope="scope">
              {{scope.row.expressNumber || '--'}}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="SNCode" label="SN码" min-width="120" >
            <template slot-scope="scope">
              {{scope.row.SNCode || '--'}}
            </template>
          </el-table-column> -->
          <el-table-column prop="sender" label="发货人" min-width="100" v-if="activeName == 'delivered'">
            <template slot-scope="scope">
              {{scope.row.sender || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="sendTime" label="发货时间" min-width="90" v-if="activeName == 'delivered'">
            <template slot-scope="scope">
              {{scope.row.sendTime || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="returned" label="退款人" min-width="110" v-if="activeName == 'returned'">
            <template slot-scope="scope">
              {{scope.row.returned || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="returnedTime" label="退款时间" min-width="85" v-if="activeName == 'returned'">
            <template slot-scope="scope">
              {{scope.row.returnedTime || '--'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="140" fixed="right" v-if="activeName !== 'returned'">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" v-if="activeName == 'paid'" @click="deliverGoods(scope.row.id)">发货</el-button>
                <div class="line" v-if="activeName == 'paid'"></div>
                <el-button class="textBtn" type="text" @click="refundOrder(scope.row.id)">退款</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        top="200px"
        class="dialog"
        :title="deliverId !== '' ? '立即发货' : '提示'"
        width="30%"
        @closed="closed"
        :close-on-click-modal="false"
        >
        <el-form v-if="deliverId !== ''" ref="form" :model="deliverInfo" label-width="100px">
          <el-form-item label="物流单号：">
            <el-input maxlength="20" placeholder="不超过20个字符" v-model="deliverInfo.logisticsCode"></el-input>
          </el-form-item>
        </el-form>
        <span v-if="refundId !== ''">您确定退款该订单吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button v-if="refundId !== ''" @click="cancel">取 消</el-button>
          <el-button v-if="refundId !== ''" type="primary" @click="returned">确 定</el-button>
          <el-button v-if="deliverId !== ''" type="primary" @click="deliver">发 货</el-button>
        </span>
    </el-dialog>
  </el-main>
</template>

<script>
// import MainTitle from '@/pages/layout/MainTitle'
export default {
  name: "allOrderList",
  data() {
    return {
      deliverInfo: {
        logisticsCode: '',
      },
      dialogVisible: false,
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      activeName: 'paid',
      id: this.$route.query.id, //从用户列表分析历史跳转时带过来的参数
      openId: this.$route.query.openId, //从客服消息分析历史跳转时带过来的参数
      recordId: "",
      loading: true,
      formInline: {
        productName: "",
        buyName: "",
        phone: "",
        buyTime: "",
        sendTime: "",
        returnedTime: ""
      },
      submitData: {
        productName: "",
        buyName: "",
        phone: "",
        buyTime: "",
        sendTime: "",
        returnedTime: ""
      },
      total: 1,
      nowPage: 1,
      pageSize: 10,
      tableData1: [], // 待发货订单
      tableData2: [], // 已发货订单
      tableData3: [], // 退款订单
      deliverId: '',//发货ID
      refundId: '',//退款ID
    };
  },
  watch: {},
  components: {},
  mounted() {
    this.activeName = this.$route.query.activeName ? this.$route.query.activeName : 'paid'
    if (this.id) {
      this.formInline.userId = this.id;
      this.submitData.userId = this.id;
    }
    if (this.openId) {
      this.formInline.openId = this.openId;
      this.submitData.openId = this.openId;
    }
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 关闭对话框后
    closed(){
      this.deliverId = ''
      this.refundId = ''
      this.deliverInfo = {
        logisticsCode: '',
      }
    },
    // 取消
    cancel(){
      this.dialogVisible = false
    },
    // 确认发货
    deliver(){
      console.log(this.deliverInfo);
      if(this.deliverInfo.logisticsCode == ''){
        this.$message.error('未填写物流单号');
        return false
      }
      let params = {}
      params.orderId = this.deliverId
      params.status = 'delivered'
      params.expressNum = this.deliverInfo.logisticsCode
      this.$api.post('/v1/orders/edit',params,r=>{
        if(r.errorCode == '0'){
          this.$message.success('发货成功')
          this.search(1)
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
      this.dialogVisible = false
    },
    returned(){
      let params = {}
      params.orderId = this.refundId
      params.status = 'returned'
      this.$api.post('/v1/orders/edit',params,r=>{
        if(r.errorCode == '0'){
          this.$message.success('退款成功')
          this.dialogVisible = false
          this.search(1)
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
    },
    // 发货
    deliverGoods(id){
      this.dialogVisible = true
      this.deliverId = id
    },
    // 退款
    refundOrder(id){
      this.dialogVisible = true
      this.refundId = id

    },
    // 切换标签页
    handleClick(e){
      this.pageSize = 10
      this.nowPage = 0
      this.formInline = {
        id: "",
        productName: "",
        buyName: "",
        phone: "",
        buyTime: "",
        sendTime: "",
        returnedTime: ""
      }
      this.submitData = {
        id: "",
        productName: "",
        buyName: "",
        phone: "",
        buyTime: "",
        sendTime: "",
        returnedTime: ""
      }
      this.search(1)
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    setDayString(date) {
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      );
    },
    formatDate(row, column) {
      // 获取单元格数据
      let data = row[column.property]
      if(data == null) {
          return null
      }
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    },
    search(e) {
      this.loading=true
      var req = {};
        if (
          this.submitData.buyTime !== null &&
          this.submitData.buyTime !== "" &&
          this.submitData.buyTime !== undefined &&
          this.submitData.buyTime.length !== 1
        ) {
          var start = this.submitData.buyTime[0];
          var end = this.submitData.buyTime[1];
          req.paidStartTime = start;
          req.paidEndTime = end;
        }
      if(this.activeName == 'delivered'){
        if (
          this.submitData.sendTime !== null &&
          this.submitData.sendTime !== "" &&
          this.submitData.sendTime !== undefined &&
          this.submitData.sendTime.length !== 1
        ) {
          var start = this.submitData.sendTime[0];
          var end = this.submitData.sendTime[1];
          req.deliveredStartTime = start;
          req.deliveredEndTime = end;
        }
      }else if(this.activeName == 'returned'){
        if (
          this.submitData.returnedTime !== null &&
          this.submitData.returnedTime !== "" &&
          this.submitData.returnedTime !== undefined &&
          this.submitData.returnedTime.length !== 1
        ) {
          var start = this.submitData.returnedTime[0];
          var end = this.submitData.returnedTime[1];
          req.returnedStartTime = start;
          req.returnedEndTime = end;
        }
      }
      if (this.submitData.productName) {
        req.productName = this.submitData.productName;
      }
      if (this.submitData.buyName) {
        req.buyName = this.submitData.buyName;
      }
      if (this.submitData.phone) {
        req.phone = this.submitData.phone;
      }
      req.status = this.activeName
      req.size = this.pageSize;
      req.page = parseInt(e) - 1;
      this.$api.post('/v1/orders', req, r => {
        if (r.errorCode == '0') {
          r.data.content.map(v => {
            if (v.orderTime) {
              v.orderTime = this.$format.format(v.orderTime, 'yyyy-MM-dd hh:mm:ss')
            }
            if (v.sendTime) {
              v.sendTime = this.$format.format(v.sendTime, 'yyyy-MM-dd hh:mm:ss')
            }
            if (v.returnedTime) {
              v.returnedTime = this.$format.format(v.returnedTime, 'yyyy-MM-dd hh:mm:ss')
            }
            if(v.productName || v.productname){
              v.productname = v.productName || v.productname
            }
            if(v.productSize || v.size){
              v.size = v.productSize || v.size
            }
            if(v.productPrice || v.price){
              v.price = v.productPrice || v.price
            }
            if(v.buyName || v.buyName){
              v.buyName = v.buyName || v.buyName
            }
            if(v.phone || v.phone){
              v.phone = v.phone || v.phone
            }
            if(v.userRole || v.userrole){
              let role = v.userRole || v.userrole
              switch (role) {
                case 'primaryDealer':
                  v.userrole = '一级经销商'
                  break;
                case 'secondaryDealer':
                  v.userrole = '二级经销商'
                  break;
                default:
                  break;
              }
            }
            if(v.userPName || v.userpname){
              v.userpname = v.userPName || v.userpname
            }
            if(v.createTime || v.createtime){
              v.createtime = this.$format.format(v.createTime || v.createtime, 'yyyy-MM-dd hh:mm:ss')
            }
            if(v.userName || v.username){
              v.username = v.userName || v.username
            }
            return v
          })
          if(this.activeName == 'paid'){
            this.tableData1 = r.data.content
            this.tableData2 = []
            this.tableData3 = []
          }else if(this.activeName == 'delivered'){
            this.tableData2 = r.data.content
            this.tableData1 = []
            this.tableData3 = []
          }else if(this.activeName == 'returned'){
            this.tableData3 = r.data.content
            this.tableData1 = []
            this.tableData2 = []
          }
          this.total = r.data.totalElements
          this.loading = false
        } else {
          this.loading = false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      }, f => {
        this.loading = false
        if (f === 403) {
          this.logout()
        }
      },'json')
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  justify-content: space-between;
  flex-wrap: nowrap;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 20px;
  line-height: 72px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
}
.main-content {
  padding: 10px 20px 20px;
  height: 100%;
  overflow: auto;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.chart-box{
  /* height: calc(100% - 250px); */
  /* overflow-y: scroll; */
}
.main-content .chart-title {
  margin-bottom: 20px;
}
.frequency {
  color: #303133;
}
.frequency-title,
.chart-title {
  font-size: 18px;
}
.frequency-tiem {
  padding: 20px;
  padding-bottom: 0;
  border-left: 1px solid #d8d8d8;
}
.frequency-name {
  font-size: 14px;
}
.frequency-content {
  font-size: 48px;
  padding-top: 10px;
}
.frequency-content .unit {
  font-size: 18px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.table-header-select {
  overflow: hidden;
}
.table-header-select span {
  line-height: 28px;
}
.tooltip-content {
  padding: 2px 5px;
}
.tooltip-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
  color: #333;
}
.tooltip-item {
  overflow: hidden;
  font-size: 14px;
  line-height: 2;
  color: #333;
}
.tooltip-item .tooltip-item-left {
  color: #999;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px ;
}
.input-time {
  /* width: 425px; */
  /* margin-right: 30px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.search-button {
  /* width: 120px; */
  margin: 10px 0;
  height: fit-content;
}
.block{
  text-align: right;
  padding-bottom: 20px;
}
.tableStyle >>>tr{
  height: 64px;
}
.box-card >>> .el-card__body{
  padding: 15px 20px 10px;
}
.el-tabs__header{
  margin: 0 0 10px;
}
.tableStyle >>> .el-table__header .cell{
   padding: 0!important;
   text-overflow: unset!important;
   white-space:nowrap!important
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
.dialog>>> .el-dialog__footer{
  text-align: center;
}
.dialog >>> .el-dialog__title{
  font-weight: bold;
}
</style>
