<template>
  <el-container class="index">
    <el-header height="64px">
        <div class="logo fl">
          <a href="http://www.heartvoice.com.cn" target="_blank">
            <img style="width:36px;height:36px;" src="@/assets/images/logo-s.png" alt="">
          </a>
          <span class="menuText">经销商管理平台</span>
        </div>
        <!-- <div class="title fl" @click="link('/hardware')">经销商管理平台</div> -->
        <div class="menu fr">
          <TopMenu></TopMenu>
        </div>
    </el-header>
    <el-container class="left-menu">
        <LeftMenu></LeftMenu>
        <slot></slot>
    </el-container>
  </el-container>
</template>

<script>
import LeftMenu from '@/pages/layout/LeftMenu'
import TopMenu from '@/pages/layout/TopMenu'
export default {
  name: 'Menu',
  data () {
    return {
    }
  },
  watch: {
  },
  mounted () {
  },
  components: {LeftMenu, TopMenu},
  methods: {
    link (path, query) {
      this.$router.push({path: path, query: query})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
    height: 100%;
}
.el-header{
    background: #303133;
    padding: 0;
    overflow: hidden;
}
.logo{
     width: 176px;
     /* text-align: center; */
     padding-left: 24px;
     height: 64px;
     display: flex;
    align-items: center;
 }
 .logo img{
     width: 120px;
     height: 24px;
     margin: 7px 0;
 }
.title{
     font-size: 16px;
     color: #fff;
     line-height: 64px;
     border-left: 1px solid #1b1b1b;
     padding: 0 20px;
     cursor: pointer;
 }
 .menu{
     padding-right: 20px;
 }
 .el-aside{
     background: rgb(59, 60, 64);
     height: 100%;
     border-top: 1px solid #1b1b1b;
 }
 .docLeft::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #f5f5f5;
  z-index: -1;
}
 .docLeft .left-menu{
   width: 1200px;
   margin: 0 auto;
 }
 .docLeft .el-header{
   position: fixed;
   width: 100%;
   z-index: 2;
 }
 .docLeft .el-aside{
    position: fixed;
    background-color: #f5f5f5;
    top: 48px;
    padding-bottom: 48px;
 }
 .menuText{
   color: #DB6F2C;
  margin-left: 12px;
  font-size: 18px;
  font-weight: 500;
 }
</style>
