<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-page-header @back="goBack" content="录入订单">
        </el-page-header>
        <div class="title">订单详情：</div>
        <el-form :disabled="!!orderId" class="form" label-position="left" ref="formInline" :hide-required-asterisk='true' :rules="rules" label-width="110px" :model="formInline">
          <el-form-item label="订单号：" prop="orderId">
            <el-input v-model="formInline.orderId" size="medium" maxlength="30" placeholder="不超过30个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品名称：" prop="productId">
            <el-select v-model="formInline.productId" placeholder="请选择商品名称" style="width: 100%;" @change="getProductsInfo">
              <el-option
                v-for="item in products"
                :key="item.idStr"
                :label="item.displayName"
                :value="item.idStr">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品价格：" prop="productPrice">
            <el-input disabled v-model="formInline.productPrice" size="medium" maxlength="6" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品实付价：" prop="totalFee">
            <el-input  v-model="formInline.totalFee"  size="medium" maxlength="6" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品数量：" prop="productSize">
            <el-input v-model="formInline.productSize"  maxlength="6" size="medium" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <el-form-item label="订单来源：" prop="source">
            <el-select v-model="formInline.source" placeholder="请选择订单来源" style="width: 100%;">
              <el-option
                v-for="item in sources"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="购买人姓名：" prop="buyerName">
            <el-input v-model="formInline.buyerName" size="medium" maxlength="10" placeholder="不超过10个字符"></el-input>
          </el-form-item>
          <el-form-item label="购买人手机：" prop="buyerPhone">
            <el-input v-model="formInline.buyerPhone"  maxlength="11" size="medium" placeholder="等于11个字符"></el-input>
          </el-form-item>
          <el-form-item label="购买人地址：" prop="buyerAddress">
            <el-input v-model="formInline.buyerAddress"  size="medium" placeholder="不超过30个字符"></el-input>
          </el-form-item> -->
          <el-form-item label="下单时间：" prop="orderTime">
            <el-date-picker
              class="datePicker"
              :picker-options="pickerBeginDateBefore"
              v-model="formInline.orderTime"
              type="datetime"
              size="medium"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="经销商身份码：" prop="dealerCode">
            <el-select v-model="formInline.dealerCode" @change="getDealers" placeholder="请选择经销商" style="width: 100%;" filterable :filter-method="filterMethod">
              <el-option
                v-for="item in dealers"
                :key="item.dealerCode"
                :label="item.dealerName"
                :value="item.dealerCode">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="SN码：" prop="snCode">
            <el-input v-model="formInline.snCode" minlength="12"  maxlength="12" size="medium" placeholder="等于12位字符"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button :loading="submitLoading" class="search-button" type="primary" size="medium" @click="submitForm('formInline')">确定</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </el-main>
</template>

<script>
  const validPhone = (rule, value, callback) => {
    const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
    if (!value) {
      callback(new Error('请输入购买人手机号'))
    } else if (!reg.test(value)) {
      callback(new Error('请输入正确手机号码'))
    } else {
      callback()
    }
  }
  // 验证只能输入数字
  const limitInputNumber = (rule,val,callback)=>{
    console.log(val);
    const reg = /^[1-9]\d*$/
    if(!val){
      callback(new Error('输入为空！'))
    }else if(!reg.test(val)){
      callback(new Error('请输入数字'))
    }else{
      callback()
    }
  }
  // 限制只能输入数字(可以输入两位小数)
  const limitInputPointNumber = (rule,val,callback) =>{
    const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
    if(!val){
      callback(new Error('输入为空！'))
    }else if(!reg.test(val)){
      callback(new Error('请输入正确格式'))
    }else{
      callback()
    }
  }
  export default {
    data(){
      return {
        submitLoading: false,//表单是否正在提交中
        orderId: this.$route.query.orderId,//查看的订单id
        pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        rules: {
          orderId: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
            { min: 0, max: 30, message: '不超过30个字符', trigger: 'blur' }
          ],
          productId: [
            { required: true, message: '请选择商品', trigger: 'blur' }
          ],
          productPrice: [
            { required: true, message: '请选择商品', trigger: 'blur' }
          ],
          productSize: [
            { required: true, message: '请输入商品数量', trigger: 'blur' },
            { validator: limitInputNumber, trigger: 'blur' },
          ],
          totalFee: [
            { required: true, message: '请输入商品实付价', trigger: 'blur' },
            { validator: limitInputPointNumber, trigger: 'blur' }
          ],
          // buyerName: [
          //   { required: true, message: '请输入购买人姓名', trigger: 'blur' },
          //   { min: 0, max: 10, message: '不超过10个字符', trigger: 'blur' }
          // ],
          // buyerAddress: [
          //   { required: true, message: '请输入收件人地址', trigger: 'blur' },
          //   { min: 0, max: 30, message: '不超过30个字符', trigger: 'blur' }
          // ],
          // buyerPhone: [
          //   { validator: limitInputNumber, message: '请输入11位数字', trigger: 'blur' },
          //   {required: true, trigger: 'blur', validator: validPhone}
          // ],
          source: [
            {required: true, trigger: 'blur', message: '请选择订单来源'}
          ],
          dealerCode: [
            {required: true, trigger: 'blur', message: '请输入经销商身份码'},
            { min: 0, max: 6, message: '不超过6个字符', trigger: 'blur' }
          ],
          // snCode: [
          //   {required: true, trigger: 'blur', message: '请输入SN码'},
          //   { min: 12, max: 12, message: '等于12个字符', trigger: 'blur' },
          //   { validator: limitInputNumber, message: '请输入12位数字', trigger: 'blur' },
          // ],
          orderTime: [
            { type: 'date', required: true, message: '请选择购买时间', trigger: 'change' }
          ],
        },
        sources: [
          {
            label: '淘宝',
            value: '淘宝'
          },
          {
            label: '京东',
            value: '京东'
          }
        ],
        products: [],
        formInline: {
          orderId: '',
          productId: '',//商品名称
          productPrice: null,//商品价格
          totalFee: null,//商实付价
          source: '', //订单来源
          // buyerName: '', //购买人姓名
          // buyerPhone: '', //购买人电话
          // buyerAddress: '',//购买人地址
          orderTime: '',//购买时间
          dealerCode: '',//经销商身份码
        },
        dealers: [],
        allDealers: []
      }
    },
    mounted(){
      this.getProducts()
      // 获取该业务员下所有经销商
      this.getAllDealers()
    },
    methods: {
      // 还原数组
      getDealers(){
        this.dealers = this.allDealers
      },
      // 筛选经销商
      filterMethod(e){
        let arr = []
        if (e) { //val存在
          arr = this.allDealers.filter(v=>{
            if(v.dealerCode.indexOf(e)!== -1 || v.dealerName.indexOf(e)!== -1){
              return v
            }
          })
        }else{
          arr = this.allDealers
        }
        this.dealers = arr
      },
      // 获取该业务员下所有经销商
      getAllDealers(){
        this.$api.post('/v1/user/dealer/list',{},r=>{
          if(r.errorCode == '0'){
            let data = []
            r.data.map(v=>{
              var obj = {}
              obj.dealerCode = v.dealerUserInfo.code
              obj.dealerName = v.name
              data.push(obj)
            })
            this.allDealers = data
            this.dealers = data
          }else{
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0])
            } else {
              this.$message.error(r.msg)
            }
          }
        })
      },
      // 获取商品
      getProducts(){
        let tag = {
          tag: 'thirdProduct'
        }
        this.$api.get('/v1/products',tag,r=>{
          if(r.errorCode == '0'){
            this.products = r.data
            if(this.orderId){
              this.getOrderInfo()
            }
          }else{
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0])
            } else {
              this.$message.error(r.msg)
            }
          }
        })
      },
      // 获取订单详情
      getOrderInfo(){
        let params = {}
        params.orderId  = this.orderId
        this.$api.post('/v1/order/thirdOrderDetail',params,r=>{
          if(r.errorCode == '0'){
            let data = r.data
            if(data.productIdStr){
              for(let item of this.products){
                if(data.productIdStr == item.idStr){
                  data.productId = item.idStr
                  data.productPrice = item.price
                }
              }
            }
            if(data.orderTime){
              data.orderTime = new Date(data.orderTime)
            }
            this.formInline = data
          }else{
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0])
            } else {
              this.$message.error(r.msg)
            }
          }
        })
      },
      // 选中商品后 获取商品所有详情
      getProductsInfo(e){
        for(let item of this.products){
          if(e == item.idStr){
            this.formInline.productId = item.idStr
            this.formInline.productPrice = parseFloat(item.price)
          }
        }
      },
      link(path, query) {
        this.$router.push({ path: path, query: query });
      },
      goBack(){
        this.link('/orderUploadList')
      },
      submitForm(form){
        this.submitLoading = true
        this.$refs[form].validate((valid) => {
          if (valid) {
            let vo = this.$refs[form].model
            vo.productSize = parseInt(vo.productSize)
            this.$confirm(
              '上传后订单内容不可修改，请仔细核实订单内容，确定要上传订单么？',
              '提示',
              {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$api.post('/v1/order/uploadThirdOrder',vo,res=>{
                if(res.errorCode == '0'){
                  this.submitLoading = false
                  this.$message.success("订单已成功提交")
                  this.goBack()
                }else{
                  this.submitLoading = false
                  if (res.details && res.details[0]) {
                    this.$message.error(res.details[0])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              },f=>{
                this.submitLoading = false
                if (f === 403) {
                  this.logout()
                }
              },'json')
            }).catch(() => {
                this.submitLoading = false
            });

          } else {
            this.submitLoading = false
            return false;
          }
        });
      },
      logout() {
        var params = {};
        params.u = "/logout";
        sessionStorage.setItem("username", null);
        // this.$api.get('', params, r => {})
        this.link("/login");
      },
    }
  }
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.main-content {
  padding: 20px;
  height: 100%;
}
.box-card{
  height: 85%;
  overflow: scroll;
}
.box-card:last-child{
  padding-bottom: 100px;
}
.box-card >>> .el-page-header{
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.el-page-header >>> .el-page-header__content{
  color: #ee6600;
  font-weight: bold;
}
.form{
  width: 30%;
  margin-left: 20px;
}
.title{
  margin: 15px 0 10px;
  font-weight: bold;
  font-size: 14px;
}
.search-button{
  padding: 10px 55px;
}
.datePicker{
  width: 100% !important;
}
</style>