<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-page-header @back="goBack" content="新增商品"> </el-page-header>
        <div class="title">商品详情：</div>
        <el-form
          class="form"
          label-position="left"
          ref="formInline"
          :rules="rules"
          label-width="110px"
          :model="formInline"
        >
          <el-form-item label="商品名称：" prop="productName">
            <el-input
              v-model="formInline.productName"
              size="medium"
              maxlength="50"
              placeholder="不超过50个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品类型：" prop="tag">
            <el-select v-model="formInline.tag" :disabled="proId !== ''" placeholder="请选择">
              <el-option
                v-for="(item, k) in options"
                :key="k"
                :label="item"
                :value="k"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="formInline.tag === 'thirdProduct'"
            label="淘宝商品ID："
            prop="tbSkuId"
          >
            <el-input
              v-model="formInline.tbSkuId"
              size="medium"
              maxlength="18"
              placeholder="不超过18个字符"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-show="formInline.tag === 'thirdProduct'"
            label="京东商品ID："
            prop="jdSkuId"
          >
            <el-input
              v-model="formInline.jdSkuId"
              size="medium"
              maxlength="18"
              placeholder="不超过18个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品价格：" prop="price">
            <el-input
              v-model="formInline.price"
              size="medium"
              maxlength="8"
              placeholder="不超过8个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品原价：" prop="originalPrice">
            <el-input
              v-model="formInline.originalPrice"
              size="medium"
              maxlength="8"
              placeholder="不超过8个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品单位：" prop="unit">
            <el-input
              v-model="formInline.unit"
              size="medium"
              maxlength="8"
              placeholder="不超过8个字符"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="formInline.tag !== 'thirdProduct'"
            label="商品图片："
            label-width="110px"
          >
            <span slot="label"><span class="xing">*</span> 商品图片：</span>
            <el-upload
              action="/dealer/v1/upload"
              :file-list="idCardFrontPathList"
              :headers="headers"
              :data="flag"
              :disabled="disabled"
              name="multipartFile"
              :class="{ disabled: hidden }"
              list-type="picture-card"
              :multiple="false"
              :limit="1"
              accept=".jpg,.jpeg,.png"
              ref="upload"
              :auto-upload="autoUpload"
              class="businessLicense"
              :on-preview="handlePictureCardPreview"
              :on-error="handleFailed"
              :on-success="handleIDCardBeforeSuccess"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="imgNumTips">建议上传尺寸400x400，图片大小不超过5M</div>
          </el-form-item>
          <el-form-item
            v-if="formInline.tag !== 'thirdProduct'"
            label="商品轮播图："
            label-width="110px"
          >
            <span slot="label"><span class="xing">*</span> 商品轮播图：</span>
            <el-upload
              action="/dealer/v1/upload"
              :headers="headers"
              :data="flag"
              :file-list="contractPathList"
              :disabled="disabled"
              name="multipartFile"
              list-type="picture-card"
              :class="{ disabled: hidden }"
              :multiple="true"
              accept=".jpg,.jpeg,.png"
              ref="upload"
              :auto-upload="autoUpload"
              class="agencyContract"
              :on-preview="handlePictureCardPreview"
              :on-success="handleContractSuccess"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="imgNumTips">建议上传尺寸400x400，图片大小不超过5M</div>
          </el-form-item>
          <el-form-item
            v-if="formInline.tag !== 'thirdProduct'"
            label="商品详情："
            label-width="110px"
          >
            <span slot="label"><span class="xing">*</span> 商品详情：</span>
            <el-upload
              action="/dealer/v1/upload"
              :headers="headers"
              :data="flag"
              :file-list="licensePathList"
              :disabled="disabled"
              name="multipartFile"
              list-type="picture-card"
              :class="{ disabled: hidden }"
              :multiple="true"
              accept=".jpg,.jpeg,.png"
              ref="upload"
              :auto-upload="autoUpload"
              class="agencyContract"
              :on-preview="handlePictureCardPreview"
              :on-success="handleBusinessLicenseSuccess"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="imgNumTips">建议上传尺寸宽度750，图片大小不超过10M</div>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="submitLoading"
              class="search-button"
              type="primary"
              size="medium"
              @click="submitForm('formInline')"
              >确 定</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible1" top="20px">
      <img width="100%" :src="dialogImageUrl1" alt="" />
    </el-dialog>
  </el-main>
</template>

<script>
// import { fork } from 'cluster';
// 验证只能输入数字
const limitInputNumber = (rule, val, callback) => {
  // console.log(val);
  const reg = /^[1-9]\d*$/;
  if (!val) {
    // callback(new Error('输入为空！'))
    callback();
  } else if (!reg.test(val)) {
    callback(new Error("请输入数字"));
  } else {
    callback();
  }
};
// 限制只能输入数字(可以输入两位小数)
const limitInputPointNumber = (rule, val, callback) => {
  const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/;
  if (!val) {
    callback(new Error("输入为空！"));
  } else if (!reg.test(val)) {
    callback(new Error("请输入正确格式"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      contractPathArr: [], //商品轮播图
      contractPathList: [], //商品轮播图
      licensePathArr: [], //商品详情图片
      headers: {
        "client-name": "teleconsultation",
        "X-Auth-Token": this.$store.state.token,
      },
      autoUpload: true,
      dialogImageUrl1: "",
      dialogVisible1: false,
      idCardFrontPathList: [],
      licensePathList: [],
      proId: this.$route.query.id || "",
      disabled: false,
      hidden: false,
      submitLoading: false, //表单是否正在提交中
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      rules: {
        tbSkuId: [
          // { max: 50, message: '不超过50个字符', trigger: 'blur' },
          { validator: limitInputNumber, trigger: "blur" },
        ],
        jdSkuId: [
          // { max: 50, message: '不超过50个字符', trigger: 'blur' },
          { validator: limitInputNumber, trigger: "blur" },
        ],
        productName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          // { max: 50, message: "不超过50个字符", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入商品价格", trigger: "blur" },
          { validator: limitInputPointNumber, trigger: "blur" },
        ],
        tag: [{ required: true, message: "请选择商品类型", trigger: "blur" }],
        unit: [
          { required: true, message: "请输入商品单位", trigger: "blur" },
        ],
      },
      options: [],
      formInline: {
        productName: "", //商品名称
        tag: "", //商品类型
        tbSkuId: "", //商品价格
        jdSkuId: "", //商品数量
        price: "", //购买人姓名
        unit: "", //商品单位
        idCardFrontPath: "", //商品图片
        licensePath: "", //商品详情
        contractPath: "", //商品轮播图
      },
      flag: {'flag': 'product'}
    };
  },
  mounted() {
    // if (this.proId !== "") {
    //   this.getProInfo();
    // } else {
      this.getTage();
    // }
  },
  methods: {
    handleFailed(e) {
      console.log(e);
    },
    // 商品轮播图
    handleContractSuccess(res, file, fileList) {
      console.log(res);
      if (res.errorCode == "0") {
        this.getImageUrl(res.data, this.contractPathList, "contractPathList");
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0]);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    // 商品详情
    handleBusinessLicenseSuccess(res, file, fileList) {
      console.log(res);
      if (res.errorCode == "0") {
        this.getImageUrl(res.data, this.licensePathList, "licensePathList");
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0]);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    // 移除
    handleRemove(file, fileList) {
      this[file.name] = fileList;
      // switch (file.name) {
      //   case "idCardFrontPath":
      //     this.formInline.idCardFrontPath = "";
      //     this.idCardFrontPathList = [];
      //     break;
      //   case "idCardBackPath":
      //     this.formInline.idCardBackPath = "";
      //     this.idCardBackPathList = [];
      //     break;
      //   case "licensePath":
      //     this.formInline.licensePath = "";
      //     this.licensePathList = [];
      //     break;
      //   case "contractPath":
      //     let arr = this.formInline.contractPath.split(",");
      //     console.log(arr);
      //     arr.map((item, index) => {
      //       console.log(file);
      //       console.log(item);
      //       if (item == file.remoteUrl) {
      //         console.log(file);
      //         arr.splice(index, 1);
      //         this.formInline.contractPath = arr.join(",");
      //         console.log(this.formInline.contractPath);
      //       }
      //     });
      //     this.contractPathArr.map((item, index) => {
      //       if (item.uid == file.uid) {
      //         this.contractPathArr.splice(index, 1);
      //       }
      //     });
      //     break;
      //   default:
      //     break;
      // }
    },
    // 商品图片
    handleIDCardBeforeSuccess(res, file, fileList) {
      console.log(res);
      if (res.errorCode == "0") {
        // this.getImageUrl(res.data,this.idCardFrontPathList,'idCardFrontPath');
        this.getImageUrl(
          res.data,
          this.idCardFrontPathList,
          "idCardFrontPathList"
        );
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0]);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    // 超出限制时
    exceed() {
      this.$message.error("最多只能上传1张图片");
    },
    //获取商品分类
    getTage() {
      this.$api.get(
        "/v1/personalDisplay/tag",
        {},
        (r) => {
          if (r.errorCode == "0" && r.data) {
            let data = r.data;
            this.options = data;
            if(this.proId !== ''){
              this.getProInfo()
            }
            // for (const key in data) {
            //   console.log(key);
            //   let formInline = this.formInline;
            //   console.log(formInline);
            //   formInline.tag = key;
            //   this.formInline = formInline;
            //   // break;
            // }
          }
        },
        (f) => {
          if (f === 403) {
            this.logout();
          }
        }
      );
    },
    // 获取要修改的商品信息
    getProInfo() {
      this.$api.get(
        "/v1/productDetailInfo",
        { id: this.proId },
        (r) => {
          console.log(r);
          if (r.errorCode == "0") {
            // this.getTage();
            let data = r.data;
            this.formInline = {
              productName: data.displayName,
              tbSkuId: data.tbSkuId,
              jdSkuId: data.jdSkuId,
              price: data.price,
              originalPrice: data.originalPrice,
              id: data.idStr,
              tag: data.tag,
              unit: data.unit,
              idCardFrontPath: data.productPictures,
            };
            //商品图片
            if (data.productPictures) {
              // console.log(data.productPictures)
              this.idCardFrontPathList = [];
              let productPictures = JSON.parse(data.productPictures);
              productPictures.map((url) => {
                this.getImageUrl(
                  url,
                  this.idCardFrontPathList,
                  "idCardFrontPathList"
                );
              });
            }
            //轮播图
            if (data.rotationsPictures) {
              this.contractPathList = [];
              let rotationsPictures = JSON.parse(data.rotationsPictures);
              rotationsPictures.map((url) => {
                this.getImageUrl(
                  url,
                  this.contractPathList,
                  "contractPathList"
                );
              });
            }
            //详情图
            if (data.detailPictures) {
              this.licensePathList = [];
              let detailPictures = JSON.parse(data.detailPictures);
              detailPictures.map((url) => {
                this.getImageUrl(url, this.licensePathList, "licensePathList");
              });
            }
            // console.log(this.formInline);
          }
        },
        (f) => {
          if (f === 403) {
            this.logout();
          }
        }
      );
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    goBack() {
      this.link("/goodsList", { activeName: "selfUpload" });
    },
    //获取图片的本地路径
    getImageUrl(path, fileList, name) {
      this.$api.get(
        "/v1/download",
        { url: path },
        (r) => {
          if (r) {
            var img = URL.createObjectURL(r.request.response);
            fileList.push({ url: img, name: name, remoteUrl: path });
          }
        },
        (f) => {},
        "",
        "blob"
      );
    },
    submitForm(form) {
      // console.log(this.$refs[form]);
      // let model = this.$refs[form].model;
      this.submitLoading = true;
      this.$refs[form].validate((valid) => {
        console.log(valid);
        if (valid) {
          if (
            !this.idCardFrontPathList.length &&
            this.formInline.tag !== "thirdProduct"
          ) {
            this.$message.error("请选择商品图片");
            return false;
          }
          if (
            !this.contractPathList.length &&
            this.formInline.tag !== "thirdProduct"
          ) {
            this.$message.error("请选择商品轮播图片");
            return false;
          }
          if (
            !this.licensePathList.length &&
            this.formInline.tag !== "thirdProduct"
          ) {
            this.$message.error("请选择商品详情图片");
            return false;
          }
          let vo = this.$refs[form].model;
          // console.log(vo);
          vo.price = parseFloat(vo.price);
          if (vo.tbSkuId) {
            vo.tbSkuId = parseInt(vo.tbSkuId);
          }
          if (vo.jdSkuId) {
            vo.jdSkuId = parseInt(vo.jdSkuId);
          }
          if (this.proId !== "") {
            vo.id = this.formInline.id;
          }
          if(this.formInline.tag == 'care'){
            //商品图片
            vo.productPictures = [this.idCardFrontPathList[0].remoteUrl];
            // 处理商品轮播图片
            let contractImgArr = [];
            this.contractPathList.map((v) => {
              contractImgArr.push(v.remoteUrl);
            });
            vo.rotationsPictures = contractImgArr;

            // 处理商品详情图片
            let licenseImgArr = [];
            this.licensePathList.map((v) => {
              licenseImgArr.push(v.remoteUrl);
            });
            vo.detailPictures = licenseImgArr;
          }

          // console.log(vo);
          this.submitLoading = false;
          // return;
          this.$api.post(
            "/v1/product/addOrUpdate",
            vo,
            (r) => {
              if (r.errorCode == "0") {
                this.submitLoading = false;
                let msg = "";
                if (this.proId !== "") {
                  msg = "商品信息已修改";
                } else {
                  msg = "商品已新增";
                }
                this.$message.success(msg);
                this.goBack();
              } else {
                this.submitLoading = false;
                if (r.details && r.details[0]) {
                  this.$message.error(r.details[0]);
                } else {
                  this.$message.error(r.msg);
                }
              }
            },
            (f) => {
              this.submitLoading = false;
            }
          );
        } else {
          this.submitLoading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: hidden;
}
.box-card {
  height: 100%;
  overflow: scroll;
}
.box-card >>> .el-page-header {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.el-page-header >>> .el-page-header__content {
  color: #ee6600;
  font-weight: bold;
}
.form {
  margin: 20px 0 80px;
  width: 40%;
}
.search-button {
  padding: 10px 55px;
}
.datePicker {
  width: 100% !important;
}
.title {
  margin: 15px 0 10px;
  font-weight: bold;
  font-size: 14px;
}
.agencyContract >>> .el-upload-list--picture-card .el-upload-list__item {
  width: auto;
  height: auto;
}
.agencyContract >>> .el-upload-list__item-thumbnail {
  display: block;
}
.tipsText {
  font-size: 14px;
  line-height: 1;
  font-style: normal;
}
.imgNumTips {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 16px;
  color: #606266;
}
.xing {
  color: #f56c6c;
}
</style>