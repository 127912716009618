<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-page-header @back="goBack" content="分销商品新增">
        </el-page-header>
        <el-form class="form" label-position="left" ref="formInline" :hide-required-asterisk='true' :rules="rules" label-width="110px" :model="formInline">
          <el-form-item label="经销商名称：" prop="dealerName">
            <el-input disabled v-model="formInline.dealerName" size="medium" maxlength="50" placeholder="不超过50个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品名称：" prop="productName">
            <el-input disabled v-model="formInline.productName" size="medium" maxlength="50" placeholder="不超过50个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品价格：" prop="productPrice">
            <el-input disabled v-model="formInline.productPrice" size="medium" maxlength="6" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <el-form-item label="淘宝商品ID：" prop="tbId">
            <el-input disabled v-model="formInline.tbId" size="medium" maxlength="30" placeholder="不超过30个字符"></el-input>
          </el-form-item>
          <el-form-item label="京东商品ID：" prop="jdId">
            <el-input disabled v-model="formInline.jdId" size="medium" maxlength="30" placeholder="不超过30个字符"></el-input>
          </el-form-item>
          <!-- <el-form-item label="商品图片：" label-width="110px">
            <el-upload
                action="/dealer/v1/upload"
                :headers="headers"
                :file-list="productsImgs"
                :disabled="true"
                name="multipartFile"
                list-type="picture-card"
                :multiple="true"
                accept=".jpg,.jpeg,.png"
                ref="upload"
                :auto-upload="true"
                class="agencyContract"
                :on-preview="handlePictureCardPreview">
                <i class="tipsText">商品图片</i>
              </el-upload>
          </el-form-item> -->
          <el-form-item label="淘宝分销链接：" prop="tbLink">
            <el-input v-model="formInline.tbLink" size="medium" maxlength="256"  placeholder="请录入淘宝分销连接"></el-input>
          </el-form-item>
          <el-form-item label="京东分销链接：" prop="jdLink">
            <el-input v-model="formInline.jdLink" size="medium" maxlength="256"  placeholder="请录入京东分销连接"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :loading="submitLoading" class="search-button" type="primary" size="medium" @click="submitForm('formInline')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </el-main>
</template>

<script>
  // 验证只能输入数字
  const limitInputNumber = (rule,val,callback)=>{
    console.log(val);
    const reg = /^[1-9]\d*$/
    if(!val){
      callback()
    }else if(!reg.test(val)){
      callback(new Error('请输入数字'))
    }else{
      callback()
    }
  }
  // 限制只能输入数字(可以输入两位小数)
  const limitInputPointNumber = (rule,val,callback) =>{
    const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
    if(!val){
      callback(new Error('输入为空！'))
    }else if(!reg.test(val)){
      callback(new Error('请输入正确格式'))
    }else{
      callback()
    }
  }
  export default {
    data(){
      return {
        productId: this.$route.query.productId || '',
        dealerId: this.$route.query.dealerId || '',
        dealerName: this.$route.query.dealerName || '',
        submitLoading: false,//表单是否正在提交中
        pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        rules: {
          dealerName: [
            { required: true, message: '请输入经销商名称', trigger: 'blur' },
          ],
          productName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
          ],
          productPrice: [
            { required: true, message: '请输入商品价格', trigger: 'blur' },
            { validator: limitInputPointNumber, trigger: 'blur' }
          ],
          tbId: [
            { validator: limitInputNumber, trigger: 'blur' }
          ],
          jdId: [
            { validator: limitInputNumber, trigger: 'blur' }
          ],
        },
        formInline: {
          dealerName: '',//经销商名称
          productName: '',//商品名称
          productPrice: null,//商品价格
          tbId: '', //淘宝商品ID
          jdId: '',//京东商品ID
          tbLink: '',//淘宝链接
          jdLink: '',//京东链接
        }
      }
    },
    mounted(){
      if(this.productId !== ''){
        this.getGoodsInfo()
      }
    },
    methods: {
      // 查看分销商品信息
      getGoodsInfo(id){
        this.$api.get('/v1/productDealerShareDetailInfo',{productId: this.productId, dealerUserId : this.dealerId},r=>{
          if(r.errorCode == '0'){
            let data = r.data
            this.formInline = {
              dealerName: this.dealerName,//经销商名称
              productName: data.displayName,//商品名称
              productPrice: data.price,//商品价格
              tbId: data.tbSkuId, //淘宝商品ID
              jdId: data.jdSkuId,//京东商品ID
              tbLink: data.jdLink,//淘宝链接
              jdLink: data.tbLink,//京东链接
            }
          }
        },f=>{
          if (f === 403) {
            this.logout()
          }
        })
      },
      // 放大缩略图
      // handlePictureCardPreview(file) {
      //   this.dialogImageUrl = file.url;
      //   this.dialogVisible = true;
      // },
      link(path, query) {
        this.$router.push({ path: path, query: query });
      },
      logout() {
        var params = {};
        params.u = "/logout";
        sessionStorage.setItem("username", null);
        // this.$api.get('', params, r => {})
        this.link("/login");
      },
      goBack(){
        this.link('/distributionGoods',{id: this.dealerId,'dealerName': this.dealerName})
      },
      submitForm(form){
        this.submitLoading = true
        this.$refs[form].validate((valid) => {
          if (valid) {
            let params = {}
            let vo = this.$refs[form].model
            // if(!this.productsImgs.length){
            //   this.$message.error('请选择商品图片')
            //   return false
            // }
            params.jdLink = vo.jdLink
            params.tbLink = vo.tbLink
            params.productId = this.productId
            params.dealerUserId = this.dealerId
            console.log(vo);
            this.$api.post('/v1/product/link/saveOrUpdate',params,r=>{
              if(r.errorCode == '0'){
                this.submitLoading = false
                this.$message.success("商品信息已修改")
                this.goBack()
              }else{
                this.submitLoading = false
                if (r.details && r.details[0]) {
                  this.$message.error(r.details[0])
                } else {
                  this.$message.error(r.msg)
                }
              }
            },f=>{
                this.submitLoading = false
              },'json')
          } else {
            this.submitLoading = false
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.box-card{
  height: 100%;
}
.box-card >>> .el-page-header{
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.el-page-header >>> .el-page-header__content{
  color: #ee6600;
  font-weight: bold;
}
.form{
  margin-top: 20px;
  width: 30%;
}
.search-button{
  padding: 10px 55px;
}
.tipsText{
  font-size: 14px;
  line-height: 1;
  font-style: normal;
}
.agencyContract >>> .el-upload-list--picture-card .el-upload-list__item{
  width: auto;
  height: auto;
}
.form >>> .el-form-item__label{
  padding: 0;
}
.disabled >>> .el-upload--picture-card{
    display: none
}
</style>