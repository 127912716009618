<template>
 <div>
   <el-form :inline="true">
      <div class="title">被转移业务员：{{user.name}}</div>
      <el-form-item label="姓名">
        <el-input size="medium" v-model="name" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="userList()" v-has="'read'">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%">
        <el-table-column
        header-align="center"
        align="center"
        type="index"
        label="序号"
        width="60">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="用户姓名">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="phone"
        label="手机号">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="userRole"
        label="用户角色">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="button"
        label="操作">
        <template slot-scope="scope">
          <el-radio v-model="radio" :label="scope.row.id"><font>1</font></el-radio>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="sub-btn" size="medium" type="primary" @click="onSubmit()">提交转移</el-button>
 </div>
</template>

<script>
  export default {
    name: "changeDealer",
    data() {
      return {
        loading:false,
        tableData:[],
        radio:'',
        name:''
      }
    },
    props: {
      user:{
        type:Object,
        default(){
          return {}
        }
      }
    },
    mounted(){
      this.userList()
    },
    watch: {
    },
    methods: {
      userList() {
        var params = {}
        params.page = 0
        params.size = 100
        if(this.name) params.name = this.name
        this.loading = true
        this.$api.post('/v1/user/list',params,res=>{
          if(res.errorCode === '0'){
            this.loading = false
            if(res.data && res.data.content && res.data.content.length > 0){
              this.tableData = []
              for(var item of res.data.content){
                if(item.status === 'normal' && item.role !== 'root'){
                  switch (item.role) {
                    case 'root':
                      item.userRole = '超级管理员'
                      break;
                    case 'manger':
                      item.userRole = '业务经理'
                      break;
                    case 'salesman':
                      item.userRole = '业务员'
                      break;
                    case 'primaryDealer':
                      item.userRole = '一级经销商'
                      break;
                    case 'secondaryDealer':
                      item.userRole = '二级经销商'
                      break;
                    default:
                      break;
                  }
                  item.id = item.idStr
                  item.pid = item.pidStr
                  this.tableData.push(item)
                }
              }
            }
          }else{
            this.loading = false
            this.$message.error(res.msg)
          }
        },f=>{
          this.loading = false
          console.log(f);
          this.$message.error('操作失败！')
        })
      },
      onSubmit() {
        // console.log(this.form);
        if(this.loading) return
        if(!this.radio){
          this.$message.error('请选择需要转移的业务员！')
          return
        }
        this.$confirm('确定要转移经销商吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          var params = {}
          params.userId = this.user.id
          params.replaceId = this.radio
          this.$api.post('/v1/user/remove',params,res=>{
            this.loading = false
            if(res.errorCode === '0'){
              this.$message.success('操作成功!')
              this.$emit('reload');
            }else{
              this.$message.error(res.msg)
            }
          },f=>{
            this.loading = false
            console.log(f);
            this.$message.error(f.data.message)
          })
        }).catch(() => {   
        });     
      }
    }
  }
</script>

<style lang="scss" scoped>
  font{
    color: #ffffff;
  }
  .sub-btn{
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
  .title{
    text-align: center;
    margin-top: -25px;
    margin-bottom: 15px;
    color: #FE9F36;
  }
</style>