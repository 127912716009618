<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">商品名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入商品名称查询"
                  size="medium"
                  v-model="formInline.productName"
                >
                </el-input>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
              >查询</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="650"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="displayname" label="商品名称" min-width="180">
          </el-table-column>
          <el-table-column prop="price" label="商品价格（元）" min-width="150">
          </el-table-column>
          <el-table-column prop="commission" label="分销奖励（元）" min-width="150">
            <template slot-scope="scope">
              {{scope.row.commission === 0 ? scope.row.commission : (scope.row.commission == null ? '--' : scope.row.commission)}}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" @click="tbCode(scope.row.tblink)" v-if="scope.row.tblink">淘宝分销码</el-button>
                <div class="line" v-if="scope.row.tblink && scope.row.jdlink"></div>
                <div class="ganggang" v-if="!scope.row.tblink && !scope.row.jdlink">--</div>
                <el-button class="textBtn" type="text" @click="jdCode(scope.row.jdlink)" v-if="scope.row.jdlink">京东分销码</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog :show-close="false" :close-on-click-modal="true" class="dialog" :visible.sync="showCodeVisible" top="200px" width="500px">
      <vue-qr class="codeImg" :text="dealerCode" :size="400"></vue-qr>
      <!-- <div class="codeText">{{dealerCode}}</div> -->
    </el-dialog>
  </el-main>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      settlementTime: [],
      tableData: [],
      loading: true,
      total: 0,
      nowPage: 1,
      pageSize: 10,
      formInline: {
        productName: "",
      },
      submitData: {
        productName: "",
      },
      dealerCode: '',
      showCodeVisible: false
    };
  },
  components: {vueQr},
  mounted() {
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 查看淘宝分销码
    tbCode(id){
      this.dealerCode = id
      this.showCodeVisible = true
    },
    // 查看京东分销码
    jdCode(id){
      this.dealerCode = id
      this.showCodeVisible = true
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var bonusSettlementRes = {};
      if (this.submitData.productName) {
        bonusSettlementRes.productName = this.submitData.productName;
      }
      bonusSettlementRes.size = this.pageSize;
      bonusSettlementRes.page = parseInt(e) - 1;
      // this.tableData = [
      //   {
      //     name: '便携心电仪',
      //     price: '100',
      //     bonus: '50',
      //     tbId: '968386',
      //     jdId: '968386'
      //   },
      //   {
      //     name: '便携心电仪',
      //     price: '100',
      //     bonus: '50',
      //     tbId: '968386',
      //     jdId: '968386'
      //   },
      //   {
      //     name: '便携心电仪',
      //     price: '100',
      //     bonus: '50',
      //     jdId: '968386'
      //   }
      // ]
      // this.loading=false
      this.$api.post('/v1/product/Dealer/list',bonusSettlementRes,r=>{
        if(r.errorCode == '0'){
          this.tableData = r.data.content
          this.total = r.data.totalElements
          this.loading=false
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        this.loading=false
        if (f === 403) {
          this.logout()
        }
      },'json')

    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  /* width: 425px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.tableStyle >>>tr{
  height: 64px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px;
  height: fit-content;
}
.block{
  text-align: right;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
.ganggang{
  color: #FE9F36;
}
.dialog >>> .el-dialog__body{
  display: flex;
  justify-content: center;
  padding: 40px;
}
.dialog >>> .el-dialog__header{
  padding: 0 !important;
}
.codeImg{
  box-shadow: 0px 16px 38px 13px rgba(107, 107, 107, 0.09);
}
</style>