<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="角色">
      <el-select v-model="form.role" placeholder="选择角色">
          <el-option label="业务经理" value="manger"></el-option>
          <el-option label="业务员" value="salesman"></el-option>
          <el-option label="一级经销商" value="primaryDealer"></el-option>
          <el-option label="二级经销商" value="secondaryDealer"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="权限编辑">
      <el-tree
        :props="props"
        :data="menuList"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="roleMenus[form.role]"
        ref="tree"
      >
      </el-tree>
    </el-form-item>
    <el-form-item style="margin-top:60px">
      <el-button type="primary" @click="onSubmit" :loading="loading">确定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  export default {
    name: "setRole",
    data() {
      return {
        loading:false,
        form: {
          role: 'manger'
        },
        props: {
          children: 'children',
          label: 'menuName'
        }
      }
    },
    props: {
      menuList:{
        type:Array,
        default(){
          return []
        }
      },
      roleMenus:{
        type:Object,
        default(){
          return {}
        }
      },
      role:{
        type:String,
        default:'manger'
      }
    },
    watch: {
      "form.role": {
        handler(newVal, oldVal){
          this.$refs.tree.setCheckedNodes(this.roleMenus[newVal])
        },
        deep: true
      },
      role(newVal, oldVal){
        this.form.role = JSON.parse(JSON.stringify(newVal))
      }
    },
    mounted(){
      this.form.role = JSON.parse(JSON.stringify(this.role))
    },
    methods: {
      onSubmit() {
        if(!this.form.role){
          this.$message.error('角色选择错误!')
          return
        }
        let checkedMenus =  this.$refs.tree.getCheckedKeys()
        this.loading = true
        var params = {}
        params.role = this.form.role
        params.menuIds = checkedMenus || []
        this.$api.post('/v1/menu/power',params,r=>{
          this.loading = false
          if(r.errorCode === '0'){
            this.$message.success('保存成功!')
            this.$emit('reload');
          }else{
            this.$message.error(r.msg)
          }
        },err=>{
          this.loading = false
        },'json')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>