import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/layout/Home'
import Login from '@/pages/login/login'
import Workbench from '@/pages/workbench/workbench'
import OrderList from '@/pages/orderList/orderList'
import DataDetails from '@/pages/dataDetails/dataDetails'
import DealerList from '@/pages/dealerList/dealerList'
import ApplicationList from '@/pages/applicationList/applicationList'
import DistributorManager from '@/pages/distributorManager/distributorManager'
import AuditList from '@/pages/auditList/auditList'
import MenuManager from '@/pages/menuManager/menuManager'
import RoleManager from '@/pages/roleManager/roleManager'
import AccountManager from '@/pages/accountManager/accountManager'
import UploadOrder from '@/pages/uploadOrder/uploadOrder'
import BonusSettlement from '@/pages/bonusSettlement/bonusSettlement'
import ApplyDistributor from '@/pages/applicationList/applyDistributor'
import EditPassword from '@/pages/editPassword/editPassword'
import SaleDetailsMore from '@/pages/workbench/saleDetailsMore'
import OrderUploadList from '@/pages/orderUploadList/orderUploadList'
import EnterOrder from '@/pages/orderUploadList/enterOrder'
import DealerProducts from '@/pages/dealerProducts/dealerProducts'
import DistributionGoods from '@/pages/distributorManager/distributionGoods'
import EditDealerProducts from '@/pages/distributorManager/editDealerProducts'
import GoodsList from '@/pages/goodsList/goodsList'
import AddProduct from '@/pages/goodsList/addProduct'
import AllOrderList from '@/pages/allOrderList/allOrderList'


Vue.use(Router)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
 routes: [
    {
      path: '/',
      name: 'Home',
      meta: { requiresAuth: true }, // 添加表示需要验证
      component: Home,
     children: [
       {
         path: 'addProduct',
         component: AddProduct
       },
       {
         path: 'allOrderList',
         component: AllOrderList
       },
       {
         path: 'goodsList',
         component: GoodsList
       },
       {
         path: 'editDealerProducts',
         component: EditDealerProducts
       },
       {
         path: 'distributionGoods',
         component: DistributionGoods
       },
       {
         path: 'dealerProducts',
         component: DealerProducts
       },
       {
         path: 'orderUploadList',
         component: OrderUploadList
       },
       {
         path: 'enterOrder',
         component: EnterOrder
       },
       {
         path: 'saleDetailsMore',
         component: SaleDetailsMore
       },
       {
         path: 'applyDistributor',
         component: ApplyDistributor
       },
       {
         path: 'bonusSettlement',
         component: BonusSettlement
       },
       {
         path: 'auditList',
         component: AuditList
       },
       {
         path: 'menuManager',
         component: MenuManager
       },
       {
         path: 'roleManager',
         component: RoleManager
       },
       {
         path: 'accountManager',
         component: AccountManager
       },
       {
         path: 'distributorManager',
         component: DistributorManager
        },
       {
          path: 'workbench',
          component: Workbench
        },
        {
          path: 'orderList',
          component: OrderList
        },
        {
          path: 'uploadOrder',
          component: UploadOrder
        },
        {
          path: 'dataDetails',
          component: DataDetails
        },
        {
          path: 'dealerList',
          component: DealerList
        },
        {
          path: 'applicationList',
          component: ApplicationList
        }
      ]
    },
    {
      path: '/editPassword',
      name: 'EditPassword',
      component: EditPassword
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
})


