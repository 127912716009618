<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-page-header
          @back="goBack"
          :content="pageTitle"
        ></el-page-header>
        <div class="title">经销商详情：</div>
        <el-form
          class="form"
          :rules="rules"
          ref="formInline"
          label-position="left"
          label-width="110px"
          :model="formInline"

        >
          <el-form-item v-if="(level !== 'secondaryDealer' || from == 'auditList') || (level== 'secondaryDealer' && from =='distributorManager')" prop="userRole" label="经销商等级：">
            <el-select v-model="formInline.userRole"
                :disabled="userId !== '' ? true : false"
                 placeholder="请选择经销商等级"
                 style="width: 100%;"
                 @change="changePidShow">
              <el-option
                v-for="item in levels"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="(level !== 'secondaryDealer' && showPid) || from == 'auditList' || (level== 'secondaryDealer' && from =='distributorManager')"  prop="pid" label="上级经销商：">
            <el-select v-model="formInline.pid"
                :disabled="userId !== '' ? true : false"
                placeholder="请选择上级经销商"
                style="width: 100%;" >
              <el-option
                v-for="item in pids"
                :key="item.idStr"
                :label="item.name"
                :value="item.idStr">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name" label="经销商名称：">
            <el-input
              v-model="formInline.name"
              :disabled="disabled"
              size="medium"
              placeholder="不超过30个字符"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="经销商手机号：">
            <el-input
              v-model="formInline.phone"
              :disabled="disabled"
              maxlength="11"
              size="medium"
              placeholder="不超过11个字符"
            ></el-input>
          </el-form-item>
          <el-form-item prop="tbPositionId" label="淘宝推广位ID：" v-if="from !== 'applicationList'">
            <el-input
              v-model="formInline.tbPositionId"
              :disabled="from == 'auditList' && status !== 'approving' ? true : false"
              maxlength="18"
              size="medium"
              placeholder="不超过18个字符"
            ></el-input>
          </el-form-item>
          <el-form-item prop="jdPositionId" label="京东推广位ID：" v-if="from !== 'applicationList'">
            <el-input
              v-model="formInline.jdPositionId"
              :disabled="from == 'auditList' && status !== 'approving' ? true : false"
              maxlength="18"
              size="medium"
              placeholder="不超过18个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="经销商医院：">
            <el-input
              v-model="formInline.hospital"
              :disabled="disabled"
              size="medium"
              maxlength="10"
              placeholder="不超过10个字符"
            ></el-input>
          </el-form-item>
          <el-form-item label="经销商科室：">
            <el-input
              v-model="formInline.department"
              :disabled="disabled"
              size="medium"
              maxlength="10"
              placeholder="不超过10个字符"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="经销商身份证图片："
            class="IDPic"
            label-width="150px"
          >
            <span slot='label'><span class="xing">*</span> 经销商身份证图片：</span>
            <div class="picBefore">
                <el-upload
                  action="/dealer/v1/upload"
                  :file-list="idCardFrontPathList"
                  :headers="headers"
                  :class = "{disabled:hidden}"
                  :disabled="disabled"
                  name="multipartFile"
                  list-type="picture-card"
                  :limit="1"
                  accept=".jpg,.jpeg,.png"
                  ref="upload"
                  :auto-upload="autoUpload"
                  class="avatar-uploader"
                  :on-preview="handlePictureCardPreview"
                  :on-success="handleIDCardBeforeSuccess"
                  :on-remove="handleRemove">
                  <i class="tipsText">身份证正面照</i>
                </el-upload>
            </div>
            <div class="picAfter">
              <el-upload
                action="/dealer/v1/upload"
                :file-list="idCardBackPathList"
                :headers="headers"
                :disabled="disabled"
                name="multipartFile"
                list-type="picture-card"
                :class = "{disabled:hidden}"
                :limit="1"
                accept=".jpg,.jpeg,.png"
                ref="upload"
                :auto-upload="autoUpload"
                class="avatar-uploader"
                :on-preview="handlePictureCardPreview"
                :on-success="handleIDCardAfterSuccess"
                :on-remove="handleRemove">
                <i class="tipsText">身份证背面照</i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="经销商营业执照图片：" label-width="150px">
            <el-upload
                action="/dealer/v1/upload"
                :file-list="licensePathList"
                :headers="headers"
                :disabled="disabled"
                name="multipartFile"
                :class = "{disabled:hidden}"
                list-type="picture-card"
                :multiple="false"
                :limit="1"
                accept=".jpg,.jpeg,.png"
                ref="upload"
                :auto-upload="autoUpload"
                class="businessLicense"
                :on-preview="handlePictureCardPreview"
                :on-success="handleBusinessLicenseSuccess"
                :on-remove="handleRemove">
                <i class="tipsText">营业执照</i>
              </el-upload>

          </el-form-item>
          <el-form-item label="经销商代理合同：" label-width="150px">
            <span slot='label'><span class="xing">*</span> 经销商代理合同：</span>
            <el-upload
                action="/dealer/v1/upload"
                :headers="headers"
                :file-list="contractPathArr"
                :disabled="disabled"
                name="multipartFile"
                list-type="picture-card"
                :class = "{disabled:hidden}"
                :multiple="true"
                accept=".jpg,.jpeg,.png"
                ref="upload"
                :auto-upload="autoUpload"
                class="agencyContract"
                :on-preview="handlePictureCardPreview"
                :on-success="handleContractSuccess"
                :on-remove="handleRemove">
                <i class="tipsText">合同照片</i>
              </el-upload>
              <div class="imgNumTips">图片仅支持jpg、png、jpeg的图片</div>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="box-card">
        <div class="title">商品分成比例：</div>
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          max-height="700"
          :border="true"
          class="tableStyle"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column prop="displayName" label="商品名称" min-width="180">
          </el-table-column>
          <el-table-column
            prop="price"
            label="商品价格（元）"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            prop="ownCommission"
            :label="formInline.userRole == 'primaryDealer' ? '经销商分成奖励（元）' : '二级分销商分成奖励（元）'"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-input :disabled="disabled" v-model="scope.row.ownCommission"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            v-if="formInline.userRole !== 'primaryDealer'"
            prop="superCommission"
            label="上级分销上分成奖励（元）"
            min-width="180"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.superCommission"
                :disabled="disabled"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button v-if="(from !== 'auditList' && from !== 'applicationList') || (from == 'applicationList' && userId == '')" @click="submitApply('formInline')" class="search-button apply" type="primary"
          >提交申请</el-button
        >
        <el-button class="search-button apply" type="primary" @click="addAccount" v-if="from == 'auditList' && accountStatus == 'approving'">生成账号</el-button>
      </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible1" top="10px">
      <img width="100%" :src="dialogImageUrl1" alt="" />
    </el-dialog>
  </el-main>
</template>

<script>
const validPhone = (rule, value, callback) => {
  const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
  if (!value) {
    callback(new Error('请输入经销商手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确手机号码'))
  } else {
    callback()
  }
}
// 验证只能输入数字
const limitInputNumber = (rule,val,callback)=>{
  console.log(val);
  const reg = /^[1-9]\d*$/
  if(!val){
    callback(new Error('输入为空！'))
  }else if(!reg.test(val)){
    callback(new Error('请输入数字'))
  }else{
    callback()
  }
}
export default {
  data() {
    return {
      status: '',
      pageTitle: '创建经销商账号',
      showPid: false,//是否显示选择上级经销商
      idCardFrontPathList:[],
      idCardBackPathList:[],
      licensePathList:[],
      pids: [],
      levels: [
        {
          label: '一级经销商',
          value: 'primaryDealer'
        },
        {
          label: '二级经销商',
          value: 'secondaryDealer'
        }
      ],
      level: this.$route.query.level || '',
      from: this.$route.query.from || '',
      accountStatus: this.$route.query.status || '',
      userId: this.$route.query.id || '',
      disabled: this.$route.query.disabled == 'true' ? true : false,
      rules: {
        userRole: [
          {required: this.level !== 'secondaryDealer' ? true : false, message: '请选择经销商等级', trigger: 'blur'}
        ],
        pid: [
          {required: false, message: '请选择上级经销商', trigger: 'blur'}
        ],
        phone: [
          { validator: limitInputNumber, message: '请输入11位数字', trigger: 'blur' },
          {required: true, trigger: 'blur', validator: validPhone}
        ],
        tbPositionId: [
          // { min: 0, max: 50, message: '不超过50个字符', trigger: 'blur' }
        ],
        jdPositionId: [
          // { min: 0, max: 50, message: '不超过50个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入经销商名称', trigger: 'blur' },
          { min: 0, max: 30, message: '不超过30个字符', trigger: 'blur' }
        ],
        idCardFrontPath: [
          {required: true, message: '请选择身份证正面照片', trigger: 'blur',}
        ],
        idCardBackPath: [
          {required: true, message: '请选择身份证背面照片', trigger: 'blur',}
        ],
      },
      contractPathArr: [],
      formInline: {
        userRole: this.$route.query.level || '',
        commissionDetails: [],
        contractPath: '',
        department: '',
        hospital: '',
        idCardBackPath: '',
        idCardFrontPath: '',
        licensePath: '',
        name: '',
        phone: '',
        tbPositionId: '',
        jdPositionId: ''
      },
      autoUpload: true,
      headers: {
        'client-name':'teleconsultation',
        'X-Auth-Token': this.$store.state.token
      },
      loading: false,
      tableData: [],
      dialogImageUrl1: "",
      dialogVisible1: false,
      hideUpload1: false,
      hideUpload2: false,
      limitLength: 1,
      fileList1: [],
      fileList2: [],
      imageUrl: "",
      picBefore: "", //身份证正面
      picAfter: "", //身份证背面
      businessLicense: "", //营业执照
      token: '',
      editForm: {},
      hidden : false,
    };
  },
  mounted(){
    if(this.from == 'applicationList'){
      this.pageTitle = '二级经销商账号申请'
    }
    if(this.from == 'auditList'){
      this.hidden = true
    }
    this.getProducts()
    if(this.from !== 'applicationList'){
      this.getPrimaryDealerList()
    }

    if(this.level == 'primaryDealer'){
      this.showPid = false
      this.rules.pid[0].required = false
    }else if(this.level == 'secondaryDealer'){
      this.showPid = true
      this.rules.pid[0].required = true
    }
    // this.token = JSON.parse(sessionStorage.getItem('vuex')).token
  },
  methods: {
    // 超出限制时
    exceed(){
      this.$message.error('最多只能上传1张图片')
    },
    // 同意生成账号
    addAccount(){
      let params = {}
      if(this.formInline.jdPositionId){
        params.jdPositionId = parseInt(this.formInline.jdPositionId)
      }
      if(this.formInline.tbPositionId){
        params.tbPositionId = parseInt(this.formInline.tbPositionId)
      }
      params.id = this.userId
      params.status = 'agree'
      this.$api.post('/v1/agreeOrRefuseDealerApply',params,r=>{
        if(r.errorCode == '0'){
          this.$message.success('账号申请已同意')
          this.$router.go(-1)
        }else{
          this.$message.error(r.msg)
        }
      })
    },
    // 移除
    handleRemove(file) {
      console.log(file);
      switch (file.name) {
        case 'idCardFrontPath':
          this.formInline.idCardFrontPath = ''
          this.idCardFrontPathList = []
          break;
        case 'idCardBackPath':
          this.formInline.idCardBackPath = ''
          this.idCardBackPathList = []
          break;
        case 'licensePath':
          this.formInline.licensePath = ''
          this.licensePathList = []
          break;
        case 'contractPath':
          let arr = this.formInline.contractPath.split(',')
          console.log(arr);
          arr.map((item,index)=>{
            console.log(file);
            console.log(item);
            if(item == file.remoteUrl){
              console.log(file);
              arr.splice(index,1)
              this.formInline.contractPath = arr.join(',')
              console.log(this.formInline.contractPath);
            }
          })
          this.contractPathArr.map((item,index)=>{
            if(item.uid == file.uid){
              this.contractPathArr.splice(index,1)
            }
          })
          break;
        default:
          break;
      }
    },
    // 获取当前用户下的所有一级经销商列表
    getPrimaryDealerList(){
      this.$api.get("/v1/currentUser/primaryDealerList",{},r=>{
        if(r.errorCode == '0'){
          this.pids = r.data

        }
      })
    },
    // 选择二级经销商时 显示上级经销商选项
    changePidShow(){
      if(this.formInline.userRole == 'secondaryDealer'){
        this.showPid = true
        this.rules.pid[0].required = true
      }else{
        this.showPid = false
        this.rules.pid[0].required = false
      }
    },
    // 查看经销商申请信息
    getApplyInfo(){
      let url = ''
      let params = {}
      if(this.from == 'distributorManager'){
        url = '/v1/dealerDetailInfo'
        params = {
          userId: this.userId
        }
      }else if(this.from == 'auditList' || this.from =='applicationList'){
        url = '/v1/secondaryDealerApplyDetail'
        params = {
          id: this.userId
        }
      }
      this.$api.get(url,params,r=>{
        if(r.errorCode == '0'){
          let data = r.data
          console.log(data);
          if(this.from == 'applicationList' || this.from == 'auditList'){
            if(data.idCardFrontPath){
              this.getImageUrl(data.idCardFrontPath,this.idCardFrontPathList,'idCardFrontPath');
            }
            if(data.idCardBackPath){
              this.getImageUrl(data.idCardBackPath,this.idCardBackPathList,'idCardBackPath');
            }
            if(data.licensePath){
              this.getImageUrl(data.licensePath,this.licensePathList,'licensePath');
            }
            if(data.contractPath){
              let arr = data.contractPath.split(',')
              arr.map(img=>{
                this.getImageUrl(img,this.contractPathArr,'contractPath');
              })
            }
            if(data.commissionDetails){
              let commissionDetails = JSON.parse(data.commissionDetails)
              for(var i=0;i<commissionDetails.length;i++){
                for(var j=0;j<this.tableData.length;j++){
                  if(commissionDetails[i].productId == this.tableData[j].idStr){
                    this.tableData[j].ownCommission = commissionDetails[i].ownCommission
                    this.tableData[j].superCommission = commissionDetails[i].superCommission
                  }
                }
              }
            }
            this.formInline = {
              userId: this.userId,
              userRole: this.level,
              pid: data.applyUserIdStr,
              hospital: data.hospital,
              jdPositionId: data.jdPositionId || '',
              tbPositionId: data.tbPositionId || '',
              department: data.department,
              name: data.name,
              phone: data.phone,
              idCardFrontPath: data.idCardFrontPath,
              idCardBackPath: data.idCardBackPath,
              licensePath: data.licensePath,
              contractPath: data.contractPath,
              commissionDetails: JSON.parse(data.commissionDetails)
            }
            this.status = data.status
            console.log(this.formInline);

          }else if(this.from == 'distributorManager'){
            this.editForm = r.data
            let dealerUserInfo = data.dealerUserInfo
            if(dealerUserInfo.idCardFrontPath){
              this.getImageUrl(dealerUserInfo.idCardFrontPath,this.idCardFrontPathList,'idCardFrontPath');
            }
            if(dealerUserInfo.idCardBackPath){
              this.getImageUrl(dealerUserInfo.idCardBackPath,this.idCardBackPathList,'idCardBackPath');
            }
            if(dealerUserInfo.licensePath){
              this.getImageUrl(dealerUserInfo.licensePath,this.licensePathList,'licensePath');
            }
            if(dealerUserInfo.contractPath){
              let arr = dealerUserInfo.contractPath.split(',')
              arr.map(img=>{
                this.getImageUrl(img,this.contractPathArr,'contractPath');
              })
            }
            this.formInline = {
              userId: this.userId,
              pid: data.pidStr,
              userRole: this.level,
              hospital: dealerUserInfo.hospital,
              department: dealerUserInfo.department,
              name: data.name,
              jdPositionId: dealerUserInfo.jdPositionId || '',
              tbPositionId: dealerUserInfo.tbPositionId || '',
              phone: data.phone,
              idCardFrontPath: dealerUserInfo.idCardFrontPath,
              idCardBackPath: dealerUserInfo.idCardBackPath,
              licensePath: dealerUserInfo.licensePath,
              contractPath: dealerUserInfo.contractPath,
              commissionDetails: JSON.parse(dealerUserInfo.commissionDetails)
            }
            if(dealerUserInfo.commissionDetails){
              let commissionDetails = JSON.parse(dealerUserInfo.commissionDetails)
              for(var i=0;i<commissionDetails.length;i++){
                for(var j=0;j<this.tableData.length;j++){
                  if(commissionDetails[i].productId == this.tableData[j].idStr){
                    this.tableData[j].ownCommission = commissionDetails[i].ownCommission
                    this.tableData[j].superCommission = commissionDetails[i].superCommission
                  }
                }
              }
            }
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
    },
    // 获取商品
    getProducts(){
      this.$api.get('/v1/products',{},r=>{
        if(r.errorCode == '0'){
          r.data.map(v=>{
            v.superCommission = ''
            v.productId = v.idStr
            v.ownCommission = ''
            if(!v.displayName){
              v.displayName = v.name
            }
          })
          this.tableData = r.data
          if(this.userId){
            this.getApplyInfo()
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
    },
    // 提交申请
    submitApply(form){
      this.$refs[form].validate((valid)=>{
        if(valid){
          let model = this.$refs[form].model
          if(!model.idCardFrontPath || !model.idCardBackPath){
            this.$message.error('请选择身份证图片')
            return false
          }
          if(!this.contractPathArr.length){
            this.$message.error('请选择代理合同图片')
            return false
          }
          let data = this.tableData
          for(var i=0;i<data.length;i++){
            if(data[i].superCommission === ''){
              data[i].superCommission = 0
            }
            if(data[i].ownCommission === ''){
              data[i].ownCommission = 0
            }
            const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
            if(!reg.test(data[i].superCommission) || !reg.test(data[i].ownCommission)){
              this.$message.error('商品分成填写格式有误！')
              return false
            }
          }
          // 处理合同图片
          let arr = this.contractPathArr
          let contractImgArr = []
          for(var i=0;i<arr.length;i++){
            var imgUrl = ''
            if(arr[i].response){
              imgUrl = arr[i].response.data
            }else if(arr[i].url){
              imgUrl = arr[i].remoteUrl
            }
            contractImgArr.push(imgUrl)
          }

          model.contractPath = contractImgArr.join(',')
          // 处理商品分成
          let commissionDetails = []
          for(var i=0;i<data.length;i++){
            commissionDetails[i] = {
              ownCommission: parseFloat(data[i].ownCommission),
              productId: data[i].idStr,
              superCommission: parseFloat(data[i].superCommission)
            }
          }
          model.commissionDetails = commissionDetails
          // 推广位id
          if(model.jdPositionId){
            model.jdPositionId = parseInt(model.jdPositionId)
          }
          if(model.tbPositionId){
            model.tbPositionId = parseInt(model.tbPositionId)
          }
          let url = ''
          if(((this.from == 'applicationList' || this.from == 'auditList') && this.userId !== '') || (this.from == 'applicationList' && this.userId == '')){
            url = '/v1/secondaryDealerApply'
          }else if(this.from == 'distributorManager'){
            url = '/v1/createDealer'
          }
          if(this.userId!== ''){
            model.userId = this.userId
          }
          console.log(model);
          // return false
          this.$api.post(url,model,r=>{
            if(r.errorCode == '0'){
              this.$message.success('提交申请成功')
              this.$router.go(-1)
            }else{
              this.$message.error(r.msg)
            }
          },f=>{
            if (f === 403) {
              this.logout()
            }
          },'json')

        }else{
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 合同
    handleContractSuccess(res,file,fileList){
      console.log(fileList);
      if(res.errorCode == '0'){
        this.contractPathArr = fileList
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0])
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 营业执照
    handleBusinessLicenseSuccess(res,file,fileList){
      if(res.errorCode == '0'){
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 2000
        })
        this.formInline.licensePath = res.data
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0])
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 身份证背面
    handleIDCardAfterSuccess(res,file,fileList){
      if(res.errorCode == '0'){
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 2000
        })
        this.formInline.idCardBackPath = res.data
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0])
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    // 身份证正面
    handleIDCardBeforeSuccess(res, file, fileList){
      console.log(res,file, fileList);
      if(res.errorCode == '0'){
        // this.getImageUrl(res.data,this.idCardFrontPathList,'idCardFrontPath');
        this.formInline.idCardFrontPath = res.data
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 2000
        })
      } else {
        if (res.details && res.details[0]) {
          this.$message.error(res.details[0])
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    handleChange(file, fileList) {
      const isJPG = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
      const isLT15M = file.raw.size / 1024 / 1024 < 15;
      if (!isJPG) {
        // this.hideUpload1 = false
        this.$message.error("上传图片只能为jpg或者png格式");
        return;
      }
      if (!isLT15M) {
        // this.hideUpload1 = false
        this.$message.error("上传图片大小不能超过15M");
        return;
      }
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
      }
      this.hideUpload1 = fileList.length >= this.limitLength;
      this.fileList = fileList;
    },
    // 放大缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    //获取图片的本地路径
    getImageUrl(path,fileList,name){
      this.$api.get('/v1/download',{url: path},r=>{
        if(r){
          var img = URL.createObjectURL(r.request.response)
          fileList.push({url:img,name: name,remoteUrl: path})
        }
      },f=>{},'','blob')
    },
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
  },
};
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: scroll;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.box-card {
  /* height: 100%; */
}
.box-card:last-child {
  margin-bottom: 50px;
}
.box-card >>> .el-page-header {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.el-page-header >>> .el-page-header__content {
  color: #ee6600;
  font-weight: bold;
}
.form {
  width: 30%;
}
.form >>> .el-form-item__label {
  padding-right: 0;
}
.IDPic >>> .el-form-item__content {
  display: flex;
}
.picBefore {
  margin-right: 20px;
}
.hide >>> .el-upload--picture-card {
  display: none;
}
.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 20px 0;
}
.search-button {
}
.search-button.apply {
  display: block;
  margin: 20px auto;
}

.tableStyle >>> .el-input {
  width: 100px;
}
.tableStyle >>> .el-input__inner {
  border: none;
  border-bottom: 1px solid #dcdfe6;
  text-align: center;
  border-radius: 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/* .el-upload--picture-card{
  width: 157px;
  height: 93px;
} */
.avatar-uploader >>> .avatar-uploader-icon {
  width: 157px;
  height: 93px;
  line-height: 93px;
}
.tipsText{
  font-size: 14px;
  line-height: 1;
  font-style: normal;
}
.avatar-uploader >>> .el-upload {
  background: #fbfdff;
}
.businessLicense >>> .el-upload--picture-card{
  width: 157px;
  height: 93px;
  line-height: 93px;
}
.avatar-uploader >>> .el-upload--picture-card{
  width: 157px;
  height: 93px;
  line-height: 93px;
}
.avatar-uploader >>> .el-upload-list--picture-card .el-upload-list__item{
  width: auto;
  height: auto;
}
.avatar-uploader >>> .el-upload-list__item-thumbnail{
  display: block;
}
.businessLicense >>> .el-upload-list--picture-card .el-upload-list__item{
  width: auto;
  height: auto;
}
.businessLicense >>> .el-upload-list__item-thumbnail{
  display: block;
}
.agencyContract >>> .el-upload-list--picture-card .el-upload-list__item{
  width: auto;
  height: auto;
}
.agencyContract >>> .el-upload-list__item-thumbnail{
  display: block;
}
.disabled >>> .el-upload--picture-card{
    display: none
}
.imgNumTips{
  font-size: 12px;
  line-height: 1;
  margin-top: 16px;
  color: #606266;
}
.xing{
  color: #F56C6C;
}
</style>