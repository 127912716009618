import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import './assets/css/style.css'
import api from './api/index.js' // axios
import format from './assets/js/format.js' // 时间格式化
import VueClipboard from 'vue-clipboard2'
import store from './store'
import htmlToPdf from '@/components/utils/htmlToPdf'
import './theme/index.css'
// import './assets/js/rem.js'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$api = api
Vue.prototype.$format = format
Vue.use(VueClipboard)
Vue.use(htmlToPdf)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 这里判断用户是否登录，验证本地存储是否有token
    if (!sessionStorage.getItem('username')) { // 判断当前的token是否存在
      next({
        path: '/login'
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

const has = Vue.directive("has", {
  inserted: function(el, binding) {
      if(binding.value.includes('&&')){
        //且操作
        let arr = binding.value.split("&&")
        if (!Vue.prototype.$_has(arr[0].trim()) || !Vue.prototype.$_has(arr[1].trim())) {
          el.parentNode.removeChild(el);
        }
      }else if(binding.value.includes('||')){
        //且操作
        let arr = binding.value.split("||")
        if (!Vue.prototype.$_has(arr[0].trim()) && !Vue.prototype.$_has(arr[1].trim())) {
          el.parentNode.removeChild(el);
        }
      }else{
        // 获取按钮权限// 获取按钮权限
        if (!Vue.prototype.$_has(binding.value)) {
          el.parentNode.removeChild(el);
        }
      }

      
  }
});
const notHas = Vue.directive("notHas", {
  inserted: function(el, binding) {
      // 获取按钮权限// 获取按钮权限
      if (Vue.prototype.$_has(binding.value)) {
          el.parentNode.removeChild(el);
      }
  }
});
//// 权限检查方法（且把该方法添加到vue原型中）
Vue.prototype.$_has = function(value) {
  let isExit = false;
  if(store.state.role === 'root'){
    isExit = true
  }else{
    let path = router.history.current.path
    let power = store.state.power
    for (const key in power) {
      if (Object.hasOwnProperty.call(power, key)) {
        const element = power[key];
        if(element.path === path && element.power.includes(value)){
          isExit = true
        }
      }
    }
  }
  return isExit;
};


/* eslint-disable no-new */
new Vue({
  el: '#app',
  has,
  notHas,
  store,
  router,
  components: { App },
  template: '<App/>'
})
export default Vue
