<template>
  <div class="page">
    <div class="content">
      <!-- <div class="title">哈特瑞姆诊断后台</div> -->
      <img src="../../assets/images/logo-login.png" class="login-logo" />
      <div class="loginForm">
        <div class="formTitle">经销商管理平台</div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="0"
          class="demo-ruleForm"
        >
          <el-form-item  prop="userName">
            <!-- <div slot="label" class="labelStyle">用户名</div> -->
            <el-input class="inputStyle" type="text" placeholder="登录手机号" v-model="ruleForm.userName"></el-input>
          </el-form-item>
          <el-form-item prop="pass">
            <!-- <div slot="label"  class="labelStyle">密码</div> -->
            <el-input
              class="inputStyle"
              placeholder="登录密码"
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item> -->
            <el-button
              class="search-button"
              :loading="loginLoading"
              type="primary"
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
          <!-- </el-form-item> -->
        </el-form>
        <div class="editPassword" @click="link('/editPassword')">修改密码？</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        userName: "",
      },
      loginLoading: false,
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  created () {
    document.addEventListener('keydown', this.watchEnter)
  },
  destroyed () {
    document.removeEventListener('keydown', this.watchEnter)
  },
  methods: {
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    submitForm(formName) {
      console.log(this.$refs[formName].model);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.username = this.$refs[formName].model.userName
          this.password = this.$refs[formName].model.pass
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 监听回车按钮事件
    watchEnter (e) {
      var keyNum = window.event ? e.keyCode : e.which // 获取被按下的键值
      // 判断如果用户按下了回车键（keycody=13）
      if (keyNum === 13) {
        console.log(this.ruleForm.pass);
        this.submitForm('ruleForm')
      }
    },
    login() {
      if (this.loginLoading) {
        return false
      }
      if (!this.username) {
        this.$message.error('请填写登录手机号')
        return false
      }
      if (!this.password) {
        this.$message.error('请填写登录密码')
        return false
      }
      this.loginLoading = true
      var params = {}
      params.username = this.username
      params.password = this.password
      this.$api.post('/login',params,res=>{
        if(res.token){
          sessionStorage.setItem('username', res.dealerName)
          this.$store.commit('setToken',res.token)
          this.$store.commit('setRole',res.role)
          let menuList = []
          let menuIds = {}//可用的菜单
          //获取可用菜单
          if(res.role !== 'root'){
            if(res.powerList){
              //加入菜单父级
              res.powerList.map(v=>{
                res.menuList.map(menu=>{
                  if(v === menu.idStr){
                    //获取它的父级放入powerList
                    if(menu.pIdStr !== '0') res.powerList.push(menu.pIdStr)
                  }
                })
              })
              res.powerList.map(v=>{
                if(v){
                  let arr = []
                  arr = v.split("-");
                  if(arr.length === 1){
                    //不可拆分
                    if(!menuIds[v]){
                      menuIds[v] = {}
                      menuIds[v].power = []
                    }
                  }else{
                    //可拆分
                    if(!menuIds[arr[0]]) menuIds[arr[0]] = {}
                    if(!menuIds[arr[0]].power) menuIds[arr[0]].power = []
                    menuIds[arr[0]].power.push(arr[1])
                  }
                }
              })
            }
            if(res.menuList){
              res.menuList.map(menu=>{
                for (const id in menuIds) {
                  if(menu.idStr === id && menu.status === '启用'){
                    menuIds[id].path = menu.jumpWay
                    menuList.push(menu)
                  }
                }
              })
            }
            //递归将菜单分层
            menuList = this.setMenuList(menuList,'0')
            // console.log(menuList)
          }else{
            res.menuList.map(menu=>{
                if( menu.status === '启用'){
                  menuList.push(menu)
                }
              })
            //root自动获得所有菜单
            menuList = this.setMenuList(menuList,'0')
          }
          console.log(menuList)
          this.$store.commit('setMenu',menuList)
          this.$store.commit('setPower',menuIds)
          this.$message({
            message: "登录成功！",
            type: "success",
          });
          setTimeout((res) => {
            this.loginLoading = false;
            let jumpWay = '/'
            if(menuList[0]){
              if(menuList[0].children){
                jumpWay = menuList[0].children[0].jumpWay
              }else{
                jumpWay = menuList[0].jumpWay
              }
            }
            this.link(jumpWay); //此处/orderList 其实走了俩层路由，/ 一层父路由， orderList一层子路由
          },500);
        }
      },f=>{
        console.log(f);
        this.loginLoading = false
        this.$message.error(f.data.message)
      })
    },
    //递归设置菜单层级
    setMenuList(data,pid){
      let rs = []
      data.map(v=>{
        if(v.pIdStr === pid){
          let item = JSON.parse(JSON.stringify(v));
          item.children = this.setMenuList(data,v.idStr)
          if(item.children.length < 1){
            delete item.children
          }
          rs.push(item)
        }
      })
      return rs
    }
  },
};
</script>

<style scoped>
.page {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  min-height: 800px;
  overflow: auto;
  background: url("../../assets/images/loginBg.jpg") no-repeat;
  background-size: cover;
  background-position:center;
  display: flex;
  align-items:center;
  flex-direction:row-reverse;
}
.input-box {
  text-align: center;
  padding-top: 100px;
}
.content {
  min-width: 37.5%;
  margin:0 100px;
  margin-bottom: 50px;
  float:right;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .title {
  color: #ffffff;
  font-size: 55px;
  font-weight: bold;
}
.content .login-logo {
  width: 180px;
}
.content .loginForm {
  background: #6A91FF;
  border-radius: 15px;
  box-shadow: 0px 7px 69px 29px rgba(116, 116, 116, 0.06);
  padding: 56px 48px;
  margin-top: 50px;
  min-width: 198px;
}
.formTitle{
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  /* word-spacing: 7px; */
  letter-spacing: 6px;
  text-align: center;
  margin-bottom: 28px;
}
.loginForm >>> .el-form-item{
  margin-bottom: 24px;
}
.loginForm >>> .el-form-item label:after {
  content: " ";
  display: inline-block;
  width: 100%;
}

.loginForm >>> .el-form-item__label {
  text-align: justify;
  font-weight: bold;
  color: #666666;
  font-size: 16px;
}
/* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
.loginForm >>> .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
.search-button {
  background: #FE9F36;
  border-radius: 37px;
  font-size: 16px;
  height: 40px;
  border-color: #FE9F36;
  width: 100%;
  color: #ffffff;
}

.inputStyle>>>.el-input__inner{
  background: transparent;
  border-color: #AAAAAA;
  border-radius: 37px;
  border: 1px solid #AAAAAA;
  height: 40px;
  padding: 0 22px;
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
}
.inputStyle >>> .el-input__inner::placeholder {
  color: #ffffff;
  font-size: 16px;
}
.inputStyle >>> .el-input__suffix{
  right: 20px;
}
.editPassword{
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
  margin-top: 36px;
  float: right;
  cursor: pointer;
}
</style>