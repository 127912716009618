<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-button size="medium" :type="details === 'third' ? 'primary' : ''" class="search-tab" @click="changeDetails('third')">数据详情（淘宝/京东）</el-button>
        <el-button size="medium" :type="details !== 'third' ? 'primary' : ''" class="search-tab" @click="changeDetails('applet')">数据详情（小程序）</el-button>
      </el-card>
      <el-card class="box-card">
        <div class="topCardWrapper">
          <div class="linkCard" >
            <div class="name">一级经销商数（个）</div>
            <div class="num">
              {{ numData.primaryDealerNum ? numData.primaryDealerNum : 0 }}
            </div>
          </div>
          <div class="linkCard">
            <div class="name">二级经销商数（个）</div>
            <div class="num">
              {{
                numData.secondaryDealerNum ? numData.secondaryDealerNum : 0
              }}
            </div>
          </div>
          <div class="linkCard">
            <div class="name">历史订单数（个）</div>
            <div class="num">
              {{ numData.orderSize ? numData.orderSize : 0 }}
            </div>
          </div>
          <div class="linkCard">
            <div class="name">历史总销售额（元）</div>
            <div class="num">
              {{
                numData.saleVolume
                  ? numData.saleVolume
                  : 0
              }}
            </div>
          </div>
          <div class="linkCard">
            <div class="name">未发放奖金数（元）</div>
            <div class="num">
              {{
                numData.bonusAmountUnsettled
                  ? numData.bonusAmountUnsettled
                  : 0
              }}
            </div>
          </div>
          <div class="linkCard" >
            <div class="name">历史发放奖总金数（元）</div>
            <div class="num">
              {{
                numData.bonusAmountSettled
                  ? numData.bonusAmountSettled
                  : 0
              }}
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card everyDay">
          <div class="search">
            <div class="searchText">销售订单数（个）：</div>
            <el-date-picker
              v-if="everyStatus1 == 'day' || everyStatus1 == 'month'"
              :key="everyStatus1"
              class="datePicker"
              :default-time="['12:00:00', '08:00:00']"
                size="small"
              :picker-options="pickerOptions1"
              v-model="dataEveryDay1"
              :type="
                everyStatus1 == 'day'
                  ? 'daterange'
                  : everyStatus1 === 'month'
                  ? 'monthrange'
                  : 'year'
              "
              range-separator="至"
              :start-placeholder="
                everyStatus1 == 'day'
                  ? '开始日期'
                  : everyStatus1 === 'month'
                  ? '开始月份'
                  : '选择年'
              "
              :end-placeholder="
                everyStatus1 == 'day'
                  ? '结束日期'
                  : everyStatus1 === 'month'
                  ? '结束月份'
                  : '选择年'
              "
            >
            </el-date-picker>
            <div class="year-item" v-else>2018年至今</div>
            <div
              class="searchBtn"
              v-if="everyStatus1 == 'day' || everyStatus1 == 'month'"
              @click="searchData(1)"
            >
              搜索
            </div>
            <div class="searchBtn searchBtnYear" v-else @click="searchData(1)">
              搜索
            </div>
            <el-button-group class="btnGroup">
              <el-button
                class="chooseBtn"
                ref="date"
                size="medium"
                :type="everyStatus1 === 'day' ? 'primary' : ''"
                @click="turnDate('day','everyStatus1')"
                >日</el-button
              >
              <el-button
                class="chooseBtn"
                ref="month"
                size="medium"
                :type="everyStatus1 === 'month' ? 'primary' : ''"
                @click="turnDate('month','everyStatus1')"
                >月</el-button
              >
              <el-button
                class="chooseBtn"
                ref="year"
                size="medium"
                :type="everyStatus1 === 'year' ? 'primary' : ''"
                @click="turnDate('year','everyStatus1')"
                >年</el-button
              >
            </el-button-group>
          </div>
          <div
            class="main-canvas"
            id="container1"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card everyDay">
          <div class="search">
            <div class="searchText">销售金额（元）：</div>
            <el-date-picker
              :key="everyStatus2"
              v-if="everyStatus2 == 'day' || everyStatus2 == 'month'"
              class="datePicker"
                size="medium"
              :picker-options="pickerOptions2"
              v-model="dataEveryDay2"
              :type="
                everyStatus2 == 'day'
                  ? 'daterange'
                  : everyStatus2 === 'month'
                  ? 'monthrange'
                  : 'year'
              "
              range-separator="至"
              :start-placeholder="
                everyStatus2 == 'day'
                  ? '开始日期'
                  : everyStatus2 === 'month'
                  ? '开始月份'
                  : '选择年'
              "
              :end-placeholder="
                everyStatus2 == 'day'
                  ? '结束日期'
                  : everyStatus2 === 'month'
                  ? '结束月份'
                  : '选择年'
              "
            >
            </el-date-picker>
            <div class="year-item" v-else>2018年至今</div>
            <div
              class="searchBtn"
              v-if="everyStatus2 == 'day' || everyStatus2 == 'month'"
              @click="searchData(2)"
            >
              搜索
            </div>
            <div class="searchBtn searchBtnYear" v-else @click="searchData(2)">
              搜索
            </div>
            <el-button-group class="btnGroup">
              <el-button
                class="chooseBtn"
                ref="date"
                size="medium"
                :type="everyStatus2 === 'day' ? 'primary' : ''"
                @click="turnDate('day','everyStatus2')"
                >日</el-button
              >
              <el-button
                class="chooseBtn"
                ref="month"
                size="medium"
                :type="everyStatus2 === 'month' ? 'primary' : ''"
                @click="turnDate('month','everyStatus2')"
                >月</el-button
              >
              <el-button
                class="chooseBtn"
                ref="year"
                size="medium"
                :type="everyStatus2 === 'year' ? 'primary' : ''"
                @click="turnDate('year','everyStatus2')"
                >年</el-button
              >
            </el-button-group>
          </div>
          <div
            class="main-canvas"
            id="container2"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="card everyDay">
          <div class="search">
            <div class="searchText">经销商销售详情：</div>
            <el-date-picker
              v-if="everyStatus3 == 'day' || everyStatus3 == 'month'"
              :key="everyStatus3"
              class="datePicker"
              :picker-options="pickerOptions1"
              size="medium"
              v-model="dataEveryDay3"
              :type="
                everyStatus3 == 'day'
                  ? 'daterange'
                  : everyStatus3 === 'month'
                  ? 'monthrange'
                  : 'year'
              "
              range-separator="至"
              :start-placeholder="
                everyStatus3 == 'day'
                  ? '开始日期'
                  : everyStatus3 === 'month'
                  ? '开始月份'
                  : '选择年'
              "
              :end-placeholder="
                everyStatus3 == 'day'
                  ? '结束日期'
                  : everyStatus3 === 'month'
                  ? '结束月份'
                  : '选择年'
              "
            >
            </el-date-picker>
            <div class="year-item" v-else>2018年至今</div>
            <div
              class="searchBtn"
              v-if="everyStatus3 == 'day' || everyStatus3 == 'month'"
              @click="searchData(3)"
            >
              搜索
            </div>
            <div class="searchBtn searchBtnYear" v-else @click="searchData(3)">
              搜索
            </div>
            <div class="tips">
              <div class="tip">
                <span class="colorTip"></span>
                <span class="colorExplain">销售订单数</span>
              </div>
              <div class="tip">
                <span class="colorTip distributor"></span>
                <span class="colorExplain">销售总金额</span>
              </div>
              <div class="tip">
                <span class="colorTip gotAward"></span>
                <span class="colorExplain">获得奖金</span>
              </div>
            </div>
            <div class="checkMore" @click="link('/saleDetailsMore',{'tag': details})">查看更多>>></div>
          </div>
          <div
            class="main-canvas"
            id="container3"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></div>
        </div>
      </el-card>
    </div>
  </el-main>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      details: 'third',
      startTime1: '',
      endTime1: '',
      startTime2: '',
      endTime2: '',
      startTime3: '',
      endTime3: '',
      orderMoneyData: [], //销售金额
      orderData: [], //销售订单数
      loading: false,
      dataEveryDay1: "",
      dataEveryDay2: "",
      dataEveryDay3: '',
      everyStatus1: "day",
      everyStatus2: "day",
      everyStatus3: "day",
      numData: {
        primaryDealerNum: '',
        secondaryDealerNum: '',
        orderSize: '',
        saleVolume: '',
        bonusAmountUnsettled: '',
        bonusAmountSettled: ''
      },
      pickerOptions1: {
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime();
          if (maxDate) {
            this.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          // if (this.pickerMinDate !== "") {
          //   return time.getTime() > new Date();
          // }
          return time.getTime() > Date.now();
        },
      },
      pickerOptions2: {
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime();
          if (maxDate) {
            this.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          // if (this.pickerMinDate !== "") {
          //   return time.getTime() > new Date();
          // }
          return time.getTime() > Date.now();
        },
      },

    };
  },
  mounted() {
    this.getData()
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 获取数据
    getData(){
      this.getTopData()
      this.searchData1();
      this.searchData2();
      this.searchData3();
    },
    // 修改数据详情来源
    changeDetails(source){
      this.details = source
      this.getData()
    },
    formatNumber(num) {
      return num >= 1e3  ? (num / 1e3).toFixed(1) + 'k' : num
    },
    // 获取头部各项数据
    getTopData(){
      // 一级/二级经销商
      let roles = ['primaryDealer','secondaryDealer']
      for(let i = 0;i<roles.length;i++){
        let params = {}
        params.role = roles[i]
        this.$api.get('/v1/dealerSize',params,r=>{
          if(r.errorCode == '0'){
            if(roles[i] == 'primaryDealer'){
              this.numData.primaryDealerNum = this.formatNumber(r.data)
            }else if(roles[i] == 'secondaryDealer'){
              this.numData.secondaryDealerNum = r.data
            }
          }
        })
      }
      let tag = {
        tag: this.details
      }
      // 历史订单数
      this.$api.get('/v1/orderSize',tag,r=>{
        if(r.errorCode == '0'){
          this.numData.orderSize = this.formatNumber(r.data)
        }
      })
      // 历史总销售额
      this.$api.get('/v1/saleVolume',tag,r=>{
        if(r.errorCode == '0'){
          this.numData.saleVolume = this.formatNumber(r.data)
        }
      })
      // 未发放奖金数/已发放奖金数
      let status = ['unsettled','settled']
      for(let j = 0;j<status.length;j++){
        let param = {}
        param.status = status[j]
        param.tag = this.details
        this.$api.get('/v1/bonusAmount',param,r=>{
          if(r.errorCode == '0'){
            if(status[j] == 'unsettled'){
              this.numData.bonusAmountUnsettled = this.formatNumber(r.data)
            }else if(status[j] == 'settled'){
              this.numData.bonusAmountSettled = this.formatNumber(r.data)
            }
          }
        })
      }
    },
    dateFormate(date){
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    },
    // 经销商销售详情
    searchData3(){
      if(!this.dataEveryDay3){
        this.dataEveryDay3 = [this.dateFormate(new Date(new Date().getTime()-15*24*3600*1000)),this.dateFormate(new Date())]
        this.searchData(3)
        return
      }
      var dealerRes = {}
      dealerRes.start=this.startTime3 ? this.startTime3 : null
      dealerRes.end=this.endTime3 ? this.endTime3 : null
      dealerRes.page = 0
      dealerRes.size = 15
      dealerRes.tag = this.details
      //特殊处理年，默认查18年至今最多10年数据
      if(this.everyStatus3 == 'year'){
        dealerRes.end=new Date().getFullYear()
        dealerRes.start=params.endTime - 10
        if(dealerRes.start < 2018) dealerRes.start = 2018
      }
      this.$api.post('/v1/dealerSaleDetailChart',dealerRes,r=>{
        if(r.errorCode == '0'){
          this.awardsData = r.data.content
          this.chart(this.awardsData, "经销商销售详情", "container3", false, true);
        }else{
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    // 销售金额数
    searchData2(){
      if(!this.dataEveryDay2){
        this.dataEveryDay2 = [this.dateFormate(new Date(new Date().getTime()-15*24*3600*1000)),this.dateFormate(new Date())]
        this.searchData(2)
        return
      }
      var query = {}
      query.start=this.startTime2 ? this.startTime2 : null
      query.end=this.endTime2 ? this.endTime2 : null
      query.type='saleVolume'
      query.mode=this.everyStatus2
      query.tag = this.details
      //特殊处理年，默认查18年至今最多10年数据
      if(this.everyStatus2 == 'year'){
        query.end=new Date().getFullYear()
        query.start=query.end - 10
        if(query.start < 2018) query.start = 2018
      }
      this.$api.post('/v1/orderSizeOrSaleVolumeChart',query,r=>{
        if(r.errorCode == '0'){
          this.orderMoneyData = r.data
          this.chart(this.orderMoneyData, "销售金额", "container2", false);
        }else{
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    // 销售订单数
    searchData1(){
      if(!this.dataEveryDay1){

        this.dataEveryDay1 = [this.dateFormate(new Date(new Date().getTime()-15*24*3600*1000)),this.dateFormate(new Date())]
        this.searchData(1)
        return
      }
      var query = {}
      query.start=this.startTime1 ? this.startTime1 : null
      query.end=this.endTime1 ? this.endTime1 : null
      query.type='orderSize'
      query.mode=this.everyStatus1
      query.tag = this.details
      //特殊处理年，默认查18年至今最多10年数据
      if(this.everyStatus1 == 'year'){
        query.end=new Date().getFullYear()
        query.start=query.end - 10
        if(query.start < 2018) query.start = 2018
      }
      this.$api.post('/v1/orderSizeOrSaleVolumeChart',query,r=>{
        if(r.errorCode == '0'){
          this.orderData = r.data
          this.chart(this.orderData, "销售订单数", "container1", false);
        }else{
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    searchData(num) {
      if(num == '1'){
        if(this.dataEveryDay1){
          var startTime = new Date(this.dataEveryDay1[0])
          if(this.everyStatus1 == 'day'){
            this.startTime1 = this.$format.format(startTime,'yyyy-MM-dd')
          }else{
            this.startTime1 = this.$format.format(startTime, 'yyyy-MM')
          }

          var endTime = new Date(this.dataEveryDay1[1])
          if(this.everyStatus1 == 'day'){
            this.endTime1 = this.$format.format(endTime,'yyyy-MM-dd')
          }else{
            this.endTime1 = this.$format.format(endTime, 'yyyy-MM')
          }
        }
        this.searchData1()
      }else if(num == '2'){
        if(this.dataEveryDay2){
          var startTime = new Date(this.dataEveryDay2[0])
          if(this.everyStatus2 == 'day'){
            this.startTime2 = this.$format.format(startTime,'yyyy-MM-dd')
          }else{
            this.startTime2 = this.$format.format(startTime, 'yyyy-MM')
          }

          var endTime = new Date(this.dataEveryDay2[1])
          if(this.everyStatus2 == 'day'){
            this.endTime2 = this.$format.format(endTime,'yyyy-MM-dd')
          }else{
            this.endTime2 = this.$format.format(endTime, 'yyyy-MM')
          }
        }
        this.searchData2()
      }else if(num == '3'){
        if(this.dataEveryDay3){
          var startTime = new Date(this.dataEveryDay3[0])
          if(this.everyStatus3 == 'day'){
            this.startTime3 = this.$format.format(startTime,'yyyy-MM-dd')
          }else{
            this.startTime3 = this.$format.format(startTime, 'yyyy-MM')
          }
          var endTime = new Date(this.dataEveryDay3[1])
          if(this.everyStatus3 == 'day'){
            this.endTime3 = this.$format.format(endTime,'yyyy-MM-dd')
          }else{
            this.endTime3 = this.$format.format(endTime, 'yyyy-MM')
          }
        }
        this.searchData3()
      }
    },
    turnDate(tag,everyStatus) {
      if(everyStatus === 'everyStatus1'){
        this.everyStatus1 = tag;
        this.dataEveryDay1 = "";
      }else if(everyStatus === 'everyStatus2'){
        this.everyStatus2 = tag;
        this.dataEveryDay2 = "";
      }else if(everyStatus === 'everyStatus3'){
        this.everyStatus3 = tag;
        this.dataEveryDay3 = "";
      }

    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    // 画图
    chart(data, title, canvas, titleShow, showAward) {
      let dateLine = [];
      let xData = [];
      let arr0 = [];
      let arr1 = [];
      let arr2 = []
      if(showAward){
        let selfAward = []
        let distributorAward = []
        let gotAward = []
        for(let item of data){
          arr0.push(item.ordersize)
          arr1.push(item.salevolume)
          arr2.push(item.bonusAmount)
          xData.push(item.name)
        }
      }else if(title == '销售订单数'){
        for (let i = 0; i < data.length; i++) {
          xData.push(data[i].row_date);
          arr0.push(data[i].count);
        }
      }else if(title == '销售金额'){
        for (let i = 0; i < data.length; i++) {
          xData.push(data[i].row_date);
          arr0.push(data[i].sum);
        }
      }
      var dom = document.getElementById(canvas);
      var myChart = echarts.init(dom);
      var option = null;
      option = {
        title: {
          text: title,
          show: titleShow,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["检测人数", "检测次数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
            },
          },
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: xData,
          "axisLabel": {
            "margin": 10,
            "interval": 0,
            "width": 90,
            "lineHeight": 16,
            "overflow": 'break',
          },
        },
        yAxis: {
          // name: isNew === true ? '次数' : '次数/人数',
          // type: 'value'
        },
        series: this.seriesData(arr0,arr1,arr2,title)
      };
      if (option && typeof option === "object") {
        myChart.setOption(option, true);
      }
    },
    seriesData(arr0,arr1,arr2,title){
      let arr = []
      if(title == '经销商销售详情'){
        arr = [
          {
            name: "销售订单数",
            type: "bar",
            data: arr0,
            itemStyle: {
              color: "rgba(255,144,70, 1)",
            },
            barMaxWidth: "20px",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          },
          {
            name: "销售总金额",
            type: "bar",
            data: arr1,
            itemStyle: {
              color: "rgba(70, 70, 70, 1)",
            },
            barMaxWidth: "20px",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          },
          {
            name: "获得奖金",
            type: "bar",
            data: arr2,
            itemStyle: {
              color: "rgba(43,185,168, 1)",
            },
            barMaxWidth: "20px",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          }
        ]
        return arr
      }
      if(arr0.length && arr1.length){
        arr = [
          {
            name: "销售订单数",
            type: "bar",
            data: arr0,
            itemStyle: {
              color: "rgba(255,144,70, 1)",
            },
            barMaxWidth: "20%",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          },
          {
            name: "销售总金额",
            type: "bar",
            data: arr1,
            itemStyle: {
              color: "rgba(70, 70, 70, 1)",
            },
            barMaxWidth: "20%",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          }
        ]
        return arr
      }else{
        arr = [
          {
            name: title,
            type: "bar",
            data: arr0,
            itemStyle: {
              color: "rgba(255,144,70, 1)",
            },
            barMaxWidth: "40px",
            label: {
              show: true,
              textStyle: { color: "#000000" },
              position: "top",
            },
          }
        ]
        return arr
      }
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      this.link("/login");
    },
  },
};
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  /* overflow: scroll; */
}
.main-content {
  padding: 20px;
  height: 100%;
  padding-bottom: 50px;
  overflow: auto;
}
.topCardWrapper {
  display: flex;
  justify-content: space-between;
}
.topCardWrapper .linkCard {
  width: 12%;
  background: rgba(247,148,148, 1);
  /* display: flex;
justify-content: space-between; */
  padding: 15px 12px 10px;
  /* align-items: flex-end; */
  color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
}
.topCardWrapper .linkCard:nth-child(2) {
  background: rgba(242,175,110, 1);
}
.topCardWrapper .linkCard:nth-child(3) {
  background: rgba(63,183,14, 1);
}
.topCardWrapper .linkCard:nth-child(4) {
  background: rgba(43,185,168, 1);
}

.topCardWrapper .linkCard:nth-child(5) {
  background: rgba(42,113,181, 1);
}
.topCardWrapper .linkCard:nth-child(6) {
  background: rgba(200,58,225, 1);
}
.topCardWrapper .num {
  font-family: "楷体 Bold", "楷体 Regular", "楷体", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  padding: 10px 0 10px;
}
.topCardWrapper .name {
  font-family: "Arial Normal", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
}

.everyDay {
  flex-direction: column;
}
.search {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
}
.search .checkMore{
  position: absolute;
  right: 60px;
  font-size: 14px;
  color: #FE9F36;
  cursor: pointer;
}
.search >>> .datePicker {
  width: 350px !important;
}
#container1 {
  height: 440px;
  padding: 0 !important;
}
#container2 {
  height: 440px;
  padding: 0 !important;
}
#container3 {
  height: 440px;
  padding: 0 !important;
}
.main-canvas {
  width: 100%;
}
.year-item {
  border: 1px solid #ccc;
  background: #fff;
  line-height: 38px;
  padding: 0 40px;
  font-size: 16px;
  color: #999;
  border-radius: 6px;
}
.searchBtn {
  /* border: 1px solid rgb(22, 155, 213); */
  /* border-left: none; */
  line-height: 36px;
  font-size: 14px;
  height: 36px;
  padding: 0px 10px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 6px;
  background: rgb(22, 155, 213);
  color: #ffffff;
}
.chooseBtn{
  font-size: 14px;
  border-radius: 6px;
  height: 36px;
}
.btnGroup {
  margin-left: 20px;
}
.btnGroup >>> .el-button--primary {
  background: rgb(22, 155, 213);
  border-color: rgb(22, 155, 213);
}
.tips {
  display: flex;
  margin-left: 20px;
  font-size: 14px;
}
.tips .tip {
  display: flex;
  align-items: center;
}
.tips .tip .colorTip {
  width: 20px;
  height: 20px;
  background: rgb(255, 144, 70);
  /* margin-right: 10px; */
  margin: 0 10px;
}
.tips .tip .colorTip.distributor {
  background: rgb(70, 70, 70);
}
.tips .tip .colorTip.gotAward {
  background: rgb(43,185,168);
}
.el-card:last-child{
  margin-bottom: 100px;
}
.searchText{
  font-size: 14px;
}
.btnGroup >>> .el-button--medium{
  padding: 10px 12px;
}
</style>