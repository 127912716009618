<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-form :inline="true">
          <el-form-item label="菜单名称">
            <el-input size="medium" v-model="name" placeholder="菜单名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="getMenuList()" v-has="'read'">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="createVisible = true;menu={}" v-has="'add'">创建</el-button>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          border
          style="width: 100%">
           <el-table-column
            header-align="center"
            align="center"
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="menuName"
            label="菜单名称">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="menuCode"
            label="标识码">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="jumpWay"
            label="跳转路径">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="jumpMethod"
            label="跳转方式">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="newPage"
            label="新页面">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="icon"
            label="icon">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="menuUsage"
            label="菜单功能">
            <template slot-scope="scope">
              <div v-for="menuUsage in scope.row.menuUsage" :key="menuUsage.code">{{menuUsage.name}}({{menuUsage.code}})</div>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="status"
            label="状态">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="button"
            label="操作">
            <template slot-scope="scope">
              <el-link
                @click="menu = scope.row;createVisible = true"
                type="primary" v-has="'update'">修改</el-link>
              <font v-has="'update && delete'">|</font>
              <el-link
                @click="remove(scope.row)"
                type="primary" v-has="'delete'">移除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog
      :title="menu.id?'修改菜单':'新建菜单'"
      :close-on-click-modal="false"
      :visible.sync="createVisible"
      destroy-on-close
      top="1vh"
      width="60%"
      center
      class="dialog">
      <createMenu @reload="reload" :menu="menu" :allMenus="tableData"></createMenu>
    </el-dialog>
  </el-main>
</template>

<script>
  import createMenu from './createMenu'
  export default {
    name: "menuManager",
    data() {
      return {
        menu:{},
        createVisible:false,
        tableLoading:false,
        name:'',
        tableData:[
        ]
      }
    },
    components: {createMenu},
    mounted() {
      this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
      this.getMenuList()
    },
    methods:{
      reload(){
        this.createVisible = false
        this.getMenuList()
      },
      remove(row){
        this.$confirm('确定要删除该菜单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableLoading = true
          let params = {}
          params.id = row.id
          this.$api.post('/v1/menu/delete',params,r=>{
            this.tableLoading = false
            if(r.errorCode === '0'){
              this.getMenuList()
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
            }else{
              this.$message.error(r.msg)
            }
          },err=>{
            this.$message({
              type: 'error',
              message: '操作失败!'
            });
            this.tableLoading = false
          })
        }).catch(() => {
        });
      },
      getMenuList(){
        this.tableLoading = true
        var params = {}
        if(this.name){
          params.menuName = this.name
        }
        this.$api.post('/v1/menu/list',params,r=>{
          this.tableLoading = false
          if(r.errorCode === '0'){
            for(var item of r.data){
              item.id = item.idStr
              item.pid = item.pidStr
              item.menuUsage = item.menuUsage.map(v=>{
                v = JSON.parse(v)
                return v
              })
            }
            this.tableData = r.data
          }else{
            this.$message.error(r.msg)
          }
        },err=>{
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  padding-bottom: 60px;
}
font{
  color: #FE9F36;
  margin: 0 8px;
}
</style>