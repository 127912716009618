<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">经销商名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入经销商名称查询"
                  size="medium"
                  v-model="formInline.dealerName"
                >
                </el-input>
              </div>
            </div>
            <div class="input-group">
              <div class="input-title">经销商等级：</div>
              <div class="input-content">
                <el-select placeholder="请选择经销商等级" size="medium" v-model="formInline.role">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="input-group input-time">
              <div class="input-title">时间：</div>
              <div class="input-content">
                <el-date-picker
                  v-model="formInline.timeRange"
                  size="medium"
                  type="daterange"
                  :picker-options="pickerBeginDateBefore"
                  style="width: 240px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
            >查询</el-button
          >
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          max-height="700"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="经销商名称" min-width="180">
          </el-table-column>
          <el-table-column prop="role" label="经销商等级" min-width="120">
            <template slot-scope="scope">
              {{scope.row.role == 'primaryDealer' ? '一级经销商' : (scope.row.role == 'secondaryDealer' ? '二级经销商' : scope.row.role)}}
            </template>
          </el-table-column>
          <el-table-column prop="ordersize" label="销售订单数" min-width="180">
          </el-table-column>
          <el-table-column prop="salevolume" label="销售金额（元）" min-width="180">
            <template slot-scope="scope">
              {{scope.row.salevolume || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="bonusAmount" label="获取奖金数（元）" min-width="180">
            <template slot-scope="scope">
              {{scope.row.bonusAmount || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="saleMan" label="业务员" min-width="120">
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
      tag: this.$route.query.tag,
      tableData: [],
      loading: true,
      total: 1,
      nowPage: 1,
      pageSize: 10,
      options1: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "primaryDealer",
          label: "一级经销商",
        },
        {
          value: "secondaryDealer",
          label: "二级经销商",
        },
      ],
      formInline: {
        dealer: "",
        orderTime: '',
        dealerLevel: "",
      },
      submitData: {
        dealer: "",
        orderTime: '',
        dealerLevel: "",
      },
    };
  },
  mounted() {
    this.search(1);
  },
  methods: {

    handleSizeChange(e){

    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var params = {};
      if (
        this.submitData.timeRange !== null &&
        this.submitData.timeRange !== "" &&
        this.submitData.timeRange !== undefined &&
        this.submitData.timeRange.length !== 1
      ) {
        var start = this.$format.format(this.submitData.timeRange[0],'yyyy-MM-dd');
        var end = this.$format.format(this.submitData.timeRange[1],'yyyy-MM-dd');
        params.start = start;
        params.end = end
      }
      if (this.submitData.dealerName) {
        params.name = this.submitData.dealerName;
      }
      if (this.submitData.role) {
        params.role = this.submitData.role;
      }
      params.tag = this.tag
      params.size = this.pageSize;
      params.page = parseInt(e) - 1;
      this.$api.post('/v1/dealerSaleDetailChart',params,r=>{
        if(r.errorCode == '0'){
          this.tableData = r.data.content
          this.loading = false
          this.total = r.data.totalElements
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')

    },
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  /* width: 425px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 150px); */
  /* overflow-y: scroll; */
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  /* width: 120px; */
  margin: 10px 0 10px 50px;
  height: fit-content;
}
.block{
  text-align: right;
}
.box-card:last-child{
  margin-bottom: 50px;
}
</style>