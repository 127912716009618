<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">商品名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入商品名称查询"
                  size="medium"
                  v-model="formInline.productName"
                >
                </el-input>
              </div>
            </div>
            <!-- <div class="input-group">
              <div class="input-title">商品类型：</div>
              <div class="input-content">
                <el-select placeholder="请选择商品类型查询" size="medium" v-model="formInline.tag">
                  <el-option
                    v-for="(key,value) in options"
                    :key="value"
                    :label="key"
                    :value="value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div> -->
          </div>

          <div class="flex-right">
            <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
              >查询</el-button
            >
            <el-button  class="search-button" size="medium" @click="addProduct"
              >新增</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="650"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="商品名称" min-width="180">
          </el-table-column>
          <el-table-column prop="tag" label="商品类型" min-width="180">
            <template slot-scope="scope">
              {{scope.row.tag == 'care' ? '心脏照护': (scope.row.tag == 'thirdProduct' ? '淘宝/京东': '其他')}}
            </template>
          </el-table-column>
          <el-table-column prop="tbSkuId" label="淘宝商品ID" min-width="180">
            <template slot-scope="scope">
              {{scope.row.tbSkuId || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="jdSkuId" label="京东商品ID" min-width="180">
            <template slot-scope="scope">
              {{scope.row.jdSkuId || '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="商品价格（元）" min-width="160">
          </el-table-column>
          <el-table-column label="操作" min-width="200" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" @click="editInfo(scope.row.idStr)">修改</el-button>
                <div class="line"></div>
                <el-button class="textBtn" type="text" @click="remove(scope.row.idStr)">移除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
        :visible.sync="removeProductVisible"
        top="200px"
        title="提示"
        width="20%"
        >
        <span>您确定移除该分销商品吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="removeProductVisible = false">取 消</el-button>
          <el-button type="primary" @click="removeProducts">确 定</el-button>
        </span>
    </el-dialog>
  </el-main>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      removeProductVisible: false,//移除分销商品
      tableData: [],
      loading: true,
      total: 1,
      nowPage: 1,
      pageSize: 10,
      formInline: {
        productName: "",
      },
      submitData: {
        productName: "",
      },
      removeId: '',
      options: []
    };
  },
  components: {vueQr},
  mounted() {
    // this.getTage()
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    //获取商品分类
    getTage() {
      this.$api.get(
        "/v1/personalDisplay/tag",
        {},
        (r) => {
          if (r.errorCode == "0" && r.data) {
            let data = r.data;
            this.options = data;
            // this.search(1);
          }
        },
        (f) => {
          if (f === 403) {
            this.logout();
          }
        }
      );
    },
    // 新增分销商品
    addProduct(){
      this.link('/addProduct')
    },
    // 确定移除
    removeProducts(){
      this.$api.post('/v1/product/delete',{id: this.removeId},r=>{
        if(r.errorCode === '0'){
          this.$message.success('商品已移除')
          this.removeProductVisible = false
          this.search(1)
        }else{
          this.$message.success(r.msg)
          this.search(1)
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    // 移除商品
    remove(id){
      this.removeProductVisible = true
      this.removeId = id
    },
    // 修改信息
    editInfo(id,role){
      this.link('/addProduct',{'id': id})
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var dealerListRes = {};
      if (this.submitData.productName) {
        dealerListRes.productName = this.submitData.productName;
      }
      // if (this.submitData.tag) {
      //   dealerListRes.tag = this.submitData.tag;
      // }
      dealerListRes.size = this.pageSize;
      dealerListRes.page = parseInt(e) - 1;
      // this.tableData = [
      //   {
      //     id: '92374983257y984325',
      //     name: '心脏照护服务升级版',
      //     tbSkuId: '546464',
      //     jdSkuId: '878788',
      //     price: '600',
      //   },
      //   {
      //     id: '92374983257y984325',
      //     name: '心脏照护服务升级版',
      //     tbSkuId: '546464',
      //     jdSkuId: '878788',
      //     price: '600',
      //   },
      //   {
      //     id: '92374983257y984325',
      //     name: '心脏照护服务升级版',
      //     tbSkuId: '546464',
      //     jdSkuId: '878788',
      //     price: '600',
      //   },
      //   {
      //     id: '92374983257y984325',
      //     name: '心脏照护服务升级版',
      //     tbSkuId: '546464',
      //     jdSkuId: '878788',
      //     price: '600',
      //   }
      // ]
      // this.loading=false
      this.$api.post('/v1/product/list',dealerListRes,r=>{
        if(r.errorCode == '0'){
          this.tableData = r.data.content
          this.total = r.data.totalElements
          this.loading=false
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 10px 20px 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  width: 425px;
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.tableStyle >>> tr{
  height: 64px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px 0;
  height: fit-content;
}
.block{
  text-align: right;
  padding-bottom: 10px;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
/* 开关 */
.switchStyle{
  height: 28px;
}
.switchStyle >>> .el-switch__label *{
  font-size: 12px;
}
.switchStyle >>> .el-switch__core{
  height: 28px;
  border-radius: 26px;
}
.switchStyle >>> .el-switch__core:after{
  width: 22px;
  height: 22px;
  top: 2px;
  left: 2px;
}
.switchStyle >>> .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
}
.switchStyle >>> .el-switch__label--left {
  z-index: 9;
  left: 32px;
  color: #606266;
  margin-right: 3px;
}
.switchStyle >>> .el-switch__label--right {
  z-index: 9;
  left: 0px;
}
.switchStyle >>> .el-switch__label.is-active {
  display: block;
}
.switchStyle.el-switch >>> .el-switch__core,
.el-switch .el-switch__label {
  width: 66px !important;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.dialog >>> .el-dialog__body{
  display: flex;
  justify-content: center;
  padding: 40px;
}
.dialog >>> .el-dialog__header{
  padding: 0 !important;
}
.codeImg{
  box-shadow: 0px 16px 38px 13px rgba(107, 107, 107, 0.09);
}
.codeText{
  font-size: 18px;
  line-height: 2;
  margin-top: 30px;
}
</style>