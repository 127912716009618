<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group" v-if="!isMini">
              <div class="input-title">商品名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入商品名称查询"
                  size="medium"
                  v-model="formInline.productName"
                >
                </el-input>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <el-button
              v-if="!isMini"
              class="search-button"
              size="medium"
              type="primary"
              @click="submit(1)"
              >查询</el-button
            >
            <el-button
              v-if="isMini"
              class="search-button"
              size="medium"
              type="primary"
              @click="add"
              >新增</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="650"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column prop="displayname" label="商品名称" min-width="180">
          </el-table-column>
          <el-table-column prop="price" label="商品价格（元）" min-width="120">
          </el-table-column>
          <el-table-column
            prop="tblink"
            v-if="!isMini"
            label="淘宝分销链接"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.tblink || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="jdlink"
            v-if="!isMini"
            label="京东分销链接"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.jdlink || "--" }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="tbSkuId"
            v-if="isMini"
            label="淘宝ID"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.tbSkuId || "--" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="jdSkuId"
            v-if="isMini"
            label="京东ID"
            min-width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.jdSkuId || "--" }}
            </template>
          </el-table-column> -->
          <el-table-column label="操作" min-width="130" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button
                  class="textBtn"
                  v-if="!isMini"
                  type="text"
                  @click="editLink(scope.row.productid)"
                  >修改分销链接</el-button
                >
                <el-button
                  class="textBtn"
                  v-else
                  type="text"
                  @click="removeGoods(scope.row.productId)"
                  >移除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
      :show-close="false"
      :close-on-click-modal="true"
      class="dialog"
      :visible.sync="showCodeVisible"
      top="200px"
      width="500px"
    >
      <vue-qr class="codeImg" :text="dealerCode" :size="400"></vue-qr>
      <!-- <div class="codeText">{{dealerCode}}</div> -->
    </el-dialog>
    <el-dialog :visible.sync="isShow" width="1200px">
      <el-table
        ref="multipleTable"
        :data="tableDataAdd"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60"> </el-table-column>
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="商品名称" min-width="150">
          <template slot-scope="scope">{{ scope.row.displayName }}</template>
        </el-table-column>
        <!-- <el-table-column v-if="!isMini" prop="tbSkuId" label="淘宝商品ID" min-width="80">
          <template slot-scope="scope">{{
            scope.row.tbSkuId || "--"
          }}</template>
        </el-table-column>
        <el-table-column v-if="!isMini" prop="jdSkuId" label="京东商品ID" min-width="80">
          <template slot-scope="scope">{{
            scope.row.jdSkuId || "--"
          }}</template>
        </el-table-column> -->
        <el-table-column prop="price" label="商品价格（元）" min-width="80">
        </el-table-column>
      </el-table>
      <div class="flex submit">
        <el-button
          class="search-button"
          size="medium"
          type="primary"
          @click="submitAdd()"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import { log } from "util";
import vueQr from "vue-qr";
export default {
  data() {
    return {
      isShow: false, //点击新增显示列表
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableDataAdd: [],
      totalAdd: 0,
      nowPageAdd: 1,
      pageSizeAdd: 10,
      settlementTime: [],
      tableData: [],
      loading: true,
      total: 0,
      nowPage: 1,
      pageSize: 10,
      selectedProduct: [], //选择的商品
      formInline: {
        productName: "",
      },
      submitData: {
        productName: "",
      },
      dealerCode: "",
      showCodeVisible: false,
      dealerId: this.$route.query.id || "",
      isMini: this.$route.query.isMini == "true" ? true : false,
      dealerName: this.$route.query.dealerName || "",
    };
  },
  components: { vueQr },
  mounted() {
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 移除小程序分销商品
    removeGoods(id) {
      this.$confirm("确定要移除该商品吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            userId: this.dealerId || "",
            type: "delete",
            productIds: id,
          };
          this.$api.post("/v1/personalDisplay/update", params, (r) => {
            if (r.errorCode == "0") {
              this.$message.success("操作成功！");
              this.search(1);
            }
          });
        })
        .catch(() => {
          this.$message.error("取消操作");
        });
    },
    handleSelectionChange(e) {
      this.selectedProduct = e;
      // console.log(e);
    },
    // 新增
    add() {
      this.personalDisplay();
      this.selectedProduct = [];
      this.isShow = true;
    },
    submitAdd() {
      // console.log(this.selectedProduct);
      if (this.selectedProduct.length > 0) {
        let params = {
          userId: this.dealerId || "",
          type: "add",
          productIds: [],
        };
        this.selectedProduct.map((v) => {
          params.productIds.push(v.productId);
        });
        params.productIds = params.productIds.join(",");
        this.$api.post("/v1/personalDisplay/update", params, (r) => {
          if (r.errorCode == "0") {
            this.$message.success("操作成功！");
            this.search(1);
          }
        });
      }
      this.isShow = false;
    },
    // 修改分销链接
    editLink(id) {
      this.link("/editDealerProducts", {
        productId: id,
        dealerId: this.dealerId,
        dealerName: this.dealerName,
      });
    },
    handleSizeChangeAdd(e) {
      this.pageSizeAdd = e;
      this.nowPageAdd = 0;
      // this.search(1)
    },
    pageChageAdd(e) {
      // this.search(e);
      this.nowPageAdd = parseInt(e);
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.nowPage = 0;
      this.search(1);
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading = true;
      var params = {};
      if (this.submitData.productName) {
        params.productName = this.submitData.productName;
      }
      if (this.dealerId !== "") {
        params.dealerUserId = this.dealerId;
        params.userId = this.dealerId;
      }
      params.size = this.pageSize;
      params.page = parseInt(e) - 1;
      let contentType = "json";
      let url = "/v1/product/manager/list";
      if (this.isMini) {
        url = "/v1/personalDisplay/list";
        contentType = "";
        params.type = "display";
      }
      this.$api.post(
        url,
        params,
        (r) => {
          if (r.errorCode == "0") {
            this.tableData = r.data.content.map((v) => {
              if (v.displayName) v.displayname = v.displayName;
              return v;
            });
            this.total = r.data.totalElements;
            this.loading = false;
          } else {
            this.loading = false;
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0]);
            } else {
              this.$message.error(r.msg);
            }
          }
        },
        (f) => {
          this.loading = false;
          if (f === 403) {
            this.logout();
          }
        },
        contentType
      );
    },
    personalDisplay() {
      var params = {};
      params.size = 100;
      params.page = 0;
      params.userId = this.dealerId;
      params.type = "notDisplay";
      this.$api.post(
        "/v1/personalDisplay/list",
        params,
        (r) => {
          if (r.errorCode == "0") {
            this.tableDataAdd = r.data.content;
            // this.total = r.data.totalElements;
            this.loading = false;
          } else {
            this.loading = false;
            if (r.details && r.details[0]) {
              this.$message.error(r.details[0]);
            } else {
              this.$message.error(r.msg);
            }
          }
        },
        (f) => {
          this.loading = false;
          if (f === 403) {
            this.logout();
          }
        }
      );
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
  },
};
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex {
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex.submit{
  justify-content: flex-end;
}
.flex-left {
  display: flex;
  flex-wrap: wrap;
}
.flex-right {
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  /* width: 425px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box {
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.tableStyle >>> tr {
  height: 64px;
}
.el-table {
  border-top: 1px solid #ebeef5;
  border-bottom: none;
}
.el-table::before {
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px;
  height: fit-content;
}
.block {
  text-align: right;
}
.operateBtn {
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn {
  padding: 0;
}
.operateBtn .line {
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color: #fe9f36;
}
.dialog >>> .el-dialog__body {
  display: flex;
  justify-content: center;
  padding: 40px;
}
.dialog >>> .el-dialog__header {
  padding: 0 !important;
}
.codeImg {
  box-shadow: 0px 16px 38px 13px rgba(107, 107, 107, 0.09);
}
</style>