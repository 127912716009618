<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">经销商名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入经销商名称查询"
                  size="medium"
                  v-model="formInline.dealerName"
                >
                </el-input>
              </div>
            </div>
            <div class="input-group">
              <div class="input-title" slot-scope="">经销商电话：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入经销商电话查询"
                  size="medium"
                  v-model="formInline.phone"
                >
                </el-input>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
              >查询</el-button
            >
            <el-button  class="search-button" size="medium" @click="addDistributor" v-has="'add'"
              >新增分销商账号</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="650"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="dealerName" label="经销商名称" min-width="150">
          </el-table-column>
          <el-table-column prop="role" label="经销商等级" min-width="120">
            <template slot-scope="scope">
              {{scope.row.role == 'primaryDealer' ? '一级经销商' : (scope.row.role == 'secondaryDealer' ? '二级经销商' : scope.row.role)}}
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="经销商电话" min-width="140">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="160">
          </el-table-column>
          <el-table-column prop="status" label="账号状态" min-width="140">
            <template slot-scope="scope">
              <el-switch
                class="switchStyle"
                v-has="'updateStatus'"
                @change="changeStatus(scope.row.id,scope.row.status)"
                :value="scope.row.status"
                active-color="rgb(22,175,240)"
                active-value="normal"
                active-text="启用"
                inactive-color="rgb(219,219,219)"
                inactive-value="forbidden"
                inactive-text="禁用">
              </el-switch>
              <el-switch
                class="switchStyle"
                :disabled="true"
                v-notHas="'updateStatus'"
                v-model="scope.row.status"
                active-color="rgb(22,175,240)"
                active-value="normal"
                active-text="启用"
                inactive-color="rgb(219,219,219)"
                inactive-value="forbidden"
                inactive-text="禁用">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="saleName" label="业务员" min-width="130">
            <template slot-scope="scope">{{scope.row.saleName || '--'}}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="240" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" @click="editInfo(scope.row.id,scope.row.role)" v-has="'update'">修改</el-button>
                <div class="line" v-has="'update'"></div>
                <el-button class="textBtn" type="text" @click="showCode(scope.row.code)">身份码</el-button>
                <div v-if="scope.row.status == 'forbidden' && scope.row.role == 'primaryDealer'" v-has="'delete'" class="line"></div>
                <el-button v-if="scope.row.status == 'forbidden' && scope.row.role == 'primaryDealer'" v-has="'delete'" class="textBtn" type="text" @click="delDealer(scope.row.id)">移除二级经销商</el-button>
                <div class="line"></div>
                <el-button class="textBtn" type="text" @click="dealerProducts(scope.row.id,scope.row.dealerName)">淘宝/京东商品</el-button>
                <div class="line"></div>
                <el-button class="textBtn" type="text" @click="dealerProductsMini(scope.row.id,scope.row.dealerName)">小程序商品</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog :show-close="false" :close-on-click-modal="true" class="dialog" :visible.sync="showCodeVisible" top="200px" width="500px">
      <vue-qr class="codeImg" :text="dealerCode" :size="400"></vue-qr>
      <!-- <div class="codeText">{{dealerCode}}</div> -->
    </el-dialog>
    <el-dialog
        :visible.sync="removeDealerVisible"
        top="200px"
        title="提示"
        width="30%"
        >
        <span>您确定将此账号下的二级经销商转移至超级管理员吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="removeDealerVisible = false">取 消</el-button>
          <el-button type="primary" @click="removeDealer">确 定</el-button>
        </span>
    </el-dialog>
  </el-main>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  data() {
    return {
      dealerCode: '',
      removeDealerVisible: false,//移除二级经销商对话框
      showCodeVisible: false,//显示身份码
      tableData: [],
      loading: true,
      total: 1,
      nowPage: 1,
      pageSize: 10,
      formInline: {
        dealer: "",
        dealerPhone: ''
      },
      submitData: {
        dealer: "",
        dealerPhone: ''
      },
    };
  },
  components: {vueQr},
  mounted() {
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    dealerProductsMini(id,dealerName){
      this.link('/distributionGoods',{id: id,dealerName: dealerName,isMini: true})
    },
    // 查看经销商分销商品
    dealerProducts(id,dealerName){
      this.link('/distributionGoods',{id: id,dealerName: dealerName})
    },
    // 移除二级经销商
    delDealer(id){
      this.removeDealerVisible = true
      this.removeId = id
    },
    // 确认移除
    removeDealer(){
      let params = {
        userId: this.removeId
      }
      this.$api.post('/v1/remove',params,r=>{
        if(r.errorCode == '0'){
          this.$message.success('该账号下分销商已转移至超级管理员')
          this.removeId = ''
          this.removeDealerVisible = false
        }else{
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      })
    },
    // 修改使用状态
    changeStatus(id,status){
      this.$confirm('确认修改该账号使用状态吗', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if(status == 'forbidden'){
            status = 'normal'
          }else{
            status = 'forbidden'
          }
          let params = {
            userId: id,
            status: status
          }
          this.$api.post('/v1/user/manage/user',params,r=>{
            if(r.errorCode == '0'){
              if(params.status == 'forbidden'){
                this.$message.success('账号禁用成功')
              }else{
                this.$message.success('账号启用成功')
              }
              this.search()
            }
          })
        }).catch(() => {
          this.$message.error('取消操作')
        });

    },
    // 修改信息
    editInfo(id,role){
      this.link('/applyDistributor',{'id': id, disabled: false, level: role, from: 'distributorManager'})
    },
    // 显示身份码
    showCode(code){
      this.dealerCode = code
      this.showCodeVisible = true
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    // 申请二级分销商
    addDistributor(){
      this.link('/applyDistributor',{disabled: false,from: 'distributorManager'})
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var dealerListRes = {};
      if (this.submitData.dealerName) {
        dealerListRes.dealerName = this.submitData.dealerName;
      }
      if (this.submitData.phone) {
        dealerListRes.phone = this.submitData.phone;
      }
      dealerListRes.size = this.pageSize;
      dealerListRes.page = parseInt(e) - 1;
      this.$api.post('/v1/Dealer/list',dealerListRes,r=>{
        if(r.errorCode == '0'){
          r.data.content.map(v=>{
            if (v.createTime) {
              v.createTime = this.$format.format(v.createTime, 'yyyy-MM-dd hh:mm:ss')
            }
            return v
          })
          this.tableData = r.data.content
          this.total = r.data.totalElements
          this.loading=false
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    }
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 10px 20px 20px;
  height: 100%;
  overflow: auto;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  width: 425px;
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.tableStyle >>> tr{
  height: 64px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px 0;
  height: fit-content;
}
.block{
  text-align: right;
  padding-bottom: 10px;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
/* 开关 */
.switchStyle{
  height: 28px;
}
.switchStyle >>> .el-switch__label *{
  font-size: 12px;
}
.switchStyle >>> .el-switch__core{
  height: 28px;
  border-radius: 26px;
}
.switchStyle >>> .el-switch__core:after{
  width: 22px;
  height: 22px;
  top: 2px;
  left: 2px;
}
.switchStyle >>> .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
}
.switchStyle >>> .el-switch__label--left {
  z-index: 9;
  left: 32px;
  color: #606266;
  margin-right: 3px;
}
.switchStyle >>> .el-switch__label--right {
  z-index: 9;
  left: 0px;
}
.switchStyle >>> .el-switch__label.is-active {
  display: block;
}
.switchStyle.el-switch >>> .el-switch__core,
.el-switch .el-switch__label {
  width: 66px !important;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.dialog >>> .el-dialog__body{
  display: flex;
  justify-content: center;
  padding: 40px;
}
.dialog >>> .el-dialog__header{
  padding: 0 !important;
}
.codeImg{
  box-shadow: 0px 16px 38px 13px rgba(107, 107, 107, 0.09);
}
.codeText{
  font-size: 18px;
  line-height: 2;
  margin-top: 30px;
}
</style>