<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-page-header @back="goBack" content="录入订单">
        </el-page-header>
        <el-form class="form" label-position="left" ref="formInline" :hide-required-asterisk='true' :rules="rules" label-width="100px" :model="formInline">
          <el-form-item label="订单号：" prop="id">
            <el-input v-model="formInline.id" size="medium" maxlength="30" placeholder="不超过30个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品名称：" prop="productName">
            <el-input v-model="formInline.productName" size="medium" maxlength="50" placeholder="不超过50个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品价格：" prop="productPrice">
            <el-input v-model="formInline.productPrice" size="medium" maxlength="6" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <el-form-item label="商品数量：" prop="productSize">
            <el-input v-model="formInline.productSize" maxlength="6" size="medium" placeholder="不超过6个字符"></el-input>
          </el-form-item>
          <!-- <el-form-item label="购买人姓名：" prop="buyerName">
            <el-input v-model="formInline.buyerName" size="medium" maxlength="10" placeholder="不超过10个字符"></el-input>
          </el-form-item>
          <el-form-item label="购买人电话：" prop="buyerPhone">
            <el-input v-model="formInline.buyerPhone" maxlength="11" size="medium" placeholder="等于11个字符"></el-input>
          </el-form-item> -->
          <el-form-item label="购买时间：" prop="buyerTime">
            <!-- <el-input v-model="formInline.buyerTime" size="medium" placeholder="年/月/日"></el-input> -->
            <el-date-picker
              class="datePicker"
              :picker-options="pickerBeginDateBefore"
              v-model="formInline.buyerTime"
              type="datetime"
              size="medium"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button :loading="submitLoading" class="search-button" type="primary" size="medium" @click="submitForm('formInline')">提交订单</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </el-main>
</template>

<script>
  const validPhone = (rule, value, callback) => {
    const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
    if (!value) {
      callback(new Error('请输入购买人手机号'))
    } else if (!reg.test(value)) {
      callback(new Error('请输入正确手机号码'))
    } else {
      callback()
    }
  }
  // 验证只能输入数字
  const limitInputNumber = (rule,val,callback)=>{
    console.log(val);
    const reg = /^[1-9]\d*$/
    if(!val){
      callback(new Error('输入为空！'))
    }else if(!reg.test(val)){
      callback(new Error('请输入数字'))
    }else{
      callback()
    }
  }
  // 限制只能输入数字(可以输入两位小数)
  const limitInputPointNumber = (rule,val,callback) =>{
    const reg = /^[0-9]+([.]{1}[0-9]{1,2})?$/
    if(!val){
      callback(new Error('输入为空！'))
    }else if(!reg.test(val)){
      callback(new Error('请输入正确格式'))
    }else{
      callback()
    }
  }
  export default {
    data(){
      return {
        submitLoading: false,//表单是否正在提交中
        pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        rules: {
          id: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
            { min: 0, max: 30, message: '不超过30个字符', trigger: 'blur' }
          ],
          productName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
            { min: 0, max: 50, message: '不超过50个字符', trigger: 'blur' }
          ],
          productPrice: [
            { required: true, message: '请输入商品价格', trigger: 'blur' },
            { validator: limitInputPointNumber, trigger: 'blur' }
          ],
          productSize: [
            { required: true, message: '请输入商品数量', trigger: 'blur' },
            { validator: limitInputNumber, trigger: 'blur' },
          ],
          // buyerName: [
          //   { required: true, message: '请输入购买人姓名', trigger: 'blur' },
          //   { min: 0, max: 10, message: '不超过10个字符', trigger: 'blur' }
          // ],
          // buyerPhone: [
          //   { validator: limitInputNumber, message: '请输入11位数字', trigger: 'blur' },
          //   {required: true, trigger: 'blur', validator: validPhone}
          // ],
          buyerTime: [
            { type: 'date', required: true, message: '请选择购买时间', trigger: 'change' }
          ],
        },
        formInline: {
          id: '',
          productName: '',//商品名称
          productPrice: null,//商品价格
          productSize: null, //商品数量
          // buyerName: '', //购买人姓名
          // buyerPhone: '', //购买人电话
          buyerTime: '',//购买时间
        }
      }
    },
    methods: {
      link(path, query) {
        this.$router.push({ path: path, query: query });
      },
      goBack(){
        this.link('/orderList',{'activeName': 'selfUpload'})
      },
      submitForm(form){
        this.submitLoading = true
        this.$refs[form].validate((valid) => {
          if (valid) {
            let vo = this.$refs[form].model
            vo.productSize = parseInt(vo.productSize)
            vo.productPrice = parseFloat(vo.productPrice)
            console.log(vo);
            this.$api.post('/v1/order/upload',vo,r=>{
              if(r.errorCode == '0'){
                this.submitLoading = false
                this.$message.success("订单已成功提交")
                this.goBack()
              }else{
                this.submitLoading = false
                if (r.details && r.details[0]) {
                  this.$message.error(r.details[0])
                } else {
                  this.$message.error(r.msg)
                }
              }
            },f=>{
                this.submitLoading = false
              },'json')
          } else {
            this.submitLoading = false
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.main-content {
  padding: 20px;
  height: 100%;
  overflow: auto;
}
.box-card{
  height: 100%;
}
.box-card >>> .el-page-header{
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}
.el-page-header >>> .el-page-header__content{
  color: #ee6600;
  font-weight: bold;
}
.form{
  margin-top: 20px;
  width: 30%;
}
.search-button{
  padding: 10px 55px;
}
.datePicker{
  width: 100% !important;
}
</style>