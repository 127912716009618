var format = function (date, fmt) {
  var timeObj = new Date(date)
  var o = {
    'M+': timeObj.getMonth() + 1, // 月份
    'd+': timeObj.getDate(), // 日
    'h+': timeObj.getHours(), // 小时
    'm+': timeObj.getMinutes(), // 分
    's+': timeObj.getSeconds(), // 秒
    'q+': Math.floor((timeObj.getMonth() + 3) / 3), // 季度
    'w+': getWeekDate(timeObj),//星期
    'S': timeObj.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (timeObj.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
var getWeekDate = function(now) {
  var day = now.getDay();
  var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
  var week = weeks[day];
  return week;
}
// 返回在vue模板中的调用接口
export default {
  format: function (date, fmt) {
    return format(date, fmt)
  }
}
