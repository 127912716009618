<template>
  <el-main>
    <!-- <MainTitle name="硬件数据"></MainTitle> -->
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">订单号：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入订单号查询"
                  size="medium"
                  v-model="formInline.id"
                >
                </el-input>
              </div>
            </div>
            <div class="input-group input-time">
              <div class="input-title">下单时间：</div>
              <div class="input-content">
                <el-date-picker
                  v-model="formInline.buyTime"
                  size="medium"
                  :picker-options="pickerBeginDateBefore"
                  type="daterange"
                  style="width: 240px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="flex-right">
            <el-button size="medium" class="search-button" type="primary" @click="submit(1)"
              >查询</el-button
            >
            <el-button  size="medium" class="search-button" @click="uploadOrder"
              >上传订单</el-button
            >
            <el-button  size="medium" class="search-button" @click="batchUpload"
              >批量上传</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="620"
          class="tableStyle"
          :data="tableData"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="id" label="订单号" min-width="150"> </el-table-column>
          <el-table-column prop="productname" label="商品名称" min-width="150"> </el-table-column>
          <el-table-column prop="size" label="商品数量" min-width="80"> </el-table-column>
          <el-table-column prop="price" label="商品价格（元）" min-width="110"> </el-table-column>
          <!-- <el-table-column prop="buyername" label="收件人姓名" min-width="100"> </el-table-column>
          <el-table-column prop="buyerphone" label="收件人手机号" min-width="120"> </el-table-column> -->
          <el-table-column prop="buytime" label="下单时间" min-width="160"> </el-table-column>
          <el-table-column prop="totalfee" label="订单总价" min-width="100"> </el-table-column>
          <el-table-column prop="username" label="所属经销商" min-width="160"> </el-table-column>
          <el-table-column prop="source" label="订单来源" min-width="80"> </el-table-column>
          <el-table-column prop="userpname" label="上传人员"  min-width="100"> </el-table-column>
          <el-table-column prop="uploadtime" label="上传时间" min-width="160"> </el-table-column>
          <el-table-column label="操作" min-width="100" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" v-if="scope.row.status === 'unsettled'" @click="remove(scope.row.id)">移除</el-button>
                <div class="line"></div>
                <el-button class="textBtn" type="text" @click="checkOrder(scope.row.id)">查看</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
        :visible.sync="dialogVisible1"
        top="200px"
        class="uploadFile"
        width="35%"
        title="批量上传"
        center
        @closed="closed"
        :close-on-click-modal="false"
        >
        <div class="tips" v-for="item in tipsArr">
          <div class="tipsItem">{{item}}</div>
        </div>
        <div class="orderSource">
          <div class="left">订单渠道：</div>
          <el-select class="right" v-model="orderSource" placeholder="请选择">
            <el-option
              v-for="item in sources"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-upload
          class="upload-file"
          ref="upload"
          :headers="headers"
          :action="action()"
          accept=".xls,.xlsx,.csv"
          :limit="1"
          list-type="text"
          name="multipartFile"
          :file-list="fileList"
          :show-file-list="false"
          :on-success="handleSuccess"
          :auto-upload="true">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        <span slot="footer">
          <el-button class="downloadDemo" @click="downloadDemo" type="text">下载上传模板</el-button>
        </span>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible"
        top="200px"
        class="dialog"
        title="提示"
        width="30%"
        @closed="cancel"
        :close-on-click-modal="false"
        >
        <span>确认移除该订单吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="confirmRemove">确 定</el-button>
        </span>
    </el-dialog>
  </el-main>
</template>

<script>
// import MainTitle from '@/pages/layout/MainTitle'
export default {
  name: "orderUploadList",
  data() {
    return {
      orderSource: '淘宝',//订单渠道
      fileList: [],
      headers: {
        'client-name':'teleconsultation',
        'X-Auth-Token': this.$store.state.token
      },
      sources: [
        {
          value: "淘宝",
          label: "淘宝",
        },
        {
          value: "京东",
          label: "京东",
        },
      ],
      tipsArr: ['1、上传前需选择上传订单为淘宝还是京东；','2、上传文件格式仅支持xls、xlsx；','3、上传文件大小不得超过10M。'],
      dialogVisible: false,
      dialogVisible1: false,
      removeId: '',//要移除的订单id
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      id: this.$route.query.id, //从用户列表分析历史跳转时带过来的参数
      openId: this.$route.query.openId, //从客服消息分析历史跳转时带过来的参数
      recordId: "",
      loading: false,
      formInline: {
        dealer: "",
      },
      submitData: {
        dealer: "",
      },
      total: 1,
      nowPage: 1,
      pageSize: 10,
      tableData: [],
      loading: true,
      orderFile: null
    };
  },
  watch: {},
  components: {},
  mounted() {
    if (this.id) {
      this.formInline.userId = this.id;
      this.submitData.userId = this.id;
    }
    if (this.openId) {
      this.formInline.openId = this.openId;
      this.submitData.openId = this.openId;
    }
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    action(){
       return '/dealer/v1/excelUpload?type='+ this.orderSource
     },
    handleSuccess(res,file,fileList){
      console.log(res,file,fileList);
      if(res.errorCode == '0'){
        this.$message.success('上传成功')
        this.$nextTick(()=>{
          this.dialogVisible1 = false
          this.$refs.upload.clearFiles()
          this.orderSource = '淘宝'
          this.search(1)
          this.fileList= []
        })
      }else{
        this.$message.error(res.msg)
        this.$refs.upload.clearFiles()
        this.action()
      }
    },
    // 下载模板
    downloadDemo(){
      this.$api.get('/v1/downloadExcel',{},r=>{
        let elink = document.createElement("a");
        // 设置下载文件名
        elink.download = "模板.xlsx";
        elink.style.display = "none";
        let blob = new Blob([r.data], { type: 'application/vnd.ms-excel' })
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      }, f => {
        if (f === 403) {
          this.logout()
        }
      },'json','blob')
    },
    // 关闭上传文件对话框
    closed(){
      this.orderSource = '淘宝'
      this.fileList= []
    },
    // 取消
    cancel(){
      this.dialogVisible = false
      this.removeId = ''
    },
    // 确认移除
    confirmRemove(){
      let params = {}
      params.orderId = this.removeId
      this.$api.post('/v1/order/removeThirdOrder',params,r=>{
        if(r.errorCode === '0'){
          this.$message.success('移除成功')
          this.dialogVisible = false
          this.removeId = ''
          this.search(1)
        }else{
          this.$message.error(r.msg)
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      })
    },
    // 移除订单
    remove(id){
      this.removeId = id
      this.dialogVisible = true
    },
    // 批量上传
    batchUpload(){
      this.dialogVisible1 = true
    },
    // 查看订单
    checkOrder(id){
      this.link('/enterOrder',{orderId: id})
    },
    // 上传订单
    uploadOrder(){
      this.link("/enterOrder")
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit(e) {
      this.submitData = Object.create(this.formInline);
      this.nowPage = e;
      this.search(e);
    },
    search(e) {
      this.loading=true
      var params = {};
      if (
        this.submitData.buyTime !== null &&
        this.submitData.buyTime !== "" &&
        this.submitData.buyTime !== undefined &&
        this.submitData.buyTime.length !== 1
      ) {
        var start = this.submitData.buyTime[0];
        var end = this.submitData.buyTime[1];
        params.startTime = start;
        params.endTime = end;
      }
      if (this.submitData.id) {
        params.id = this.submitData.id;
      }
      params.size = this.pageSize;
      params.page = parseInt(e) - 1;
      this.$api.post('/v1/order/thirdOrderList', params, r => {
        if (r.errorCode == '0') {
          if (!r.data) {
            this.tableData = []
            return false
          }
          r.data.content.map(v => {
            if (v.buytime) {
              v.buytime = this.$format.format(v.buytime, 'yyyy-MM-dd hh:mm:ss')
            }
            if (v.uploadtime) {
              v.uploadtime = this.$format.format(v.uploadtime, 'yyyy-MM-dd hh:mm:ss')
            }
            return v
          })
          this.tableData = r.data.content
          // this.tableData = [
          //   {
          //     id: '8364982365394865',
          //     productname: '便携心电仪',
          //     size: 10,
          //     price: '199',
          //     buytime: '2021-11-12 15:56:56',
          //     totalfee: '1990',
          //     username: '赵小花',
          //     source: '淘宝',
          //     userpname: '小红',
          //     uploadtime: '2021-11-11 15:56:56'
          //   }
          // ]
          this.total = r.data.totalElements
          this.loading=false
        } else {
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
        // console.log(r)
      }, f => {
        this.loading=false
        if (f === 403) {
          this.logout()
        }
      },'json')

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  justify-content: space-between;
  flex-wrap: nowrap;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 20px;
  line-height: 72px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
}
.main-content {
  padding: 10px 20px 20px;
  height: 100%;
  overflow: auto;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.chart-box{
  /* height: calc(100% - 250px); */
  /* overflow-y: scroll; */
}
.main-content .chart-title {
  margin-bottom: 20px;
}
.frequency {
  color: #303133;
}
.frequency-title,
.chart-title {
  font-size: 18px;
}
.frequency-tiem {
  padding: 20px;
  padding-bottom: 0;
  border-left: 1px solid #d8d8d8;
}
.frequency-name {
  font-size: 14px;
}
.frequency-content {
  font-size: 48px;
  padding-top: 10px;
}
.frequency-content .unit {
  font-size: 18px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.table-header-select {
  overflow: hidden;
}
.table-header-select span {
  line-height: 28px;
}
.tooltip-content {
  padding: 2px 5px;
}
.tooltip-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
  color: #333;
}
.tooltip-item {
  overflow: hidden;
  font-size: 14px;
  line-height: 2;
  color: #333;
}
.tooltip-item .tooltip-item-left {
  color: #999;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px ;
}
.input-time {
  /* width: 425px; */
  /* margin-right: 30px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.search-button {
  /* width: 120px; */
  margin: 10px 0;
  height: fit-content;
}
.block{
  text-align: right;
  padding-bottom: 20px;
}
.tableStyle >>>tr{
  height: 64px;
}
.box-card >>> .el-card__body{
  padding: 15px 20px 10px;
}
.el-tabs__header{
  margin: 0 0 10px;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color:#FE9F36;
}
.tableStyle >>> .el-table__header .cell{
   padding: 0!important;
   text-overflow: unset!important;
   white-space:nowrap!important
}
.tips .tipsItem{
  line-height: 1.8;
}
.orderSource{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.orderSource .left{
  margin-right: 10px;
  font-size: 16px;
}
.orderSource .right{

}
.orderSource .right >>> .el-input__inner{
  font-size: 16px;
}
/* 上传文件 */
.upload-file{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.uploadFile >>> .el-dialog__body{
  padding-bottom: 0;
}
.uploadFile >>> .el-dialog__header{
  font-weight: bold;
}
.downloadDemo{

}
</style>
