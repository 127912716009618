<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-form :inline="true">
          <el-form-item label="姓名">
            <el-input size="medium" v-model="name" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="角色">
          <el-select size="medium" v-model="role" placeholder="角色">
              <el-option label="全部" value=""></el-option>
              <el-option label="业务经理" value="manger"></el-option>
              <el-option label="业务员" value="salesman"></el-option>
              <el-option label="一级经销商" value="primaryDealer"></el-option>
              <el-option label="二级经销商" value="secondaryDealer"></el-option>
            </el-select>
        </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="submit()" v-has="'read'">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="createVisible = true;user={}" v-has="'add'">新增账号</el-button>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          border
          style="width: 100%">
           <el-table-column
            header-align="center"
            align="center"
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="name"
            label="用户姓名">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="userRole"
            label="用户角色">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="phone"
            label="手机号">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="createTime"
            label="创建时间">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="userStatus"
            label="账号状态">
            <template slot-scope="scope">
              <el-switch
                v-has="'updateStatus'"
                :disabled = "scope.row.role === 'root'"
                @change="switchChange(scope.row,scope.$index)"
                class="switchStyle"
                v-model="scope.row.userStatus"
                active-color="rgb(22,175,240)"
                active-text="启用"
                inactive-color="rgb(219,219,219)"
                inactive-text="禁用">
              </el-switch>
              <div v-notHas="'updateStatus'">{{scope.row.userStatus?'启用':'禁用'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="button"
            label="操作">
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.role !== 'root'"
                @click="user = scope.row;createVisible = true"
                v-has="'update'"
                type="primary">修改</el-link>
              <font v-if="scope.row.status === 'forbidden' && scope.row.role === 'salesman'" v-has="'change && update'">|</font>
              <el-link
                v-if="scope.row.status === 'forbidden' && scope.row.role === 'salesman'"
                @click="user = scope.row;changeVisible = true"
                v-has="'change'"
                type="primary">转移经销商</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="total"
          :page-size="size"
          :current-page="currentPage"
        >
        </el-pagination>
        <div style="clear:both"></div>
      </el-card>
    </div>
    <el-dialog
      :title="user.id?'修改账号':'新建账号'"
      :close-on-click-modal="false"
      :visible.sync="createVisible"
      destroy-on-close
      top="10vh"
      width="600px"
      center
      class="dialog">
      <createAccount @reload="reload" :user = "user"></createAccount>
    </el-dialog>
    <el-dialog
      title="转移经销商"
      :close-on-click-modal="false"
      :visible.sync="changeVisible"
      destroy-on-close
      top="10vh"
      width="800px"
      center
      class="dialog">
      <changeDealer @reload="reload" :user = "user"></changeDealer>
    </el-dialog>
  </el-main>
</template>

<script>
  import createAccount from './createAccount'
  import changeDealer from './changeDealer'
  export default {
    name: "accountManager",
    data() {
      return {
        user:{},
        tableLoading:false,
        createVisible:false,
        changeVisible:false,
        name:'',
        role:'',
        currentPage: 1,
        size: 10,
        total: 0,
        tableData:[
        ]
      }
    },
    components: {createAccount, changeDealer},
    mounted() {
      this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
      this.submit();
    },
    methods:{
      reload(){
        this.changeVisible = false
        this.createVisible = false
        this.submit()
      },
      // 更改当前每页条数
      handleSizeChange(val){
        this.size = val
        this.currentPage = 1
        this.getUserList()
      },
      handleCurrentChange(val){
        this.currentPage = val
        this.getUserList()
      },
      switchChange(row,index){
        let status = 'normal'
        let text = '启用'
        if(!row.userStatus){
          status = 'forbidden'
          text = '禁用'
        }
         this.$confirm('确定要' + text + '该账户吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableLoading = true
          let params = {}
          params.status = status
          params.userId = row.idStr
          this.$api.post('/v1/user/manage/user',params,r=>{
            this.tableLoading = false
            if(r.errorCode === '0'){
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.reload()
            }else{
              this.$message.error(r.msg)
            }
          },err=>{
            this.$message({
              type: 'error',
              message: '操作失败!'
            });
            this.tableData[index].userStatus = !row.userStatus  
            this.tableLoading = false
          })
        }).catch(() => {
          this.tableData[index].userStatus = !row.userStatus       
        });
      },
      // 点击搜索按钮
      submit(){
        this.currentPage = 1
        this.getUserList()
      },
      getUserList(){
        this.tableLoading = true
        var params = {}
        if(this.name){
          params.name = this.name
        }
        if(this.role){
          params.role = this.role
        }
        params.page = this.currentPage - 1
        params.size = this.size
        this.$api.post('/v1/user/list',params,r=>{
          this.tableLoading = false
          if(r.errorCode === '0'){
            for(var item of r.data.content){
              switch (item.role) {
                case 'root':
                  item.userRole = '超级管理员'
                  break;
                case 'manger':
                  item.userRole = '业务经理'
                  break;
                case 'salesman':
                  item.userRole = '业务员'
                  break;
                case 'primaryDealer':
                  item.userRole = '一级经销商'
                  break;
                case 'secondaryDealer':
                  item.userRole = '二级经销商'
                  break;
                default:
                  break;
              }
              item.userStatus = item.status === 'normal'?true:false
              item.id = item.idStr
              item.pid = item.pidStr
              item.createTime = this.$format.format(item.createTime,'yyyy-MM-dd hh:mm:ss')
            }
            this.tableData = r.data.content
            this.total = r.data.totalElements
          }else{
            this.$message.error(r.msg)
          }
        },err=>{
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  padding-bottom: 60px;
}
.el-pagination{
  margin-top: 20px;
}
.el-pagination{
  float:right;
}
font{
  color: #FE9F36;
  margin: 0 8px;
}
/* 开关 */
.switchStyle{
  height: 28px;
}
.switchStyle >>> .el-switch__label *{
  font-size: 12px;
}
.switchStyle >>> .el-switch__core{
  height: 28px;
  border-radius: 26px;
}
.switchStyle >>> .el-switch__core:after{
  width: 22px;
  height: 22px;
  top: 2px;
  left: 2px;
}
.switchStyle >>> .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-size: 12px;
}
.switchStyle >>> .el-switch__label--left {
  z-index: 9;
  left: 32px;
  color: #606266;
  margin-right: 3px;
}
.switchStyle >>> .el-switch__label--right {
  z-index: 9;
  left: 0px;
}
.switchStyle >>> .el-switch__label.is-active {
  display: block;
}
.switchStyle.el-switch >>> .el-switch__core,
.el-switch .el-switch__label {
  width: 66px !important;
}
.box-card:last-child{
  margin-bottom: 50px;
}
</style>