<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <div class="flex">
          <div class="flex-left">
            <div class="input-group">
              <div class="input-title">经销商名称：</div>
              <div class="input-content">
                <el-input
                  placeholder="请输入经销商名称查询"
                  size="medium"
                  v-model="formInline.dealerName"
                >
                </el-input>
              </div>
            </div>
            <div class="input-group">
              <div class="input-title">审批状态：</div>
              <div class="input-content">
                <el-select placeholder="请选择审批状态查询" size="medium" v-model="formInline.status">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="flex-right">
            <el-button class="search-button" size="medium" type="primary" @click="submit(1)"
              >查询</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          :data="tableData"
          class="tableStyle"
          max-height="620"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="name" label="经销商名称" min-width="180">
          </el-table-column>
          <el-table-column prop="role" label="经销商等级" min-width="130">
            <template slot-scope="scope">
              {{scope.row.role == 'primaryDealer' ? '一级经销商' : (scope.row.role == 'secondaryDealer' ? '二级经销商' : scope.row.role)}}
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="经销商电话" min-width="130">
          </el-table-column>
          <el-table-column prop="applyTime" label="申请时间" min-width="160">
          </el-table-column>
          <el-table-column prop="pname" label="上级经销商" min-width="180">
          </el-table-column>
          <el-table-column prop="status" label="审批状态" min-width="120">
            <template slot-scope="scope">
              {{scope.row.status == 'approving' ? '审核中' : (scope.row.status == 'agree' ? '已通过' : (scope.row.status == 'refuse' ? '已拒绝' : (scope.row.status == 'revoke' ? '已撤销' : scope.row.status)))}}
            </template>
          </el-table-column>
          <el-table-column prop="passTime" label="审批时间" min-width="160">
          </el-table-column>
          <el-table-column prop="handleName" label="操作人" min-width="130">
          </el-table-column>
          <el-table-column prop="saleName" label="业务员" min-width="130">
          </el-table-column>
          <el-table-column label="操作" min-width="200" fixed="right">
            <template slot-scope="scope">
              <div class="operateBtn">
                <el-button class="textBtn" type="text" v-if="scope.row.status == 'approving'" v-has="'examine'" @click="addAccount(scope.row.id,scope.row.role,scope.row.status,scope.row.pname)">生成账号</el-button>
                <div class="line" v-has="'examine'" v-if="scope.row.status == 'approving'"></div>
                <el-button class="textBtn" type="text" v-has="'refuse'" @click="reject(scope.row.id)" v-if="scope.row.status == 'approving'">拒绝</el-button>
                <el-button class="textBtn" type="text" @click="seeAccount(scope.row.id,scope.row.role,scope.row.status,scope.row.pname)" v-if="scope.row.status !== 'approving'">查看</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      loading: true,
      total: 1,
      nowPage: 1,
      pageSize: 10,
      options1: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "approving",
          label: "审核中",
        },
        {
          value: "agree",
          label: "已通过",
        },
        {
          value: "refuse",
          label: "已拒绝",
        },
      ],
      formInline: {
        dealer: "",
      },
      submitData: {
        dealer: "",
      },
    };
  },
  mounted() {
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 查看申请账号详情
    seeAccount(id,role,status,pname){
      this.link('/applyDistributor',{'id': id, disabled: true, level: role, from: 'auditList', status: status, pname: pname})
    },
    // 生成账号
    addAccount(id,role,status,pname){
      this.link('/applyDistributor',{'id': id, disabled: true, level: role, from: 'auditList', status: status, pname: pname})
    },
    // 拒接
    reject(id){
      let params = {}
      params.id = id
      params.status = 'refuse'
      this.$api.post('/v1/agreeOrRefuseDealerApply',params,r=>{
        if(r.errorCode == '0'){
          this.$message.success('账号申请已拒绝')
          this.search(1)
        }
      })
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    search(e) {
      this.loading=true
      var examineListRes = {};
      if (this.submitData.dealerName) {
        examineListRes.dealerName = this.submitData.dealerName;
      }
      if (this.submitData.status) {
        examineListRes.status = this.submitData.status;
      }
      examineListRes.size = this.pageSize;
      examineListRes.page = parseInt(e) - 1;
      this.$api.post("/v1/examine/list",examineListRes,r=>{
        if(r.errorCode == '0'){
          r.data.content.map(v=>{
            if (v.applyTime) {
              v.applyTime = this.$format.format(v.applyTime, 'yyyy-MM-dd hh:mm:ss')
            }
            if (v.passTime) {
              v.passTime = this.$format.format(v.passTime, 'yyyy-MM-dd hh:mm:ss')
            }else{
              v.passTime = '--'
            }
            v.saleName = v.saleName || '--'
            v.handleName = v.handleName || '--'
            return v
          })
          this.tableData = r.data.content
          this.total = r.data.totalElements
          this.loading=false
        }else{
          this.loading=false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      },f=>{
        if (f === 403) {
          this.logout()
        }
      },'json')
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-content {
  padding: 20px;
  height: 100%;
  /* overflow: auto; */
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px;
}
.input-time {
  /* width: 425px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.chart-box{
  /* height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}

.tableStyle >>>tr{
  height: 64px;
}
.el-pagination {
  padding: 20px 0;
}
.search-button {
  margin: 10px;
  height: fit-content;
}
.block{
  text-align: right;
}
.operateBtn{
  display: flex;
  height: 100%;
  justify-content: center;
}
.operateBtn .textBtn{
  padding: 0;
}
.operateBtn .line{
  height: auto;
  margin: 0 10px;
  width: 1px;
  display: block;
  background-color: #FE9F36;
}
.box-card:last-child{
  margin-bottom: 50px;
}
</style>