<template>
  <Menu>
    <router-view></router-view>
  </Menu>
</template>

<script>
import Menu from '@/pages/layout/Menu'
export default {
  name: 'Home',
  data () {
    return {
    }
  },
  mounted () {
    // this.menu = this.$router.options.routes[0] || []
  },
  components: { Menu }
}
</script>
