import store from '../store'
import router from '../router'
//1.引入vue
import Vue from 'vue';
//2.新创建一个vue实例
let v = new Vue();
// 配置API接口地址
var root = '/dealer'
// 引用axios
var axios = require('axios')
var qs = require('qs')
// 自定义判断元素类型JS
function toType (obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
function logout () {
  store.commit("setToken",null);
  router.push({path: '/login', query: {}})
}
// 参数过滤函数
function filterNull (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}

/*
  接口处理函数
*/
function apiAxios (method, url, params, success, failure, contentType,responseType) {
  if (params) {
    params.t = new Date().getTime()
    params = filterNull(params)
  }
  if ( (method === 'POST' || method === 'PUT') && contentType !== 'json') {
    params = qs.stringify(params)
  }
  if(contentType === 'json'){
    contentType = 'application/json'
  }else{
    contentType = 'application/x-www-form-urlencoded'
  }
  if(!responseType) responseType = 'json'
  // if (useMock) {
  //   root = 'http://127.0.0.1:4523/mock/664154' + root
  // }
  axios({
    method: method,
    url: url,
    headers: {
      'Content-type': contentType,
      'client-name':'teleconsultation',
      'X-Auth-Token': store.state.token || ''
    },
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    baseURL: root,
    responseType:responseType,
    withCredentials: true
  })
    .then(function (res) {
      if(responseType == 'json'){
        success(res.data)
      }else{
        success(res)
      }
      // if (res.data.success === true) {
      //   if (success) {
      //     success(res.data)
      //   }
      // } else {
      //   if (failure) {
      //     failure(res.data)
      //   } else {
      //     window.alert('error: ' + JSON.stringify(res.data))
      //   }
      // }
    })
    .catch(function (err) {
      let res = err.response
      if (err && res && res.status && res.status !== 403 && res.status !== 401) {
        window.alert('api error, HTTP CODE: ' + res.status)
      }
      if (failure && res && res.status && (res.status === 403 || res.status === 401) ) {
        sessionStorage.setItem("username", null);
        router.push({ path: "/login", query: {} });
        failure(res)
      } else if (failure) {
        failure(err)
      }
    })
}

// 返回在vue模板中的调用接口
export default {
  get: function (url, params, success, failure,contentType,responseType) {
    return apiAxios('GET', url, params, success, failure, contentType, responseType)
  },
  post: function (url, params, success, failure,contentType,responseType) {
    return apiAxios('POST', url, params, success, failure,contentType,responseType)
  },
  put: function (url, params, success, failure,contentType,responseType) {
    return apiAxios('PUT', url, params, success, failure,contentType,responseType)
  },
  delete: function (url, params, success, failure,contentType,responseType) {
    return apiAxios('DELETE', url, params, success, failure,contentType,responseType)
  }
}

