<template>
  <el-main>
    <!-- <MainTitle name="硬件数据"></MainTitle> -->
    <div class="main-content">
      <el-card class="box-card">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :disabled="loading" label="订单列表（淘宝/京东）" name="third"></el-tab-pane>
          <el-tab-pane :disabled="loading" label="订单列表（小程序）" name="applet"></el-tab-pane>
          <el-tab-pane :disabled="loading" label="订单列表(自主上传)" name="selfUpload"></el-tab-pane>
        </el-tabs>
        <div class="flex">
          <div class="flex-left">
              <div class="input-group">
            <div class="input-title">经销商名称：</div>
            <div class="input-content">
              <el-input
                placeholder="请输入经销商名称查询"
                size="medium"
                v-model="formInline.userName"
              >
              </el-input>
            </div>
          </div>
          <div class="input-group">
            <div class="input-title">经销商等级：</div>
            <div class="input-content">
              <el-select
                  size="medium" placeholder="请选择经销商等级" v-model="formInline.userRole">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="input-group input-time">
            <div class="input-title">下单时间：</div>
            <div class="input-content">
              <el-date-picker
                v-model="formInline.buyTime"
                size="medium"
                :picker-options="pickerBeginDateBefore"
                type="daterange"
                style="width: 240px"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- <div class="input-group">
            <div class="input-title">收件人姓名：</div>
            <div class="input-content">
              <el-input placeholder="请输入用户姓名查询" size="medium" v-model="formInline.buyerName">
              </el-input>
            </div>
          </div>

          <div class="input-group">
            <div class="input-title">收件人手机号：</div>
            <div class="input-content">
              <el-input placeholder="请输入用户手机号查询" size="medium" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11"  v-model="formInline.buyerPhone">
              </el-input>
            </div>
          </div> -->
          <div class="input-group">
            <div class="input-title">商品名称：</div>
            <div class="input-content">
              <el-input placeholder="请输入商品名称查询" size="medium" v-model="formInline.productName">
              </el-input>
            </div>
          </div>
          <div class="input-group">
            <div class="input-title">订单号：</div>
            <div class="input-content">
              <el-input placeholder="请输入订单号查询" size="medium" v-model="formInline.id">
              </el-input>
            </div>
          </div>
          <div class="input-group">
            <div class="input-title">业务员：</div>
            <div class="input-content">
              <el-input
                placeholder="请输入业务员查询"
                size="medium"
                v-model="formInline.salesMan"
              >
              </el-input>
            </div>
          </div>
          </div>
          <div class="flex-right">
            <el-button size="medium" class="search-button" type="primary" @click="submit(1)"
              >查询</el-button
            >
            <el-button v-has="'addOrder'" v-if="activeName == 'selfUpload'" size="medium" class="search-button" @click="uploadOrder"
              >上传订单</el-button
            >
          </div>
        </div>
      </el-card>
      <el-card class="box-card chart-box base-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="530"
          class="tableStyle"
          :data="activeName == 'third' ? tableData1 : (activeName == 'applet' ? tableData2 : tableData3)"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="id" label="订单号" min-width="140"> </el-table-column>
          <el-table-column prop="productname" label="商品名称" min-width="180"> </el-table-column>
          <el-table-column prop="size" label="商品数量" min-width="90"> </el-table-column>
          <el-table-column prop="price" label="商品价格（元）" min-width="100"> </el-table-column>
          <el-table-column prop="totalfee" v-if="activeName == 'third'" label="商品实付价" min-width="100"> </el-table-column>
          <!-- <el-table-column prop="buyername" label='收件人姓名' min-width="130"> </el-table-column>
          <el-table-column prop="buyerphone" label='收件人手机号' min-width="120"> </el-table-column> -->
          <el-table-column prop="buytime" label="下单时间" min-width="160" > </el-table-column>
          <el-table-column prop="username" label="所属经销商" min-width="160"> </el-table-column>
          <el-table-column prop="userrole" label="经销商等级" min-width="120"></el-table-column>
          <el-table-column prop="money" v-if="activeName == 'third' || activeName == 'applet'" label="获取奖金数（元）" min-width="120">
            <template slot-scope="scope">
              {{scope.row.money || '0'}}
            </template>
          </el-table-column>
          <el-table-column prop="userpname" label="业务员" min-width="120"> </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="pageChage"
            :total="total"
            :page-size="pageSize"
            :current-page="nowPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </el-main>
</template>

<script>
// import MainTitle from '@/pages/layout/MainTitle'
export default {
  name: "orderList",
  data() {
    return {
      pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
      activeName: 'third',
      id: this.$route.query.id, //从用户列表分析历史跳转时带过来的参数
      openId: this.$route.query.openId, //从客服消息分析历史跳转时带过来的参数
      recordId: "",
      loading: true,
      options1: [
        {
          value: "",
          label: "全部",
        },{
          value: "primaryDealer",
          label: "一级经销商",
        },
        {
          value: "secondaryDealer",
          label: "二级经销商",
        },
      ],
      formInline: {
        id: "",
        productName: "",
        // buyerName: "",
        // buyerPhone: "",
        buyTime: "",
        userName: "",
        userRole: "",
        userPName: ""
      },
      submitData: {
        id: "",
        productName: "",
        // buyerName: "",
        // buyerPhone: "",
        buyTime: "",
        userName: "",
        userRole: "",
        userPName: ""
      },
      total: 1,
      nowPage: 1,
      pageSize: 10,
      tableData1: [], // 第三方
      tableData2: [], // 小程序
      tableData3: [] // 自主上传
    };
  },
  watch: {},
  components: {},
  mounted() {
    this.activeName = this.$route.query.activeName ? this.$route.query.activeName : 'third'
    if (this.id) {
      this.formInline.userId = this.id;
      this.submitData.userId = this.id;
    }
    if (this.openId) {
      this.formInline.openId = this.openId;
      this.submitData.openId = this.openId;
    }
    this.search(1);
    this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
  },
  methods: {
    // 上传订单
    uploadOrder(){
      this.link("/uploadOrder")
    },
    // 切换标签页
    handleClick(e){
      this.pageSize = 10
      this.nowPage = 0
      this.formInline = {
        id: "",
        productName: "",
        // buyerName: "",
        // buyerPhone: "",
        buyTime: "",
        userName: "",
        userRole: "",
        userPName: ""
      }
      this.submitData = {
        id: "",
        productName: "",
        // buyerName: "",
        // buyerPhone: "",
        buyTime: "",
        userName: "",
        userRole: "",
        userPName: ""
      }
      this.search(1)
    },
    link(path, query) {
      this.$router.push({ path: path, query: query });
    },
    logout() {
      var params = {};
      params.u = "/logout";
      sessionStorage.setItem("username", null);
      // this.$api.get('', params, r => {})
      this.link("/login");
    },
    handleSizeChange(e){
      this.pageSize = e
      this.nowPage = 0
      this.search(1)
    },
    pageChage(e) {
      this.search(e);
      this.nowPage = parseInt(e);
    },
    submit() {
      this.submitData = Object.create(this.formInline);
      this.nowPage = 1;
      this.search(1);
    },
    setDayString(date) {
      return (
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      );
    },
    formatDate(row, column) {
      // 获取单元格数据
      let data = row[column.property]
      if(data == null) {
          return null
      }
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    },
    search(e) {
      this.loading=true
      var params = {};
      if (
        this.submitData.buyTime !== null &&
        this.submitData.buyTime !== "" &&
        this.submitData.buyTime !== undefined &&
        this.submitData.buyTime.length !== 1
      ) {
        var start = this.submitData.buyTime[0];
        var end = this.submitData.buyTime[1];
        params.startTime = start;
        params.endTime = end;
      }
      if (this.submitData.id) {
        params.id = this.submitData.id;
      }
      if (this.submitData.productName) {
        params.productName = this.submitData.productName;
      }
      // if (this.submitData.buyerName) {
      //   params.buyerName = this.submitData.buyerName;
      // }
      // if (this.submitData.buyerPhone) {
      //   params.buyerPhone = this.submitData.buyerPhone;
      // }
      if (this.submitData.orderTime) {
        params.orderTime = this.submitData.orderTime;
      }
      if (this.submitData.userRole) {
        params.userRole = this.submitData.userRole;
      }
      if (this.submitData.userName) {
        params.userName = this.submitData.userName; // 经销商
      }
      if (this.submitData.salesMan) {
        params.salesMan = this.submitData.salesMan; //业务员
      }
      params.size = this.pageSize;
      params.page = parseInt(e) - 1;
      let url = ''
      if(this.activeName == 'third'){
        url = '/v1/order/thirdOrderList'
        params.flag = 'orderList'
      }else if(this.activeName == 'applet'){
        url = '/v1/order/miniProgram/orderList'
        params.flag = 'applet'
      }else if(this.activeName == 'selfUpload'){
        url = '/v1/order/list'
      }
      this.$api.post(url, params, r => {
        if (r.errorCode == '0') {
          r.data.content.map(v => {
            if (v.buytime || v.buyTime) {
              v.buytime = this.$format.format(v.buytime || v.buyTime, 'yyyy-MM-dd hh:mm:ss')
            }
            if(v.productName || v.productname){
              v.productname = v.productName || v.productname
            }
            if(v.productSize || v.size){
              v.size = v.productSize || v.size
            }
            if(v.productPrice || v.price){
              v.price = v.productPrice || v.price
            }
            // if(v.buyerName || v.buyername){
            //   v.buyername = v.buyerName || v.buyername
            // }
            // if(v.buyerPhone || v.buyerphone){
            //   v.buyerphone = v.buyerPhone || v.buyerphone
            // }
            if(v.userRole || v.userrole){
              let role = v.userRole || v.userrole
              switch (role) {
                case 'primaryDealer':
                  v.userrole = '一级经销商'
                  break;
                case 'secondaryDealer':
                  v.userrole = '二级经销商'
                  break;
                default:
                  break;
              }
            }
            if(v.userPName || v.userpname){
              v.userpname = v.userPName || v.userpname
            }
            if(v.createTime || v.createtime){
              v.createtime = this.$format.format(v.createTime || v.createtime, 'yyyy-MM-dd hh:mm:ss')
            }
            if(v.userName || v.username){
              v.username = v.userName || v.username
            }
            return v
          })
          if(this.activeName == 'third'){
            this.tableData1 = r.data.content
            this.tableData2 = []
            this.tableData3 = []
          }else if(this.activeName == 'applet'){
            this.tableData2 = r.data.content
            this.tableData1 = []
            this.tableData3 = []
          }else if(this.activeName == 'selfUpload'){
            this.tableData3 = r.data.content
            this.tableData1 = []
            this.tableData2 = []
          }
          this.total = r.data.totalElements
          this.loading = false
        } else {
          this.loading = false
          if (r.details && r.details[0]) {
            this.$message.error(r.details[0])
          } else {
            this.$message.error(r.msg)
          }
        }
      }, f => {
        this.loading = false
        if (f === 403) {
          this.logout()
        }
      },'json')

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  height: 100%;
  overflow: hidden;
}
.flex{
  justify-content: space-between;
  flex-wrap: nowrap;
}
.flex-left{
  display: flex;
  flex-wrap: wrap;
}
.flex-right{
  display: flex;
  margin: 0 30px;
}
.main-title {
  font-size: 24px;
  line-height: 32px;
  padding: 0 20px;
  line-height: 72px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
}
.main-content {
  padding: 10px 20px 20px;
  height: 100%;
  overflow: auto;
}
.box-card:last-child{
  margin-bottom: 50px;
}
.chart-box{
  /* height: calc(100% - 250px); */
  /* overflow-y: scroll; */
}
.main-content .chart-title {
  margin-bottom: 20px;
}
.frequency {
  color: #303133;
}
.frequency-title,
.chart-title {
  font-size: 18px;
}
.frequency-tiem {
  padding: 20px;
  padding-bottom: 0;
  border-left: 1px solid #d8d8d8;
}
.frequency-name {
  font-size: 14px;
}
.frequency-content {
  font-size: 48px;
  padding-top: 10px;
}
.frequency-content .unit {
  font-size: 18px;
}
.el-table {
  border-top: 1px solid #EBEEF5;
  border-bottom: none;
}
.el-table::before{
  background-color: transparent;
}
.el-pagination {
  padding: 20px 0;
}
.table-header-select {
  overflow: hidden;
}
.table-header-select span {
  line-height: 28px;
}
.tooltip-content {
  padding: 2px 5px;
}
.tooltip-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
  color: #333;
}
.tooltip-item {
  overflow: hidden;
  font-size: 14px;
  line-height: 2;
  color: #333;
}
.tooltip-item .tooltip-item-left {
  color: #999;
}
.input-group {
  width: auto;
  display: flex;
  align-items: center;
  margin: 8px 10px ;
}
.input-time {
  /* width: 425px; */
  /* margin-right: 30px; */
}
.input-group .input-title {
  flex-shrink: 0;
  /* padding-right: 10px; */
  min-width: 50px;
  font-size: 14px;
}
.input-group .input-content {
  min-width: 120px;
}
.search-button {
  /* width: 120px; */
  margin: 10px 0;
  height: fit-content;
}
.block{
  text-align: right;
  padding-bottom: 20px;
}
.tableStyle >>>tr{
  height: 64px;
}
.box-card >>> .el-card__body{
  padding: 15px 20px 10px;
}
.el-tabs__header{
  margin: 0 0 10px;
}
.tableStyle >>> .el-table__header .cell{
   padding: 0!important;
   text-overflow: unset!important;
   white-space:nowrap!important
}
</style>
