<template>
  <el-main>
    <div class="main-content">
      <el-card class="box-card">
        <el-form :inline="true">
          <el-form-item label="角色名称">
            <el-input size="medium" v-model="name" placeholder="角色名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="search()" v-has="'read'">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="searchData"
          border
          style="width: 100%">
           <el-table-column
            header-align="center"
            align="center"
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="name"
            label="角色名称">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="code"
            label="角色描述">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="time"
            label="创建时间">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="button"
            label="操作">
            <template slot-scope="scope">
              <el-link
                @click="createVisible = true;role=scope.row.role"
                type="primary" v-has="'role'">菜单权限</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog
      title="菜单栏编辑"
      :close-on-click-modal="false"
      :visible.sync="createVisible"
      destroy-on-close
      top="1vh"
      width="60%"
      center
      class="dialog">
      <setRole :menuList="menuList" :roleMenus="roleMenus" :role="role" @reload = "reload"></setRole>
    </el-dialog>
  </el-main>
</template>

<script>
  import setRole from './setRole'
  export default {
    name: "roleManager",
    data() {
      return {
        createVisible:false,
        name:'',
        menuList:[],
        roleMenus:{},
        role:'manger',
        searchData:[],
        tableData:[
          {
            role:'manger',
            name:'业务经理',
            code:'业务经理',
            time:'2021-09-26 10:10:10'
          },
          {
            role:'salesman',
            name:'业务员',
            code:'业务员',
            time:'2021-09-26 10:10:10'
          },
          {
            role:'primaryDealer',
            name:'一级经销商',
            code:'一级经销商',
            time:'2021-09-26 10:10:10'
          },
          {
            role:'secondaryDealer',
            name:'二级经销商',
            code:'二级经销商',
            time:'2021-09-26 10:10:10'
          }
        ]
      }
    },
    components: {setRole},
    mounted() {
      this.$store.dispatch("setDefaultActiveFun", this.$route.path); // 菜单栏高亮
      this.searchData = this.tableData
      this.getMenuList()
      this.getPowerList()
    },
    methods:{
      reload(){
        this.createVisible = false
        this.getPowerList()
      },
      search(){
      this.searchData = []
      this.tableData.map(v=>{
        if(v.name.includes(this.name)){
            this.searchData.push(v)
          }
      })
    },
      getPowerList(){
        var params = {}
        this.$api.post('/v1/menu/powerList',params,r=>{
          // console.log(r)
          if(r.errorCode === '0'){
            let roleMenus = {}
            if(Array.isArray(r.data)){
              r.data.map(v=>{
                if(v.role){
                  roleMenus[v.role] = v.menuIds
                }
              })
            }
            this.roleMenus = roleMenus
          }else{
            this.$message.error(r.msg)
          }
        },err=>{
        })
      },
      getMenuList(){
        var params = {}
        this.$api.post('/v1/menu/list',params,r=>{
          if(r.errorCode === '0'){
            let data = []
            for(var item of r.data){
              item.id = item.idStr
              item.pid = item.pidStr
              data.push(item)
              item.menuUsage = item.menuUsage.map(v=>{
                v = JSON.parse(v)
                v.pid = item.id
                v.id = item.id + '-' + v.code
                v.menuName = v.name
                data.push(v)
              })
            }
            // console.log(data)
            this.menuList =this.setMenuList(data,'0')
          }else{
            this.$message.error(r.msg)
          }
        },err=>{
        })
      },
      //递归设置菜单层级
      setMenuList(data,pid){
        let rs = []
        data.map(v=>{
          if(v.pid === pid){
            let item = JSON.parse(JSON.stringify(v));
            item.children = this.setMenuList(data,v.id)
            if(item.children.length < 1){
              delete item.children
            }
            rs.push(item)
          }
        })
        return rs
      }
    }
  }
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
  background: #f6f7fa;
  padding-bottom: 60px;
}
</style>