import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '' ,
    role:'',//权限
    defaultActive: '', // 高亮菜单
    menu:[],//菜单
    power:{}//操作权限
  },
  mutations: {
    setToken (state, value) {
      state.token = value
    },
    setRole (state, value) {
      state.role = value
    },
    setDefaultActive (state, value) {
      state.defaultActive = value
    },
    setMenu (state, value) {
      state.menu = value
    },
    setPower (state, value) {
      state.power = value
    }
  },
  actions: {
    setTokenFun (context, value) {
      context.commit('setToken', value)
    },
    setRoleFun (context, value) {
      context.commit('setRole', value)
    },
    setDefaultActiveFun (context, value) {
      context.commit('setDefaultActive', value)
    },
    setMenuFun (context, value) {
      context.commit('setMenu', value)
    },
    setPowerFun (context, value) {
      context.commit('setPower', value)
    }
  },
  plugins: [
    persistedState({ storage: window.sessionStorage })
  ]
})
